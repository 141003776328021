import { ObjectServiceImpl } from "./serviceImpl.ts"

export interface ObjectService {
    findFileById(objectId: string): Promise<string>
    downloadBase64WithExtension(name: string, bytes: string): void
    downloadBase64WithoutExtension(name: string, bytes: string, type: string): void
    base64ToArrayBuffer(file: string): ArrayBufferLike
    downloadPdf(name: string, bytes: ArrayBuffer, type?: string): void
    base64ToFile(base64String: string): File
}

export function NewObjectService(): ObjectService {
    return new ObjectServiceImpl();
}
