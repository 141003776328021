import React from "react";
import { IconsProps } from "./dto.ts";

export function DownloadIcon(props: IconsProps) {

    return (
        <svg onClick={props.onClick} width={props.size === "" ? "24" : props.size} height={props.size === "" ? "24" : props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M7 10L12 15M12 15L17 10M12 15V3" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}