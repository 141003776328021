import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/utilis/index.ts";
import { CreateFinancialAdvisorDTO, EditFinancialAdvisorDTO, FinancialAdvisorDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO } from "./dto.ts";
import { NewFinancialAdvisorService } from "./service.ts";

interface FinancialAdvisorState {
    findAllFinancialAdvisorStatus: PromiseStatuses
    findAllFinancialAdvisorResponse?: findAllFinancialAdvisorResponseDTO
    findAllFinancialAdvisorFilters: FindAllFinancialAdvisorFiltersDTO
    selectedTab: number
    createFinancialAdvisorRequest: CreateFinancialAdvisorDTO
    createFinancialAdvisorStatus: PromiseStatuses
    editFinancialAdvisorRequest: EditFinancialAdvisorDTO
    editFinancialAdvisorStatus: PromiseStatuses
    deleteFinancialAdvisorStatus: PromiseStatuses
    findByIdFinancialAdvisorStatus: PromiseStatuses
    findByIdFinancialAdvisorResponse?: FinancialAdvisorDTO
    openNewFinancialAdvisorModal: boolean
    openConfirmModal: boolean
    financialAdvisorId: string
}

const initialState: FinancialAdvisorState = {
    findAllFinancialAdvisorStatus: 'idle',
    selectedTab: 0,
    findAllFinancialAdvisorFilters: { page: 0, itemsPerPage: 25, name: '', sort: '', order: false },
    createFinancialAdvisorRequest: {
        email: '',
        name: '',
        surname: '',
        phoneNumber: '',
        commercialName: '',
        subscriptionType: '',
        subscriptionExpirationDate: new Date(),
        avatarObjectId: 0,
        userId: '',
        note: '',
        avatar: null
    },
    createFinancialAdvisorStatus: 'idle',
    editFinancialAdvisorRequest: {
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        commercialName: '',
        avatarObjectId: 0,
        note: "",
        avatar: null
    },
    editFinancialAdvisorStatus: 'idle',
    deleteFinancialAdvisorStatus: 'idle',
    findByIdFinancialAdvisorStatus: 'idle',
    openNewFinancialAdvisorModal: false,
    openConfirmModal: false,
    financialAdvisorId: ""

}

export const findAllFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/findAllFinancialAdvisor',
    async (filters: FindAllFinancialAdvisorFiltersDTO, thunkApi): Promise<findAllFinancialAdvisorResponseDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findAllFinancialAdvisor(filters)
    }
)

export const createFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/createFinancialAdvisor',
    async (request: FormData, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.createFinancialAdvisor(request)
    }
)

export const deleteFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/deleteFinancialAdvisor',
    async (id: string, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.deleteFinancialAdvisor(id)
    }
)

export const findByIdFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/findByIdFinancialAdvisor',
    async (id: string, thunkApi): Promise<FinancialAdvisorDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findByIdFinancialAdvisor(id)
    }
)

export const editFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/editFinancialAdvisor',
    async (request: { id: string, request: FormData }, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.editFinancialAdvisor(request.id, request.request)
    }
)

const financialAdvisorSlice = createSlice({
    name: 'financialAdvisor/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllFinancialAdvisor.pending, (state) => {
                state.findAllFinancialAdvisorStatus = 'loading'
            })
            .addCase(findAllFinancialAdvisor.fulfilled, (state, action) => {
                state.findAllFinancialAdvisorStatus = 'successfully'
                state.findAllFinancialAdvisorResponse = action.payload
            })
            .addCase(findAllFinancialAdvisor.rejected, (state) => {
                state.findAllFinancialAdvisorStatus = 'failed'
            })
            .addCase(createFinancialAdvisor.pending, (state) => {
                state.createFinancialAdvisorStatus = 'loading'
            })
            .addCase(createFinancialAdvisor.fulfilled, (state, action) => {
                state.createFinancialAdvisorStatus = 'successfully'
            })
            .addCase(createFinancialAdvisor.rejected, (state) => {
                state.createFinancialAdvisorStatus = 'failed'
            })
            .addCase(deleteFinancialAdvisor.pending, (state) => {
                state.deleteFinancialAdvisorStatus = 'loading'
            })
            .addCase(deleteFinancialAdvisor.fulfilled, (state, action) => {
                state.deleteFinancialAdvisorStatus = 'successfully'
            })
            .addCase(deleteFinancialAdvisor.rejected, (state) => {
                state.deleteFinancialAdvisorStatus = 'failed'
            })
            .addCase(findByIdFinancialAdvisor.pending, (state) => {
                state.findByIdFinancialAdvisorStatus = 'loading'
            })
            .addCase(findByIdFinancialAdvisor.fulfilled, (state, action) => {
                state.findByIdFinancialAdvisorStatus = 'successfully'
                state.findByIdFinancialAdvisorResponse = action.payload
            })
            .addCase(findByIdFinancialAdvisor.rejected, (state) => {
                state.findByIdFinancialAdvisorStatus = 'failed'
            })
            .addCase(editFinancialAdvisor.pending, (state) => {
                state.editFinancialAdvisorStatus = 'loading'
            })
            .addCase(editFinancialAdvisor.fulfilled, (state, action) => {
                state.editFinancialAdvisorStatus = 'successfully'
            })
            .addCase(editFinancialAdvisor.rejected, (state) => {
                state.editFinancialAdvisorStatus = 'failed'
            })
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        resetCreateFinancialAdvisorRequest: (state) => {
            state.createFinancialAdvisorRequest = {
                email: '',
                name: '',
                surname: '',
                phoneNumber: '',
                commercialName: '',
                subscriptionType: '',
                subscriptionExpirationDate: new Date(),
                avatarObjectId: 0,
                userId: '',
                note: '',
                avatar: null
            }
        },
        setCreateFinancialAdvisorsCommercialName: (state, action) => {
            state.createFinancialAdvisorRequest.commercialName = action.payload
        },
        setCreateFinancialAdvisorsName: (state, action) => {
            state.createFinancialAdvisorRequest.name = action.payload
        },
        setCreateFinancialAdvisorsSurname: (state, action) => {
            state.createFinancialAdvisorRequest.surname = action.payload
        },
        setCreateFinancialAdvisorsEmail: (state, action) => {
            state.createFinancialAdvisorRequest.email = action.payload
        },
        setCreateFinancialAdvisorsPhoneNumber: (state, action) => {
            state.createFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setCreateFinancialAdvisorsNote: (state, action) => {
            state.createFinancialAdvisorRequest.note = action.payload
        },
        setCreateFinancialAdvisorsStatus: (state, action) => {
            state.createFinancialAdvisorStatus = action.payload
        },
        setFindbyIdFinancialAdvisorStatus: (state, action) => {
            state.findByIdFinancialAdvisorStatus = action.payload
        },
        setEditFinancialAdvisorsCommercialName: (state, action) => {
            state.editFinancialAdvisorRequest.commercialName = action.payload
        },
        setEditFinancialAdvisorsName: (state, action) => {
            state.editFinancialAdvisorRequest.name = action.payload
        },
        setEditFinancialAdvisorsSurname: (state, action) => {
            state.editFinancialAdvisorRequest.surname = action.payload
        },
        setEditFinancialAdvisorsPhoneNumber: (state, action) => {
            state.editFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setEditFinancialAdvisorsEmail: (state, action) => {
            state.editFinancialAdvisorRequest.email = action.payload
        },
        setEditFinancialAdvisorsNote: (state, action) => {
            state.editFinancialAdvisorRequest.note = action.payload
        },
        setDeleteFinancialAdisorsStatus: (state, action) => {
            state.deleteFinancialAdvisorStatus = action.payload
        },
        setEditFinancialAdisorsStatus: (state, action) => {
            state.editFinancialAdvisorStatus = action.payload
        },
        setFindAllFinancialAdvisorsFilterName: (state, action) => {
            state.findAllFinancialAdvisorFilters.name = action.payload
        },
        setOpenNewFinancialAdvisorModal: (state, action) => {
            state.openNewFinancialAdvisorModal = action.payload
        },
        setOpenConfirmModal: (state, action) => {
            state.openConfirmModal = action.payload
        },
        setFindAllFinancialAdvisorsStatus: (state, action) => {
            state.findAllFinancialAdvisorStatus = action.payload
        },
        setFindAllFinancialAdvisorsFiltersPage: (state, action) => {
            state.findAllFinancialAdvisorFilters.page = action.payload
        },
        setFinancialAdvisorId: (state, action) => {
            state.financialAdvisorId = action.payload
        },
        setEditFinancialAdisorsFile: (state, action) => {
            state.editFinancialAdvisorRequest.avatar = action.payload
        }
    },
})

export const {
    setSelectedTab,
    setCreateFinancialAdvisorsEmail,
    setCreateFinancialAdvisorsName,
    setCreateFinancialAdvisorsPhoneNumber,
    setCreateFinancialAdvisorsSurname,
    setFindbyIdFinancialAdvisorStatus,
    setEditFinancialAdvisorsName,
    setEditFinancialAdvisorsPhoneNumber,
    setEditFinancialAdvisorsSurname,
    setEditFinancialAdvisorsEmail,
    setCreateFinancialAdvisorsStatus,
    setDeleteFinancialAdisorsStatus,
    setEditFinancialAdisorsStatus,
    setFindAllFinancialAdvisorsFilterName,
    setOpenNewFinancialAdvisorModal,
    setOpenConfirmModal,
    setFindAllFinancialAdvisorsStatus,
    setFindAllFinancialAdvisorsFiltersPage,
    setFinancialAdvisorId,
    setCreateFinancialAdvisorsNote,
    setCreateFinancialAdvisorsCommercialName,
    resetCreateFinancialAdvisorRequest,
    setEditFinancialAdvisorsNote,
    setEditFinancialAdvisorsCommercialName,
    setEditFinancialAdisorsFile
} = financialAdvisorSlice.actions

export default financialAdvisorSlice.reducer

