import React from "react"
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { SearchIcon } from "../../../ui/icons/searchIcon.tsx"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { SelectComponent } from "../../../ui/molecules/select/index.tsx"
import { DocumentStatus, DocumentType, DocumentTypology } from "./dto.ts"
import { findAllDocuments, findAllReceivedDocuments, findAllSentDocuments, setDocumentFilterFileName, setDocumentFilterMonth, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterStatus, setDocumentFilterTypology, setDocumentFilterYear } from "./slice.ts"

interface Props {
    type: DocumentType
}

export function DocumentFilters(props: Props) {
    const { type } = props;

    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)
    const companyState = useAppSelector(state => state.company)

    let years: { value: number, label: number }[] = []
    const startYear = 2022

    for (let i = startYear; i <= (new Date().getFullYear() + 1); i++) {
        years.push({ value: i, label: i })
    }

    let options: { value: string, label: string }[] = []
    switch (type) {
        case DocumentType.balance:
            options =
                [
                    { value: DocumentTypology.balanceDocument, label: "Bilancio" },
                    { value: DocumentTypology.document770, label: "770" },
                    { value: DocumentTypology.iva, label: "IVA" },
                    { value: DocumentTypology.unique, label: "Unico" }
                ]
            break;
        case DocumentType.f24:
            options =
                [
                    { value: DocumentTypology.contributions, label: "Contributi" },
                    { value: DocumentTypology.iva, label: "IVA" },
                    { value: DocumentTypology.directTax, label: "Imposte dirette" },
                    { value: DocumentTypology.other, label: "Altro" }
                ]
            break;
        case DocumentType.employees:
            options =
                [
                    { value: DocumentTypology.paySlip, label: "Busta paga" },
                    { value: DocumentTypology.attendance, label: "Presenze" },
                    { value: DocumentTypology.cu, label: "CU" },
                    { value: DocumentTypology.other, label: "Altro" }
                ]
            break;
        case DocumentType.accounting:
            options =
                [
                    { value: DocumentTypology.bankStatement, label: "Estratti conto" },
                    { value: DocumentTypology.invoice, label: "Fatture" },
                    { value: DocumentTypology.receipt, label: "Ricevute" },
                    { value: DocumentTypology.insurance, label: "Assicurazioni" },
                    { value: DocumentTypology.loan, label: "Finanziamenti" },
                    { value: DocumentTypology.paypal, label: "Paypal, Stripe, ecc" },
                    { value: DocumentTypology.other, label: "Altro" },
                ]
            break;
        case DocumentType.other:
            options =
                [
                    { value: DocumentTypology.inspection, label: "Visura" },
                    { value: DocumentTypology.durc, label: "DURC" }
                ]
            break;
        default:
            break;
    }

    return (
        <div className={"flex flex-row gap-4 w-full"}>
            <div className={"w-1/2"}>
                <InputComponent
                    placeholder="Nome del file"
                    startIcon={<SearchIcon />}
                    type={"text"}
                    onChangeText={(value) => dispatch(setDocumentFilterFileName(value))}
                    value={documentState.documentFilters.fileName}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            dispatch(findAllDocuments(documentState.documentFilters))
                        }
                    }}
                />
            </div>
            <div className={"w-1/4"}>
                <SelectComponent
                    placeholder="Tipologia"
                    onChange={(value) => {
                        if (companyState.selectedDocumentTab === 3) {
                            if (documentState.selectedSentOrReceivedDocumentTab === 1)
                                dispatch(findAllReceivedDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: documentState.documentFilters.order,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: documentState.documentFilters.receiverGroup,
                                    referenceYear: documentState.documentFilters.referenceYear,
                                    referenceMonth: documentState.documentFilters.referenceMonth,
                                    senderGroup: "",
                                    sort: documentState.documentFilters.sort,
                                    type: documentState.documentFilters.type,
                                    typology: value!,
                                    fileName: documentState.documentFilters.fileName,
                                    status: documentState.documentFilters.status
                                }))
                            else
                                dispatch(findAllSentDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: documentState.documentFilters.order,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: '',
                                    referenceYear: documentState.documentFilters.referenceYear,
                                    referenceMonth: documentState.documentFilters.referenceMonth,
                                    senderGroup: documentState.documentFilters.senderGroup,
                                    sort: documentState.documentFilters.sort,
                                    type: documentState.documentFilters.type,
                                    typology: value!,
                                    fileName: documentState.documentFilters.fileName,
                                    status: documentState.documentFilters.status
                                }))
                        } else {
                            dispatch(findAllDocuments({
                                companyId: documentState.documentFilters.companyId,
                                itemsPerPage: documentState.documentFilters.itemsPerPage,
                                order: documentState.documentFilters.order,
                                page: documentState.documentFilters.page,
                                receiverGroup: documentState.documentFilters.receiverGroup,
                                referenceYear: documentState.documentFilters.referenceYear,
                                referenceMonth: documentState.documentFilters.referenceMonth,
                                senderGroup: documentState.documentFilters.senderGroup,
                                sort: documentState.documentFilters.sort,
                                type: documentState.documentFilters.type,
                                typology: value!,
                                fileName: documentState.documentFilters.fileName,
                                status: documentState.documentFilters.status
                            }))
                        }
                        dispatch(setDocumentFilterTypology(value))
                    }}
                    options={options}
                    value={documentState.documentFilters.typology || null}
                />
            </div>
            {
                type !== DocumentType.accounting &&
                <div className={"w-1/4"}>
                    <SelectComponent
                        placeholder="Stato"
                        onChange={(e) => {
                            dispatch(findAllDocuments({
                                companyId: documentState.documentFilters.companyId,
                                itemsPerPage: documentState.documentFilters.itemsPerPage,
                                order: documentState.documentFilters.order,
                                page: documentState.documentFilters.page,
                                receiverGroup: documentState.documentFilters.receiverGroup,
                                referenceYear: documentState.documentFilters.referenceYear,
                                referenceMonth: documentState.documentFilters.referenceMonth,
                                senderGroup: documentState.documentFilters.senderGroup,
                                sort: documentState.documentFilters.sort,
                                type: documentState.documentFilters.type,
                                typology: documentState.documentFilters.typology,
                                fileName: documentState.documentFilters.fileName,
                                status: e!
                            }))
                            dispatch(setDocumentFilterStatus(e))
                        }}
                        options={[
                            {
                                value: DocumentStatus.read, label: "Letto",
                            },
                            {
                                value: DocumentStatus.toRead, label: "Da leggere"
                            }
                        ]}
                        value={documentState.documentFilters.status || null}
                    />
                </div>
            }
            {
                type !== DocumentType.balance && type !== DocumentType.f24 && type !== DocumentType.other &&
                <div className={"w-1/4"}>
                    <SelectComponent
                        placeholder="Mese"
                        onChange={(value) => {
                            if (companyState.selectedDocumentTab === 3) {
                                if (documentState.selectedSentOrReceivedDocumentTab === 1)
                                    dispatch(findAllReceivedDocuments({
                                        companyId: documentState.documentFilters.companyId,
                                        itemsPerPage: documentState.documentFilters.itemsPerPage,
                                        order: documentState.documentFilters.order,
                                        page: documentState.documentFilters.page,
                                        receiverGroup: documentState.documentFilters.receiverGroup,
                                        referenceYear: documentState.documentFilters.referenceYear,
                                        referenceMonth: Number(value),
                                        senderGroup: "",
                                        sort: documentState.documentFilters.sort,
                                        type: documentState.documentFilters.type,
                                        typology: documentState.documentFilters.typology,
                                        fileName: documentState.documentFilters.fileName,
                                        status: documentState.documentFilters.status
                                    }))
                                else
                                    dispatch(findAllSentDocuments({
                                        companyId: documentState.documentFilters.companyId,
                                        itemsPerPage: documentState.documentFilters.itemsPerPage,
                                        order: documentState.documentFilters.order,
                                        page: documentState.documentFilters.page,
                                        receiverGroup: '',
                                        referenceYear: documentState.documentFilters.referenceYear,
                                        referenceMonth: Number(value),
                                        senderGroup: documentState.documentFilters.senderGroup,
                                        sort: documentState.documentFilters.sort,
                                        type: documentState.documentFilters.type,
                                        typology: documentState.documentFilters.typology,
                                        fileName: documentState.documentFilters.fileName,
                                        status: documentState.documentFilters.status
                                    }))
                            } else {
                                dispatch(findAllDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: documentState.documentFilters.order,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: documentState.documentFilters.receiverGroup,
                                    referenceYear: documentState.documentFilters.referenceYear,
                                    referenceMonth: Number(value),
                                    senderGroup: documentState.documentFilters.senderGroup,
                                    sort: documentState.documentFilters.sort,
                                    type: documentState.documentFilters.type,
                                    typology: documentState.documentFilters.typology,
                                    fileName: documentState.documentFilters.fileName,
                                    status: documentState.documentFilters.status
                                }))
                            }
                            dispatch(setDocumentFilterMonth(value))
                        }}
                        options={[
                            { value: 1, label: "Gennaio" },
                            { value: 2, label: "Febbraio" },
                            { value: 3, label: "Marzo" },
                            { value: 4, label: "Aprile" },
                            { value: 5, label: "Maggio" },
                            { value: 6, label: "Giugno" },
                            { value: 7, label: "Luglio" },
                            { value: 8, label: "Agosto" },
                            { value: 9, label: "Settembre" },
                            { value: 10, label: "Ottobre" },
                            { value: 11, label: "Novembre" },
                            { value: 12, label: "Dicembre" },
                        ]}
                        value={documentState.documentFilters.referenceMonth}
                    />
                </div>
            }
            {
                type !== DocumentType.f24 && type !== DocumentType.other &&
                <div className={"w-1/4"}>
                    <SelectComponent
                        placeholder="Anno"
                        onChange={(value) => {
                            if (companyState.selectedDocumentTab === 3) {
                                if (documentState.selectedSentOrReceivedDocumentTab === 1)
                                    dispatch(findAllReceivedDocuments({
                                        companyId: documentState.documentFilters.companyId,
                                        itemsPerPage: documentState.documentFilters.itemsPerPage,
                                        order: documentState.documentFilters.order,
                                        page: documentState.documentFilters.page,
                                        receiverGroup: documentState.documentFilters.receiverGroup,
                                        referenceYear: Number(value),
                                        referenceMonth: documentState.documentFilters.referenceMonth,
                                        senderGroup: "",
                                        sort: documentState.documentFilters.sort,
                                        type: documentState.documentFilters.type,
                                        typology: documentState.documentFilters.typology,
                                        fileName: documentState.documentFilters.fileName,
                                        status: documentState.documentFilters.status
                                    }))
                                else
                                    dispatch(findAllSentDocuments({
                                        companyId: documentState.documentFilters.companyId,
                                        itemsPerPage: documentState.documentFilters.itemsPerPage,
                                        order: documentState.documentFilters.order,
                                        page: documentState.documentFilters.page,
                                        receiverGroup: '',
                                        referenceYear: Number(value),
                                        referenceMonth: documentState.documentFilters.referenceMonth,
                                        senderGroup: documentState.documentFilters.senderGroup,
                                        sort: documentState.documentFilters.sort,
                                        type: documentState.documentFilters.type,
                                        typology: documentState.documentFilters.typology,
                                        fileName: documentState.documentFilters.fileName,
                                        status: documentState.documentFilters.status
                                    }))
                            } else {
                                dispatch(findAllDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: documentState.documentFilters.order,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: documentState.documentFilters.receiverGroup,
                                    referenceYear: Number(value),
                                    referenceMonth: documentState.documentFilters.referenceMonth,
                                    senderGroup: documentState.documentFilters.senderGroup,
                                    sort: documentState.documentFilters.sort,
                                    type: documentState.documentFilters.type,
                                    typology: documentState.documentFilters.typology,
                                    fileName: documentState.documentFilters.fileName,
                                    status: documentState.documentFilters.status
                                }))
                            }
                            dispatch(setDocumentFilterYear(value))
                        }}
                        options={years}
                        value={documentState.documentFilters.referenceYear}
                    />
                </div>
            }
            {
                (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) && companyState.selectedDocumentTab === 3 && documentState.selectedSentOrReceivedDocumentTab === 1 &&
                <div className={"w-1/4"}>
                    <SelectComponent
                        placeholder="Ricevuto"
                        onChange={(value) => {
                            dispatch(findAllReceivedDocuments({
                                companyId: documentState.documentFilters.companyId,
                                itemsPerPage: documentState.documentFilters.itemsPerPage,
                                order: documentState.documentFilters.order,
                                page: documentState.documentFilters.page,
                                receiverGroup: value as "operators" | "companies",
                                referenceYear: documentState.documentFilters.referenceYear,
                                referenceMonth: documentState.documentFilters.referenceMonth,
                                senderGroup: "",
                                sort: documentState.documentFilters.sort,
                                type: documentState.documentFilters.type,
                                typology: documentState.documentFilters.typology,
                                fileName: documentState.documentFilters.fileName,
                                status: documentState.documentFilters.status
                            }))
                            dispatch(setDocumentFilterReceivedGroup(value))
                        }}
                        options={
                            [
                                { value: "operators", label: "Commercialista" },
                                { value: "companies", label: "Azienda" }
                            ]
                        }
                        value={documentState.documentFilters.receiverGroup}
                    />
                </div>
            }
            <div className={"gap-2 flex flex-row"}>
                <ButtonComponent
                    label="Pulisci"
                    size={"md"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"gray"}
                    onClick={() => {
                        if (companyState.selectedDocumentTab === 3) {
                            dispatch(setDocumentFilterFileName(""))
                            dispatch(setDocumentFilterTypology(""))
                            dispatch(setDocumentFilterYear(0))
                            dispatch(setDocumentFilterMonth(0))
                            dispatch(setDocumentFilterReceivedGroup(""))
                            dispatch(setDocumentFilterSenderGroup(""))
                            dispatch(setDocumentFilterStatus(""))
                            if (documentState.selectedSentOrReceivedDocumentTab === 1)
                                dispatch(findAllReceivedDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: false,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: '',
                                    referenceYear: 0,
                                    referenceMonth: 0,
                                    senderGroup: "",
                                    sort: "",
                                    type: documentState.documentFilters.type,
                                    typology: "",
                                    fileName: "",
                                    status: ""
                                }))
                            else
                                dispatch(findAllSentDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: false,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: '',
                                    referenceYear: 0,
                                    referenceMonth: 0,
                                    senderGroup: "",
                                    sort: "",
                                    type: documentState.documentFilters.type,
                                    typology: "",
                                    fileName: "",
                                    status: ""
                                }))
                        } else {
                            dispatch(setDocumentFilterFileName(""))
                            dispatch(setDocumentFilterTypology(""))
                            dispatch(setDocumentFilterYear(0))
                            dispatch(setDocumentFilterMonth(0))
                            dispatch(setDocumentFilterStatus(""))
                            dispatch(findAllDocuments({
                                companyId: documentState.documentFilters.companyId,
                                itemsPerPage: documentState.documentFilters.itemsPerPage,
                                order: false,
                                page: documentState.documentFilters.page,
                                receiverGroup: '',
                                referenceYear: 0,
                                referenceMonth: 0,
                                senderGroup: "",
                                sort: "",
                                type: documentState.documentFilters.type,
                                typology: "",
                                fileName: "",
                                status: ""
                            }))
                        }
                    }}
                />
                <ButtonComponent
                    label="Applica"
                    size={"md"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"blue"}
                    onClick={() => {
                        if (companyState.selectedDocumentTab === 3) {
                            if (documentState.selectedSentOrReceivedDocumentTab === 1)
                                dispatch(findAllReceivedDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: documentState.documentFilters.order,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: documentState.documentFilters.receiverGroup,
                                    referenceYear: documentState.documentFilters.referenceYear,
                                    referenceMonth: documentState.documentFilters.referenceMonth,
                                    senderGroup: "",
                                    sort: documentState.documentFilters.sort,
                                    type: documentState.documentFilters.type,
                                    typology: documentState.documentFilters.typology,
                                    fileName: documentState.documentFilters.fileName,
                                    status: documentState.documentFilters.status
                                }))
                            else
                                dispatch(findAllSentDocuments({
                                    companyId: documentState.documentFilters.companyId,
                                    itemsPerPage: documentState.documentFilters.itemsPerPage,
                                    order: documentState.documentFilters.order,
                                    page: documentState.documentFilters.page,
                                    receiverGroup: '',
                                    referenceYear: documentState.documentFilters.referenceYear,
                                    referenceMonth: documentState.documentFilters.referenceMonth,
                                    senderGroup: documentState.documentFilters.senderGroup,
                                    sort: documentState.documentFilters.sort,
                                    type: documentState.documentFilters.type,
                                    typology: documentState.documentFilters.typology,
                                    fileName: documentState.documentFilters.fileName,
                                    status: documentState.documentFilters.status
                                }))
                        } else {
                            dispatch(findAllDocuments({
                                companyId: documentState.documentFilters.companyId,
                                itemsPerPage: documentState.documentFilters.itemsPerPage,
                                order: documentState.documentFilters.order,
                                page: documentState.documentFilters.page,
                                receiverGroup: documentState.documentFilters.receiverGroup,
                                referenceYear: documentState.documentFilters.referenceYear,
                                referenceMonth: documentState.documentFilters.referenceMonth,
                                senderGroup: documentState.documentFilters.senderGroup,
                                sort: documentState.documentFilters.sort,
                                type: documentState.documentFilters.type,
                                typology: documentState.documentFilters.typology,
                                fileName: documentState.documentFilters.fileName,
                                status: documentState.documentFilters.status
                            }))
                        }
                    }}
                />
            </div>
        </div>
    )
}