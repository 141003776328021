import React from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { SearchIcon } from "../../ui/icons/searchIcon.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { findAllFinancialAdvisor, setFindAllFinancialAdvisorsFilterName } from "./slice.ts";

export function FinancialAdvisorFilters() {
    const dispatch = useAppDispatch()
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    return (
        <div className={"flex flex-row gap-4 w-full"}>
            <div className={"w-full"}>
                <InputComponent
                    type={"text"}
                    placeholder="Cerca un commercialista"
                    startIcon={<SearchIcon />}
                    onChangeText={(value) => dispatch(setFindAllFinancialAdvisorsFilterName(value))}
                    value={financialAdvisorState.findAllFinancialAdvisorFilters.name}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
                        }
                    }}
                />
            </div>
            <div className={"gap-4 flex flex-row"}>
                <ButtonComponent
                    label={"Pulisci"}
                    onClick={() => {
                        dispatch(setFindAllFinancialAdvisorsFilterName(""))
                        dispatch(findAllFinancialAdvisor({
                            page: financialAdvisorState.findAllFinancialAdvisorFilters.page,
                            itemsPerPage: financialAdvisorState.findAllFinancialAdvisorFilters.itemsPerPage,
                            name: "",
                            sort: financialAdvisorState.findAllFinancialAdvisorFilters.sort,
                            order: financialAdvisorState.findAllFinancialAdvisorFilters.order
                        }))
                    }}
                    color={"gray"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition="off"
                />
                <ButtonComponent
                    label={"Applica"}
                    onClick={() => {
                        dispatch(findAllFinancialAdvisor({
                            page: financialAdvisorState.findAllFinancialAdvisorFilters.page,
                            itemsPerPage: financialAdvisorState.findAllFinancialAdvisorFilters.itemsPerPage,
                            name: financialAdvisorState.findAllFinancialAdvisorFilters.name,
                            sort: financialAdvisorState.findAllFinancialAdvisorFilters.sort,
                            order: financialAdvisorState.findAllFinancialAdvisorFilters.order
                        }))
                    }}
                    color={"blue"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition="off"
                />
            </div>
        </div>
    )
}