import React from "react";
import { IconsProps } from "./dto";

export function BalanceIcon(props: IconsProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6983_9792)">
                <path d="M17.6747 13.2417C17.1445 14.4955 16.3153 15.6003 15.2596 16.4595C14.2038 17.3187 12.9537 17.9063 11.6184 18.1707C10.2831 18.4352 8.90337 18.3685 7.59981 17.9766C6.29624 17.5846 5.10854 16.8793 4.14053 15.9223C3.17253 14.9653 2.4537 13.7857 2.04688 12.4867C1.64006 11.1877 1.55765 9.80882 1.80685 8.47061C2.05605 7.1324 2.62926 5.8756 3.47639 4.81011C4.32351 3.74461 5.41875 2.90285 6.66635 2.35841M18.333 10.0001C18.333 8.90573 18.1175 7.8221 17.6987 6.81105C17.2799 5.80001 16.6661 4.88135 15.8922 4.10752C15.1184 3.3337 14.1998 2.71987 13.1887 2.30109C12.1777 1.8823 11.094 1.66675 9.99968 1.66675V10.0001H18.333Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6983_9792">
                    <rect width={props.size} height={props.size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}