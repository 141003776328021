import React, { ReactNode } from "react";

interface Props {
    children: ReactNode
}

export function HorizontalSecondaryTab(props: Props) {
    const { children } = props;

    return (
        <>
            <div className={"flex flex-row gap-1 px-1"}>
                {children}
            </div>
            <div className="w-full h-[2px] bg-neutral-200 mt-[-2px]" />
        </>
    )
}