import React from "react";

export function UploadFileIcon() {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 0.840535V4.8335C9.5 5.38578 9.94772 5.8335 10.5 5.8335H14.4591M4.5 15.8335H12.5C13.6046 15.8335 14.5 14.9381 14.5 13.8335V6.1812C14.5 5.79741 14.3529 5.42821 14.0889 5.14959L10.4437 1.30188C10.1605 1.00287 9.76669 0.833496 9.3548 0.833496H4.5C3.39543 0.833496 2.5 1.72893 2.5 2.8335V13.8335C2.5 14.9381 3.39543 15.8335 4.5 15.8335Z" stroke="#8383AD" strokeLinejoin="round" />
            <path d="M15 20C17.7614 20 20 17.7614 20 15C20 12.2386 17.7614 10 15 10C12.2386 10 10 12.2386 10 15C10 17.7614 12.2386 20 15 20Z" fill="#30A375" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.2853 13.2856L14.1424 16.4285L12.7139 14.9999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}