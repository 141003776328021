import React from 'react';
import { colors } from '../../colors.ts';
import { CloseIcon } from '../../icons/closeIcon.tsx';
import './style.css';

interface Props {
    active: boolean;
    close: () => void;
    message?: string;
}

export function ErrorPopup(props: Props) {
    const { active } = props;
    const { close } = props;
    const { message } = props;

    if (!active) {
        return null;
    }

    setTimeout(() => close(), 4000);

    return (
        <div className={'popup-error flex items-center justify-between p-2'}>
            <div>
                <p className={'text-text-md font-medium text-white'}>{message}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => close()}><CloseIcon color={colors.white} size={'24'} /></div>
        </div>
    );
}
