
export interface FindAllCompanyResponseDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: CompanyDTO[]
}

export interface CompanyDTO {
    name: string
    surname: string
    email: string
    userId: string
    id: string
    companyName: string
    administrativeCompanies: string[]
    financialAdvisor: string
    collaborators: string[]
    note: string
    avatarObjectId: string
}

export interface CompanyFiltersDTO {
    itemsPerPage: number
    order: boolean
    page: number
    sort: string
    companyName: string
    financialAdvisorId: string
}

export interface CreateCompanyRequestDTO {
    name: string
    surname: string
    email: string
    companyName: string
    administrativeCompanyId: number
    financialAdvisorId: number
    collaboratorId: number
    note: string
    avatar: File | null
}

export interface EditCompanyRequestDTO {
    name: string
    surname: string
    companyName: string
    note: string
    avatar: File | null
}

export const tabOperationMap = {
    0: ["Aziende", "Overview"],
    1: ["Aziende", "Documenti"],
    2: ["Aziende", "Messaggi"],
    3: ["Aziende", "Conto corrente"],
    4: ["Aziende", "Calendario"],
};

export const tabOperationCompanyMap = {
    0: ["Documenti", "Bilanci e Dichiarativi"],
    1: ["Documenti", "F24"],
    2: ["Documenti", "Dipendenti"],
    3: ["Documenti", "Contabili"],
    4: ["Documenti", "Altro"],
};

export const tabSettingMap = {
    0: ["Aziende", "Impostazioni azienda", "Anagrafica"],
    1: ["Aziende", "Impostazioni azienda", "Referenti"],
    2: ["Aziende", "Impostazioni azienda", "Ruoli"],
};

export const tabSettingCompanyMap = {
    0: ["Impostazioni", "Anagrafica"],
    1: ["Impostazioni", "Referenti"],
};
