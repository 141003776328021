import React from 'react';
import { IconsProps } from './dto.ts';

export function DashboardIcon(props: IconsProps) {

    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9999 16.6667V8.33333M9.99994 16.6667V3.33333M4.99994 16.6667V11.6667" stroke={props.color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}