import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/utilis/index.ts";
import { CompanyDTO } from "../company/dto.ts";
import { ReferrersDTO } from "../company/referrers/dto.ts";
import { CollaboratorDTO } from "../financialAdvisor/collaborator/dto.ts";
import { FinancialAdvisorDTO } from "../financialAdvisor/dto.ts";
import { OperatorDTO } from "../operator/dto.ts";
import { NewAuthService } from "./service.ts";

interface AuthState {
    findMeFinancialAdvisorStatus: PromiseStatuses
    findMeFinancialAdvisorResponse?: FinancialAdvisorDTO
    findMeReferrersStatus: PromiseStatuses
    findMeReferrersResponse?: ReferrersDTO
    findMeCollaboratorStatus: PromiseStatuses
    findMeCollaboratorResponse?: CollaboratorDTO
    findMeCompanyStatus: PromiseStatuses
    findMeCompanyResponse?: CompanyDTO
    findMeOperatorStatus: PromiseStatuses
    findMeOperatorResponse?: OperatorDTO
}

const initialState: AuthState = {
    findMeFinancialAdvisorStatus: 'idle',
    findMeCollaboratorStatus: 'idle',
    findMeCompanyStatus: 'idle',
    findMeOperatorStatus: 'idle',
    findMeReferrersStatus: 'idle'
}

export const findMeFinancialAdvisor = createAsyncThunk(
    'auth/findMeFinancialAdvisor',
    async (_, thunkApi): Promise<FinancialAdvisorDTO> => {
        const authService = NewAuthService()
        return authService.findMeFinancialAdvisor().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findMeCollaborator = createAsyncThunk(
    'auth/findMeCollaborator',
    async (_, thunkApi): Promise<CollaboratorDTO> => {
        const authService = NewAuthService()
        return authService.findMeCollaborator().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findMeCompany = createAsyncThunk(
    'auth/findMeCompany',
    async (_, thunkApi): Promise<CompanyDTO> => {
        const authService = NewAuthService()
        return authService.findMeCompany().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findMeOperator = createAsyncThunk(
    'auth/findMeOperator',
    async (_, thunkApi): Promise<OperatorDTO> => {
        const authService = NewAuthService()
        return authService.findMeOperator().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findMeReferrer = createAsyncThunk(
    'auth/findMeReferrer',
    async (_, thunkApi): Promise<ReferrersDTO> => {
        const authService = NewAuthService()
        return authService.findMeReferrer().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

const authSlice = createSlice({
    name: 'auth/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findMeFinancialAdvisor.pending, (state) => {
                state.findMeFinancialAdvisorStatus = 'loading'
            })
            .addCase(findMeFinancialAdvisor.fulfilled, (state, action) => {
                state.findMeFinancialAdvisorStatus = 'successfully'
                state.findMeFinancialAdvisorResponse = action.payload
            })
            .addCase(findMeFinancialAdvisor.rejected, (state) => {
                state.findMeFinancialAdvisorStatus = 'failed'
            })
            .addCase(findMeCollaborator.pending, (state) => {
                state.findMeCollaboratorStatus = 'loading'
            })
            .addCase(findMeCollaborator.fulfilled, (state, action) => {
                state.findMeCollaboratorStatus = 'successfully'
                state.findMeCollaboratorResponse = action.payload
            })
            .addCase(findMeCollaborator.rejected, (state) => {
                state.findMeCollaboratorStatus = 'failed'
            })
            .addCase(findMeCompany.pending, (state) => {
                state.findMeCompanyStatus = 'loading'
            })
            .addCase(findMeCompany.fulfilled, (state, action) => {
                state.findMeCompanyStatus = 'successfully'
                state.findMeCompanyResponse = action.payload
            })
            .addCase(findMeCompany.rejected, (state) => {
                state.findMeCompanyStatus = 'failed'
            })
            .addCase(findMeOperator.pending, (state) => {
                state.findMeOperatorStatus = 'loading'
            })
            .addCase(findMeOperator.fulfilled, (state, action) => {
                state.findMeOperatorStatus = 'successfully'
                state.findMeOperatorResponse = action.payload
            })
            .addCase(findMeOperator.rejected, (state) => {
                state.findMeOperatorStatus = 'failed'
            })
            .addCase(findMeReferrer.pending, (state) => {
                state.findMeReferrersStatus = 'loading'
            })
            .addCase(findMeReferrer.fulfilled, (state, action) => {
                state.findMeReferrersStatus = 'successfully'
                state.findMeReferrersResponse = action.payload
            })
            .addCase(findMeReferrer.rejected, (state) => {
                state.findMeReferrersStatus = 'failed'
            })
    },
    reducers: {
        setFindMeFinancialAdvisorStatus: (state, action) => {
            state.findMeFinancialAdvisorStatus = action.payload
        },
        setFindMeCollaboratorStatus: (state, action) => {
            state.findMeCollaboratorStatus = action.payload
        },
        setFindMeOperatorStatus: (state, action) => {
            state.findMeOperatorStatus = action.payload
        },
        setFindMeCompanyStatus: (state, action) => {
            state.findMeCompanyStatus = action.payload
        },
        setFindMeReferrerStatus: (state, action) => {
            state.findMeReferrersStatus = action.payload
        }
    },
})

export const {
    setFindMeFinancialAdvisorStatus,
    setFindMeCollaboratorStatus,
    setFindMeOperatorStatus,
    setFindMeCompanyStatus,
    setFindMeReferrerStatus
} = authSlice.actions

export default authSlice.reducer