import React from "react";

export function BreadcrumbsIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.475 18.9583H7.475C2.95 18.9583 1.01666 17.025 1.01666 12.5V7.49996C1.01666 2.97496 2.95 1.04163 7.475 1.04163H12.475C17 1.04163 18.9333 2.97496 18.9333 7.49996V12.5C18.9333 17.025 17.0083 18.9583 12.475 18.9583ZM7.475 2.29163C3.63333 2.29163 2.26666 3.65829 2.26666 7.49996V12.5C2.26666 16.3416 3.63333 17.7083 7.475 17.7083H12.475C16.3167 17.7083 17.6833 16.3416 17.6833 12.5V7.49996C17.6833 3.65829 16.3167 2.29163 12.475 2.29163H7.475Z" fill="#7171B3" />
            <path d="M6.64166 18.9583C6.3 18.9583 6.01666 18.675 6.01666 18.3333V1.66663C6.01666 1.32496 6.3 1.04163 6.64166 1.04163C6.98333 1.04163 7.26666 1.32496 7.26666 1.66663V18.3333C7.26666 18.675 6.99166 18.9583 6.64166 18.9583Z" fill="#7171B3" />
            <path d="M12.475 12.7584C12.3167 12.7584 12.1583 12.7 12.0333 12.575L9.9 10.4417C9.65833 10.2 9.65833 9.80002 9.9 9.55836L12.0333 7.42502C12.275 7.18336 12.675 7.18336 12.9167 7.42502C13.1583 7.66669 13.1583 8.06669 12.9167 8.30836L11.2333 10L12.925 11.6917C13.1667 11.9334 13.1667 12.3334 12.925 12.575C12.8 12.7 12.6417 12.7584 12.475 12.7584Z" fill="#7171B3" />
        </svg>
    )
}