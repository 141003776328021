import React from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { SearchIcon } from "../../../ui/icons/searchIcon.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../../ui/molecules/input/index.tsx";
import { findAllReferrers, setReferrersFiltersName } from "./slice.ts";

export function ReferrersFilters() {
    const dispatch = useAppDispatch()
    const referrersState = useAppSelector(state => state.referrers)

    return (
        <div className={"flex flex-row gap-4 w-full"}>
            <InputComponent
                placeholder="Cerca un referente"
                startIcon={<SearchIcon />}
                type={"text"}
                onChangeText={(value) => dispatch(setReferrersFiltersName(value))}
                value={referrersState.referrersFilter.name}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        dispatch(findAllReferrers(referrersState.referrersFilter))
                    }
                }}
            />
            <div className={"gap-4 flex flex-row"}>
                <ButtonComponent
                    label={"Pulisci"}
                    onClick={() => {
                        dispatch(setReferrersFiltersName(""))
                        dispatch(findAllReferrers({
                            page: referrersState.referrersFilter.page,
                            itemsPerPage: referrersState.referrersFilter.itemsPerPage,
                            name: "",
                            sort: referrersState.referrersFilter.sort,
                            order: referrersState.referrersFilter.order,
                            companyId: referrersState.referrersFilter.companyId
                        }))
                    }}
                    color={"gray"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition="off"
                />
                <ButtonComponent
                    label={"Applica"}
                    onClick={() => {
                        dispatch(findAllReferrers({
                            page: referrersState.referrersFilter.page,
                            itemsPerPage: referrersState.referrersFilter.itemsPerPage,
                            name: referrersState.referrersFilter.name,
                            sort: referrersState.referrersFilter.sort,
                            order: referrersState.referrersFilter.order,
                            companyId: referrersState.referrersFilter.companyId
                        }))
                    }}
                    color={"blue"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition="off"
                />
            </div>
        </div>
    )
}