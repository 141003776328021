import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { MenuKebabIcon } from "../../icons/menuKebabIcon.tsx";
import { DropDownContainer } from "./dropDownContainer.tsx";
import { DropdownProps } from "./dto.ts";

export function Dropdown(props: DropdownProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node) && !buttonRef.current?.contains(event.target as Node)) {
            closeMenu();
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    const menu = isMenuOpen ? (
        <div
            className="absolute z-50"
            ref={menuRef}
            style={{
                top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom + window.scrollY - 10 : 0,
                left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left + window.scrollX + 20 : 0,
                width: buttonRef.current ? buttonRef.current.offsetWidth : 'auto',
            }}
        >
            <DropDownContainer
                onClose={closeMenu}
                items={props.items}
            />
        </div>
    ) : null;

    return (
        <div className="relative mt-1 inline-block">
            <button
                onClick={toggleMenu}
                className="focus:outline-none"
                aria-label="Toggle menu"
                ref={buttonRef}
            >
                <MenuKebabIcon />
            </button>
            {ReactDOM.createPortal(
                menu,
                document.body
            )}
        </div>
    );
}