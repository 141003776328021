import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { NewObjectService } from "../../../../lib/object/service.ts";
import { findFileById, setFindDocumentStatus } from "../../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hook.ts";
import { monthMap } from "../../../../lib/utilis/index.ts";
import { colors } from "../../../../ui/colors.ts";
import { DownloadIcon } from "../../../../ui/icons/downloadIcon.tsx";
import { TrashIcon } from "../../../../ui/icons/trashIcon.tsx";
import { Pills } from "../../../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../../../ui/organisms/dropDown/index.tsx";
import { InfoTextPopup } from "../../../../ui/organisms/infoTextPopup/index.tsx";
import { DocumentStatus, DocumentTypologyMap } from "../dto.ts";
import { deleteDocument, findAllDocuments, findByIdDocument, setDeleteConfirmOrDenyModal } from "../slice.ts";

export function EmployeeList() {
    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)

    const objectService = NewObjectService()

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllDocuments(documentState.documentFilters))
    }, [])

    useEffect(() => {
        if (documentState.saveDocumentStatus === "successfully") {
            dispatch(findAllDocuments(documentState.documentFilters))
        }
    }, [documentState.saveDocumentStatus])

    useEffect(() => {
        if (documentState.deleteDocumentStatus === "successfully") {
            dispatch(findAllDocuments(documentState.documentFilters))
        }
    }, [documentState.deleteDocumentStatus])


    if (documentState.findAllDocumentsStatus === "loading" ||
        documentState.saveDocumentStatus === "loading" ||
        documentState.deleteDocumentStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
            <div>
                <table className="w-full mb-3">
                    <thead className="h-[56px] bg-neutral-100 font-semibold text-neutral-500 text-text-sm">
                        <th className="text-left">
                            <div className="flex py-4 px-2 w-[408px] gap-1">
                                NOME DEL FILE
                            </div>
                        </th>
                        {
                            documentState.findAllDocumentsResponse?.total !== 0 &&
                            <>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 w-[120px] gap-1">
                                        TIPOLOGIA
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 w-[120px] gap-1">
                                        STATO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 w-[120px] gap-1">
                                        MESE
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 w-[120px] gap-1">
                                        ANNO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 w-[120px] gap-1">
                                        DATA INVIO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 w-[160px] gap-1">
                                        ULTIMO DOWNLOAD
                                    </div>
                                </th>
                                <th className="text-left">
                                </th>
                                <th className="text-left">
                                </th>
                            </>
                        }
                    </thead>
                    <tbody>
                        {
                            documentState.findAllDocumentsResponse?.total === 0 ?
                                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                    <td className="py-4 px-2 text-text-sm font-medium text-neutral-500">
                                        Nessun documento trovato!
                                    </td>
                                </tr>
                                :
                                documentState.findAllDocumentsResponse?.data.map(item =>
                                    <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 text-text-md font-semibold text-neutral-600">
                                        <td className="py-4 px-2 text-neutral-800">{item.fileName}</td>
                                        <td className="py-4 px-2"><Pills size={"sm"} label={DocumentTypologyMap.get(item.typology)!} outline={true} emphasis={false} color={"gray"} /></td>
                                        <td className="py-4 px-2"><Pills size={"sm"} label={item.status === DocumentStatus.read ? "Letto" : "Da leggere"} outline={false} emphasis={true} color={item.status === DocumentStatus.read ? "green" : "blue"} /></td>
                                        <td className="py-4 px-2">{monthMap.get(item.referenceMonth)}</td>
                                        <td className="py-4 px-2">{item.referenceYear}</td>
                                        <td className="py-4 px-2">{item.date && format(new Date(item.date), 'dd/MM/yyyy')}</td>
                                        <td className="py-4 px-2">
                                            {
                                                item.companiesDownloadDate !== null ?
                                                    format(new Date(item.companiesDownloadDate), 'dd/MM/yyyy')
                                                    : '-'
                                            }
                                        </td>
                                        <td className="w-[40px]">
                                            <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                                                <InfoTextPopup
                                                    label={"Scarica"}
                                                    children={
                                                        <DownloadIcon
                                                            size={"24"}
                                                            color={colors.brandSecondary[500]}
                                                            onClick={() => {
                                                                dispatch(findFileById(item.objectId.toString())).then((e) => {
                                                                    //@ts-ignore
                                                                    objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                                                    dispatch(findByIdDocument(item.id.toString())).then(() => {
                                                                        dispatch(setFindDocumentStatus('idle'))
                                                                        dispatch(findAllDocuments(documentState.documentFilters))
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    position={"bottom"}
                                                />
                                            </div>
                                        </td>
                                        <td className="flex py-4 px-2 w-[40px] cursor-pointer">
                                            <Dropdown items={[
                                                {
                                                    label: 'Scarica',
                                                    icon: <DownloadIcon size="24" color="" />,
                                                    onClick: () => {
                                                        dispatch(findFileById(item.objectId.toString())).then((e) => {
                                                            //@ts-ignore
                                                            objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                                            dispatch(findByIdDocument(item.id.toString())).then(() => {
                                                                dispatch(setFindDocumentStatus('idle'))
                                                                dispatch(findAllDocuments(documentState.documentFilters))
                                                            })
                                                        })
                                                    }
                                                },
                                                {
                                                    label: 'Cancella',
                                                    icon: <TrashIcon color="" size={""} />,
                                                    onClick: () => {
                                                        setIsDelete(item.id.toString())
                                                        dispatch(setDeleteConfirmOrDenyModal(true))
                                                    },
                                                }
                                            ]} />
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={documentState.deleteConfirmOrDenyModal}
                handleClose={() => dispatch(setDeleteConfirmOrDenyModal(false))}
                title={"Elimina documento"}
                description={"Sei sicuro di voler eliminare questo documento?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteDocument(isDelete))
                    dispatch(setDeleteConfirmOrDenyModal(false))
                }}
            />
        </div>
    )
}