import React from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { SearchIcon } from "../../ui/icons/searchIcon.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { SelectComponent } from "../../ui/molecules/select/index.tsx";
import { findAllCompany, setCompanyFiltersCompanyName, setCompanyFiltersFinancialAdvisorId } from "./slice.ts";

export function CompanyFilters() {
    const dispatch = useAppDispatch()
    const companyState = useAppSelector(state => state.company)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    return (
        <div className={"flex flex-row gap-4 w-full"}>
            <div className={"flex flex-row gap-4 w-full"}>
                <InputComponent
                    placeholder="Cerca azienda"
                    startIcon={<SearchIcon />}
                    type={"text"}
                    onChangeText={(value) => dispatch(setCompanyFiltersCompanyName(value))}
                    defaultValue={companyState.companyFilters.companyName}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            dispatch(findAllCompany(companyState.companyFilters))
                        }
                    }}
                />
                {
                    !keycloak.hasRealmRole('financial_advisor') &&
                    <SelectComponent
                        placeholder="Tutti i commercialisti"
                        onChange={(value) => {
                            dispatch(findAllCompany({
                                itemsPerPage: companyState.companyFilters.itemsPerPage,
                                page: companyState.companyFilters.page,
                                financialAdvisorId: value as string,
                                sort: companyState.companyFilters.sort,
                                companyName: companyState.companyFilters.companyName,
                                order: companyState.companyFilters.order
                            }))
                            dispatch(setCompanyFiltersFinancialAdvisorId(value))
                        }}
                        options={financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((value) => ({ label: (value.commercialName), value: value.id }))!}
                        value={companyState.companyFilters.financialAdvisorId}
                    />
                }
                <div className={"w-1/4"}>
                    <SelectComponent placeholder="Stato" onChange={(e) => null} options={[]} disabled/>
                </div>
            </div>
            <div className={"gap-2 flex flex-row"}>
                <ButtonComponent
                    label="Pulisci"
                    size={"md"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"gray"}
                    onClick={() => {
                        dispatch(setCompanyFiltersCompanyName(""))
                        dispatch(setCompanyFiltersFinancialAdvisorId(""))
                        dispatch(findAllCompany({
                            itemsPerPage: companyState.companyFilters.itemsPerPage,
                            page: companyState.companyFilters.page,
                            financialAdvisorId: "",
                            sort: companyState.companyFilters.sort,
                            companyName: "",
                            order: companyState.companyFilters.order
                        }))
                    }}
                />
                <ButtonComponent
                    label="Applica"
                    size={"md"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"blue"}
                    onClick={() => {
                        dispatch(findAllCompany({
                            itemsPerPage: companyState.companyFilters.itemsPerPage,
                            page: companyState.companyFilters.page,
                            financialAdvisorId: companyState.companyFilters.financialAdvisorId,
                            sort: companyState.companyFilters.sort,
                            companyName: companyState.companyFilters.companyName,
                            order: companyState.companyFilters.order
                        }))
                    }}
                />
            </div>
        </div>
    )
}