import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { FinancialAdvisorIcon } from "../../ui/icons/financialAdvisorIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { Pagination } from "../../ui/organisms/pagination/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { FinancialAdvisorFilters } from "./filters.tsx";
import { FinancialAdvisorList } from "./list.tsx";
import { NewFinancialAdvisorModal } from "./newFinancialAdvisorModal.tsx";
import { findAllFinancialAdvisor, setCreateFinancialAdvisorsEmail, setCreateFinancialAdvisorsName, setCreateFinancialAdvisorsPhoneNumber, setCreateFinancialAdvisorsStatus, setCreateFinancialAdvisorsSurname, setDeleteFinancialAdisorsStatus, setEditFinancialAdisorsStatus, setFindAllFinancialAdvisorsFiltersPage, setOpenNewFinancialAdvisorModal } from "./slice.ts";

export function FinancialAdvisor() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
    }, [financialAdvisorState.findAllFinancialAdvisorFilters.page])

    return (
        <LayoutComponent
            menuItem={MenuItems.FINANCIAL_ADVISOR}
            breadcrumbItems={['Commercialisti']}
            headingLabel={"Commercialisti"}
            headingButtons={[
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: 16 }}>
                    <ButtonComponent
                        label={"Nuovo Commercialista"}
                        onClick={() => {
                            dispatch(setCreateFinancialAdvisorsEmail(""))
                            dispatch(setCreateFinancialAdvisorsName(""))
                            dispatch(setCreateFinancialAdvisorsPhoneNumber(""))
                            dispatch(setCreateFinancialAdvisorsSurname(""))
                            dispatch(setOpenNewFinancialAdvisorModal(true))
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"md"}
                        iconPosition="off"
                    />
                </div>
            ]}
            breadcrumbIcon={<FinancialAdvisorIcon color={colors.brandPrimary[500]} size="20" />}
        >
            <ErrorPopup
                active={financialAdvisorState.createFinancialAdvisorStatus === 'failed'}
                close={() => dispatch(setCreateFinancialAdvisorsStatus('idle'))}
                message="Si è verificato un errore durante la creazione del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.createFinancialAdvisorStatus === 'successfully'}
                close={() => dispatch(setCreateFinancialAdvisorsStatus('idle'))}
                message="Commercialista aggiunto"
            />
            <ErrorPopup
                active={financialAdvisorState.deleteFinancialAdvisorStatus === 'failed'}
                close={() => dispatch(setDeleteFinancialAdisorsStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.deleteFinancialAdvisorStatus === 'successfully'}
                close={() => dispatch(setDeleteFinancialAdisorsStatus('idle'))}
                message="Commercialista eliminato"
            />
            <ErrorPopup
                active={financialAdvisorState.editFinancialAdvisorStatus === 'failed'}
                close={() => dispatch(setEditFinancialAdisorsStatus('idle'))}
                message="Si è verificato un errore durante la modifica del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.editFinancialAdvisorStatus === 'successfully'}
                close={() => dispatch(setEditFinancialAdisorsStatus('idle'))}
                message="Commercialista modificato"
            />
            <FinancialAdvisorFilters />
            <FinancialAdvisorList />
            {
                financialAdvisorState.findAllFinancialAdvisorResponse !== undefined &&
                financialAdvisorState.findAllFinancialAdvisorResponse?.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={financialAdvisorState.findAllFinancialAdvisorResponse?.totalPage || 1}
                        currentPage={financialAdvisorState.findAllFinancialAdvisorFilters.page}
                        setPage={(page) => dispatch(setFindAllFinancialAdvisorsFiltersPage(page))}
                    />
                </div>
            }
            <NewFinancialAdvisorModal />
        </LayoutComponent>
    )
}