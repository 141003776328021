import React from "react";
import { colors } from "../../ui/colors.ts";
import { ActivityIcon } from "../../ui/icons/activityIcon.tsx";
import { CompanyIcon } from "../../ui/icons/companyIcon.tsx";
import { DashboardIcon } from "../../ui/icons/dashboardIcon.tsx";
import { FinancialAdvisorIcon } from "../../ui/icons/financialAdvisorIcon.tsx";
import { MenuKebabIcon } from "../../ui/icons/menuKebabIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { Card } from "../../ui/organisms/card/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";

export function Dashboard() {
    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            breadcrumbItems={['Dashboard']}
            headingLabel={"Dashboard"}
            headingButtons={[]}
            breadcrumbIcon={<DashboardIcon color={colors.brandPrimary[500]} size="24" />}
        >
            <div className={"flex flex-col gap-3 w-full"}>
                <div className={"flex flex-row gap-6 justify-between pb-6"}>
                    <Card
                        icon={<FinancialAdvisorIcon
                            color={colors.green[600]} size="32" />}
                        label={"Commercialisti"}
                        menuIcon={<MenuKebabIcon />}
                        subLabel={"32"}
                        cssIcon={"p-3 flex gap-2 border border-green-300 bg-green-100 rounded-lg"}
                    />
                    <Card
                        icon={<CompanyIcon color={colors.brandPrimary[600]} size="32" />}
                        label={"Aziende"}
                        menuIcon={<MenuKebabIcon />}
                        subLabel={"32"}
                        cssIcon={"p-3 flex gap-2 border border-brandPrimary-300 bg-brandPrimary-100 rounded-lg"}
                    />
                    <Card
                        icon={<ActivityIcon color={colors.warning[600]} size="32" />}
                        label={"Attività"}
                        menuIcon={<MenuKebabIcon />}
                        subLabel={"32"}
                        cssIcon={"p-3 flex gap-2 border border-warning-300 bg-warning-100 rounded-lg"}
                    />
                </div>
            </div>
        </LayoutComponent>
    )
}