import axios from "axios";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { EventCreationDTO, EventDTO, EventsFilters, FindAllEventsDTO, UpdateEventStatusRequest } from "./dto.ts";
import { EventsService } from "./service.ts";

export class EventsServiceImpl implements EventsService {

    public findAllEvents(filters: EventsFilters): Promise<FindAllEventsDTO> {
        let url: string = "/api/events"
        let params = {
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            itemsPerPage: filters.itemsPerPage,
            page: filters.page,
            companyId: filters.companyId,
            order: filters.order,
            sort: filters.sort
        }
        return axios({
            url: url,
            method: "GET",
            params: params,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createEvent(request: EventCreationDTO): Promise<void> {
        let url: string = "/api/events"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByIdEvent(id: string): Promise<EventDTO> {
        let url: string = "/api/events/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editEvent(id: string, request: EventCreationDTO): Promise<void> {
        let url: string = "/api/events/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteEvent(id: string): Promise<void> {
        let url: string = "/api/events/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public updateStatus(id: string, data: UpdateEventStatusRequest): Promise<void> {
        let url: string = "/api/events/" + id + "/updateStatus"
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
} 