export interface FindAllDocumentsResponseDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: DocumentDTO[]
}

export interface DocumentDTO {
    id: number
    userId: string
    fileName: string
    type: DocumentType
    typology: DocumentTypology
    date: Date
    referenceYear: number
    referenceMonth: number
    referenceDate: Date
    note: string
    companiesDownloadDate: Date | null
    operatorsDownloadDate: Date | null
    senderGroup: 'operators' | 'companies' | 'admin' | ''
    receiverGroup: 'operators' | 'companies' | ''
    companyId: number
    eventId: number
    objectId: number
    status: DocumentStatus
}

export interface DocumentFilters {
    companyId: number
    itemsPerPage: number
    order: boolean
    page: number
    receiverGroup: 'operators' | 'companies' | ''
    referenceYear: number
    referenceMonth: number
    senderGroup: 'operators' | 'companies' | 'admin' | ''
    sort: string
    type: DocumentType
    typology: DocumentTypology | string
    fileName: string
    status: DocumentStatus | string
}

export interface DocumentCreationDTO {
    fileName: string
    type: DocumentType
    typology: DocumentTypology
    date: string
    referenceYear: number
    referenceMonth: number
    referenceDate: string
    note: string
    senderGroup: 'operators' | 'companies' | 'admin'
    receiverGroup: 'operators' | 'companies'
    companyId: number
}

export enum DocumentType {
    accounting = 'accounting',
    balance = 'balance',
    f24 = 'f24',
    employees = 'employees',
    other = 'other'
}

export enum DocumentStatus {
    toRead = "toRead",
    read = "read"
}

export enum DocumentTypology {
    unique = 'unique',
    iva = 'iva',
    document770 = 'document770',
    balanceDocument = 'balanceDocument',
    inspection = 'inspection',
    durc = 'durc',
    other = 'other',
    contributions = 'contributions',
    directTax = 'directTax',
    paySlip = 'paySlip',
    cu = 'cu',
    attendance = 'attendance',
    bankStatement = 'bankStatement',
    invoice = 'invoice',
    receipt = 'receipt',
    insurance = 'insurance',
    loan = 'loan',
    paypal = 'paypal'
}

export const DocumentTypologyMap = new Map<DocumentTypology, string>([
    [DocumentTypology.attendance, 'Presenze'],
    [DocumentTypology.balanceDocument, 'Bilanci'],
    [DocumentTypology.contributions, 'Contributi'],
    [DocumentTypology.cu, 'CU'],
    [DocumentTypology.directTax, 'Imposte dirette'],
    [DocumentTypology.document770, '770'],
    [DocumentTypology.durc, 'Durc'],
    [DocumentTypology.inspection, 'Visura'],
    [DocumentTypology.iva, 'IVA'],
    [DocumentTypology.other, 'Altro'],
    [DocumentTypology.paySlip, 'Busta paga'],
    [DocumentTypology.unique, 'Unico'],
    [DocumentTypology.bankStatement, 'Estratti conto'],
    [DocumentTypology.invoice, 'Fatture'],
    [DocumentTypology.receipt, 'Ricevute'],
    [DocumentTypology.loan, 'Finanziamenti'],
    [DocumentTypology.insurance, 'Assicurazioni'],
    [DocumentTypology.paypal, 'Paypal, Stripe, ecc']
])

export const DocumentStatusMap = new Map<DocumentStatus, string>([
    [DocumentStatus.read, 'Letto'],
    [DocumentStatus.toRead, 'Da leggere'],
])