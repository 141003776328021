import { createSlice } from "@reduxjs/toolkit"

interface SecuredState {
    userRoles: string[]
    keycloakInitStatus: 'idle' | 'loading' | 'successfully' | 'failed'
}

const initialState: SecuredState = {
    userRoles: [],
    keycloakInitStatus: 'idle',
}

const securedSlice = createSlice({
    name: 'secured/slice',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.userRoles = action.payload
        },
        setKeycloakInitStatus: (state, action) => {
            state.keycloakInitStatus = action.payload
        },
    },
})

export const {
    setRoles,
    setKeycloakInitStatus,
} = securedSlice.actions

export default securedSlice.reducer