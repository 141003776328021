export enum MenuItems {
    DASHBOARD = 'DASHBOARD',
    COMPANY = 'COMPANY',
    FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
    OPERATOR = 'OPERATOR',
    NOTICE = 'NOTICE',
    CALENDAR = 'CALENDAR',
    DOCUMENT = 'DOCUMENT',
    SETTING = 'SETTING',
    CHAT = 'CHAT',
    COLLABORATOR = 'COLLABORATOR'
}