import clsx from "clsx"
import React from "react"

interface Props {
    id?: string
    checked: boolean
    disabled?: boolean
    label?: string
    labelDescription?: string
    onChange?: (checked: boolean) => void
}

export function Switch(props: Props) {
    const { id } = props;
    const { checked } = props;
    const { disabled } = props
    const { label } = props;
    const { labelDescription } = props;
    const { onChange } = props;

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label
                id={id}
                className={"inline-block w-11 h-6 relative m-0 p-0 cursor-pointer"}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={(checked) => onChange && onChange(checked.currentTarget.checked)}
                    className={"w-0 h-0 m-0 opacity-0"}
                />
                <span
                    className={
                        clsx("absolute top-0 left-0 right-0 bottom-0 duration-300 rounded-xl",
                            {
                                "bg-brandPrimary-500": checked,
                                "bg-brandPrimary-100": !checked,
                                "cursor-pointer": !disabled,
                                "cursor-not-allowed": disabled
                            }
                        )
                    }
                />
                <span
                    className={
                        clsx("absolute top-[2px] w-5 h-5 bg-white rounded-[50%] duration-300 shadow-sm",
                            {
                                "left-[22px]": checked,
                                "left-[2px]": !checked
                            }
                        )
                    }
                />
            </label>
            {(label || labelDescription) && (
                <p
                    style={{ margin: 0, paddingLeft: '12px' }}
                    className="text-text-md font-medium text-neutral-500"
                >
                    {label}
                    <br />
                    <span style={{ margin: 0 }} className="text-text-sm font-normal text-neutral-500">
                        {labelDescription}
                    </span>
                </p>
            )}
        </div>
    );
}