import React from "react";
import { colors } from "../../colors.ts";
import { ButtonComponent } from "../../molecules/button/index.tsx";
import { ModalComponent } from "../modal/index.tsx";

interface Props {
    open: boolean
    handleClose: (open: boolean) => void
    title: string
    description: string
    labelDeny: string
    labelConfirm: string
    actionConfirm: Function
    isRedConfirmButton?: boolean
}

export function ConfirmModal(props: Props) {
    const { open } = props
    const { handleClose } = props
    const { title } = props
    const { description } = props
    const { labelDeny } = props
    const { labelConfirm } = props
    const { actionConfirm } = props
    const { isRedConfirmButton } = props

    return (
        <ModalComponent
            open={open}
            handleClose={handleClose}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: "0px 12px 24px rgba(34, 36, 47, 0.16)",
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div className="flex items-center justify-center">
                    <span className="text-text-xl font-semibold text-neutral-800">{title}</span>
                </div>
                <div className="flex flex-col items-center justify-center gap-4 w-full">
                    <span className="text-text-md font-normal text-neutral-400">{description}</span>
                </div>
                <div className="flex flex-row items-center justify-center gap-3 p-0 m-o">
                    <ButtonComponent
                        label={labelDeny}
                        onClick={() => handleClose(false)}
                        color={"gray"}
                        variant={"outline"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                    <ButtonComponent
                        label={labelConfirm}
                        onClick={() => actionConfirm()}
                        color={isRedConfirmButton ? "red" : "blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                </div>
            </div>
        </ModalComponent >
    )
}
