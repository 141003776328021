import React, { ReactElement, useState } from "react";
import { ButtonColor, ButtonIconPosition, ButtonSize, ButtonVariant, buttonIconPositionMap, buttonLabelSizeMap, buttonSizeMap, buttonVariantKey, colorKey, } from './style.ts';

interface Props {
    size: ButtonSize;
    label?: string;
    disabled?: boolean;
    icon?: ReactElement
    iconPosition: ButtonIconPosition
    variant: ButtonVariant
    color: ButtonColor
    onClick?: () => void
    fullWidth?: boolean
}

export function ButtonComponent(props: Props) {
    const { label } = props
    const { color } = props
    const { variant } = props
    const { size } = props
    const { icon } = props
    const { onClick } = props
    const { disabled } = props
    const { iconPosition } = props
    const { fullWidth } = props

    const [isDarker, setIsDarker] = useState(false);

    const handleMouseEnter = () => setIsDarker(true);
    const handleMouseLeave = () => setIsDarker(false);
    const handleFocus = () => setIsDarker(true);
    const handleBlur = () => setIsDarker(false);

    if ("paintWorklet" in CSS) {
        //@ts-ignore
        CSS.paintWorklet.addModule(
            "https://www.unpkg.com/css-houdini-squircle@0.3.0/squircle.min.js"
        );
    }

    return (
        <button
            onClick={() => onClick && onClick()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            style={{
                ...buttonSizeMap.get(size),
                ...colorKey(color, buttonVariantKey(variant, isDarker)).buttonVariant,
                ...buttonIconPositionMap.get(iconPosition),
                ...{ opacity: disabled ? 0.5 : 1, borderRadius: '8px', width: fullWidth ? '100%' : '', display: "flex", justifyContent: 'center', alignItems: 'center', gap: 8, borderWidth: '1px', borderStyle: 'solid', cursor: 'pointer', },
            }}
        >
            {iconPosition !== 'off' && icon && React.cloneElement(icon, { color: colorKey(props.color, buttonVariantKey(variant, isDarker)).buttonIconVariant, size: 24 })}
            {
                props.label && props.iconPosition !== 'only' &&
                <span style={{ ...buttonLabelSizeMap.get(size), ...{ color: colorKey(color, buttonVariantKey(variant, isDarker)).buttonIconVariant } }}>{label}</span>
            }
        </button>

    )
}