import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import SecuredReducer from "../../lib/keycloak/slice.ts";
import AuthReducer from "../../pages/auth/slice.ts";
import DocumentReducer from "../../pages/company/documents/slice.ts";
import EventsReducer from "../../pages/company/events/slice.ts";
import ReferrersReducer from "../../pages/company/referrers/slice.ts";
import CompanyReducer from "../../pages/company/slice.ts";
import CollaboratorReducer from "../../pages/financialAdvisor/collaborator/slice.ts";
import FinancialAdvisorReducer from "../../pages/financialAdvisor/slice.ts";
import NoticeReducer from "../../pages/notice/slice.ts";
import OperatorReducer from "../../pages/operator/slice.ts";
import LayoutReducer from "../../ui/layout/slice.ts";
import ObjectReducer from "../object/slice.ts";

export const store = configureStore({
    reducer: {
        secured: SecuredReducer,
        financialAdvisor: FinancialAdvisorReducer,
        operator: OperatorReducer,
        company: CompanyReducer,
        collaborator: CollaboratorReducer,
        layout: LayoutReducer,
        referrers: ReferrersReducer,
        events: EventsReducer,
        auth: AuthReducer,
        object: ObjectReducer,
        document: DocumentReducer,
        notice: NoticeReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

