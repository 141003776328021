import clsx from "clsx";
import React, { ReactElement, cloneElement, useState } from "react";
import { colors } from "../../colors.ts";

interface Props {
    icon?: ReactElement | undefined
    label: string
    selectedPrimary?: boolean
    selectedSecondary?: boolean
    onClick: () => void
    type: 'primary' | 'secondary'
    disabled?: boolean
}

export function Tab(props: Props) {
    const { icon } = props;
    const { label } = props;
    const { selectedPrimary } = props;
    const { selectedSecondary } = props;
    const { onClick } = props;
    const { type } = props;
    const { disabled } = props;

    const [isHover, setIsHover] = useState(false)

    return (
        <div
            onMouseEnter={() => !disabled && setIsHover(true)}
            onMouseLeave={() => !disabled && setIsHover(false)}
            className={
                clsx("flex flex-row items-center gap-2",
                    {
                        "px-6 h-[44px] cursor-pointer hover:text-brandPrimary-500": type === 'primary',
                        "border-b-2 border-brandPrimary-500 text-label-sm text-brandPrimary-500 font-medium": selectedPrimary && type === 'primary',
                        "text-label-sm text-brandSecondary-600 font-medium": !selectedPrimary && type === 'primary',
                        "rounded-lg px-3 py-[10px] gap-2 text-label-sm font-medium cursor-pointer hover:text-brandPrimary-500": type === 'secondary',
                        "bg-brandPrimary-100 text-brandPrimary-500": selectedSecondary && type === 'secondary',
                        "text-brandSecondary-500": !selectedSecondary && type === 'secondary',
                        "cursor-not-allowed opacity-50": disabled
                    }
                )
            }
            onClick={() => !disabled && onClick()}
        >
            {icon && cloneElement(icon, { color: isHover || (selectedPrimary || selectedSecondary) ? colors.brandPrimary[500] : colors.brandSecondary[500], size: 20 })}
            {label}
        </div>
    )
}