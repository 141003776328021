import axios from "axios";
import { keycloak } from "../../lib/keycloak/index.ts";
import { FindAllNoticeResponseDTO, NoticeCreationDTO, NoticeDTO, NoticeFiltersDTO, NoticeUpsertDTO } from "./dto.ts";
import { NoticeService } from "./service.ts";

export class NoticeServiceImpl implements NoticeService {
    public findAllNotice(filters: NoticeFiltersDTO): Promise<FindAllNoticeResponseDTO> {
        let url: string = "/api/notice?" +
            "itemsPerPage=" + filters.itemsPerPage +
            "&order=" + filters.order +
            "&page=" + filters.page +
            "&sort=" + filters.sort +
            (filters.type !== '' ? ("&type=" + filters.type) : '') +
            (filters.status !== '' ? ("&status=" + filters.status) : '') +
            (filters.receiver !== '' ? ("&receiver=" + filters.receiver) : '')
        return axios({
            url: url,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createNotice(request: NoticeCreationDTO): Promise<void> {
        let url: string = "/api/notice"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findById(id: string): Promise<NoticeDTO> {
        let url: string = "/api/notice/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editNotice(id: string, request: NoticeUpsertDTO): Promise<void> {
        let url: string = "/api/notice/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteNotice(id: string): Promise<void> {
        let url: string = "/api/notice/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
