import React, { useEffect, useState } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import MultiSelectCheckbox from "../../ui/molecules/multiSelectCheckbox/index.tsx";
import { SelectComponent } from "../../ui/molecules/select/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { Switch } from "../../ui/molecules/switch/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { NoticeStatus, NoticeType } from "./dto.ts";
import { createNotice, editNotice, resetNoticeCreationRequest, setCreateNoticeStatus, setEditNoticeMessage, setEditNoticeProminent, setEditNoticeStatus, setEditNoticeSubjectLine, setEditNoticeType, setEditNoticeUserIds, setFindByIdNoticeStatus, setNoticeCreateMessage, setNoticeCreateProminent, setNoticeCreateSubjectLine, setNoticeCreateType, setNoticeCreateUserIds, setNoticeFilterReceiver, setNoticeFilterStatus, setSelectedTab } from "./slice.ts";


export function NewOrEditNotice() {
    const noticeState = useAppSelector(state => state.notice)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const referrerState = useAppSelector(state => state.referrers)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    const [draft, setDraft] = useState(false)

    let options: { name: string, code: string }[] = []

    useEffect(() => {
        dispatch(resetNoticeCreationRequest())
    }, [])

    useEffect(() => {
        if (noticeState.findByIdNoticeStatus === 'successfully' && noticeState.saveOrEditNotice === 'edit') {
            dispatch(setEditNoticeUserIds(noticeState.findByIdNoticeResponse?.userIds))
            dispatch(setEditNoticeMessage(noticeState.findByIdNoticeResponse?.message))
            dispatch(setEditNoticeSubjectLine(noticeState.findByIdNoticeResponse?.subjectLine))
            dispatch(setEditNoticeProminent(noticeState.findByIdNoticeResponse?.prominent))
            dispatch(setEditNoticeType(noticeState.findByIdNoticeResponse?.type))
            dispatch(setFindByIdNoticeStatus("idle"))
        }
    }, [noticeState.findByIdNoticeStatus, noticeState.saveOrEditNotice])

    useEffect(() => {
        if ((noticeState.createNoticeStatus === "successfully" || noticeState.editNoticeStatus === "successfully") && draft) {
            dispatch(setNoticeFilterReceiver(''))
            dispatch(setNoticeFilterStatus(NoticeStatus.DRAFT))
            dispatch(setSelectedTab(2));
        } else if ((noticeState.createNoticeStatus === "successfully" || noticeState.editNoticeStatus === "successfully") && !draft) {
            if (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('operator'))
                dispatch(setNoticeFilterReceiver('false'))
            dispatch(setNoticeFilterStatus(NoticeStatus.SENT))
            dispatch(setSelectedTab(1))
        }
    }, [noticeState.createNoticeStatus, noticeState.editNoticeStatus, draft])

    if (financialAdvisorState.findAllFinancialAdvisorStatus === "successfully" &&
        collaboratorState.findAllCollaboratorStatus === "successfully" &&
        companyState.findAllCompanyStatus === "successfully" &&
        referrerState.findAllReferrersStatus === "successfully" &&
        (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator"))
    ) {
        let financialAdvisorOptions = financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((item) => ({ name: item.commercialName, code: item.userId }))
        let collaboratorOtions = collaboratorState.findAllCollaboratorResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
        let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        options = [...financialAdvisorOptions!, ...collaboratorOtions!, ...companyOptions!, ...referrerOptions!];
    } else if (companyState.findAllCompanyStatus === "successfully" &&
        referrerState.findAllReferrersStatus === "successfully" &&
        collaboratorState.findAllCollaboratorStatus === "successfully" &&
        authState.findMeFinancialAdvisorStatus === "successfully" &&
        keycloak.hasRealmRole("financial_advisor") &&
        noticeState.saveOrEditNotice === 'edit' && !noticeState.draft
    ) {
        let financialAdvisorOptions = { name: authState.findMeFinancialAdvisorResponse?.commercialName!, code: authState.findMeFinancialAdvisorResponse?.userId! }
        let collaboratorOtions = collaboratorState.findAllCollaboratorResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
        let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        options = [...collaboratorOtions!, ...companyOptions!, ...referrerOptions!];
        options.push(financialAdvisorOptions)
    } else if (
        companyState.findAllCompanyStatus === "successfully" &&
        referrerState.findAllReferrersStatus === "successfully" &&
        (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) &&
        noticeState.saveOrEditNotice === 'save'
    ) {
        let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
        let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        options = [...companyOptions!, ...referrerOptions!];
    } else if (
        companyState.findAllCompanyStatus === "successfully" &&
        referrerState.findAllReferrersStatus === "successfully" &&
        (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) &&
        noticeState.saveOrEditNotice === 'edit' && noticeState.draft
    ) {
        let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
        let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        options = [...companyOptions!, ...referrerOptions!];
    } else if (
        companyState.findAllCompanyStatus === "successfully" &&
        referrerState.findAllReferrersStatus === "successfully" &&
        financialAdvisorState.findAllFinancialAdvisorStatus === "successfully" &&
        authState.findMeCollaboratorStatus === "successfully" &&
        keycloak.hasRealmRole("financial_advisor_collaborator") && noticeState.saveOrEditNotice === 'edit'
    ) {
        let financialAdvisorOptions = financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((item) => ({ name: item.commercialName, code: item.userId }))
        let collaboratorOptions = { name: authState.findMeCollaboratorResponse?.name! + authState.findMeCollaboratorResponse?.surname!, code: authState.findMeCollaboratorResponse?.userId! }
        let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
        let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        options = [...financialAdvisorOptions!, ...companyOptions!, ...referrerOptions!];
        options.push(collaboratorOptions)
    } else if (
        authState.findMeCompanyStatus === "successfully" &&
        referrerState.findAllReferrersStatus === "successfully" &&
        keycloak.hasRealmRole("company")
    ) {
        let companyOptions = { name: authState.findMeCompanyResponse?.companyName!, code: authState.findMeCompanyResponse?.userId! }
        let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + item.surname, code: item.userId }))
        options = [...referrerOptions!];
        options.push(companyOptions)
    } else if (authState.findMeReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company")) {
        let referrerOptions = { name: authState.findMeReferrersResponse?.name! + authState.findMeReferrersResponse?.surname!, code: authState.findMeReferrersResponse?.userId! }
        options.push(referrerOptions)
    }

    if (noticeState.findByIdNoticeStatus === "loading" ||
        noticeState.createNoticeStatus === "loading" ||
        noticeState.editNoticeStatus === "loading"
    ) {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-row justify-between items-center gap-6 w-full"}>
            <ErrorPopup
                active={noticeState.createNoticeStatus === "failed"}
                close={() => dispatch(setCreateNoticeStatus('idle'))}
                message={"Si è verificato un errore durante la creazione dell'avviso."}
            />
            <ErrorPopup
                active={noticeState.editNoticeStatus === "failed"}
                close={() => dispatch(setEditNoticeStatus('idle'))}
                message={"Si è verificato un errore durante la modifica dell'avviso."}
            />
            <div className={"flex flex-col gap-[18px] w-full"}>
                <MultiSelectCheckbox
                    onChange={(e) => {
                        if (noticeState.saveOrEditNotice === 'save')
                            dispatch(setNoticeCreateUserIds(e.value.map((item) => item.code)))
                        else
                            dispatch(setEditNoticeUserIds(e.value.map((item) => item.code)))
                    }}
                    value={noticeState.saveOrEditNotice === 'save' ? options.filter(opt => noticeState.createNotice.userIds.includes(opt.code)) : options.filter(opt => noticeState.editNoticeRequest.userIds.includes(opt.code))}
                    option={options}
                    placeholder="Destinatari"
                    disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT}
                />
                <InputComponent
                    type={"text"}
                    placeholder="Inserisci un messaggio..."
                    onChangeText={(e) => {
                        if (noticeState.saveOrEditNotice === 'save')
                            dispatch(setNoticeCreateMessage(e))
                        else
                            dispatch(setEditNoticeMessage(e))
                    }}
                    multiple
                    value={noticeState.saveOrEditNotice === 'save' ? noticeState.createNotice.message : noticeState.editNoticeRequest.message}
                    disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT}
                />
            </div>
            <div className={"flex flex-col gap-3 w-full"}>
                <InputComponent
                    type={"text"}
                    placeholder="Oggetto.."
                    value={noticeState.saveOrEditNotice === 'save' ? noticeState.createNotice.subjectLine : noticeState.editNoticeRequest.subjectLine}
                    onChangeText={(value) => {
                        if (noticeState.saveOrEditNotice === 'save')
                            dispatch(setNoticeCreateSubjectLine(value))
                        else
                            dispatch(setEditNoticeSubjectLine(value))
                    }}
                    disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT}
                />
                <SelectComponent
                    placeholder={"Tipo di avviso"}
                    options={[
                        { value: NoticeType.NEWS, label: "News", },
                        { value: NoticeType.DEADLINE, label: "Scadenze", },
                        { value: NoticeType.UPDATE, label: "Aggiornamenti", },
                        { value: NoticeType.REQUEST, label: "Richieste", },
                        { value: NoticeType.EVENT, label: "Eventi", }
                    ]}
                    value={noticeState.saveOrEditNotice === 'save' ? noticeState.createNotice.type : noticeState.editNoticeRequest.type}
                    onChange={(e) => {
                        if (noticeState.saveOrEditNotice === 'save')
                            dispatch(setNoticeCreateType(e))
                        else
                            dispatch(setEditNoticeType(e))
                    }}
                    disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT}
                />
                <Switch
                    checked={noticeState.saveOrEditNotice === 'save' ? noticeState.createNotice.prominent : noticeState.editNoticeRequest.prominent}
                    label="Segna come importante!"
                    onChange={(e) => {
                        if (noticeState.saveOrEditNotice === 'save')
                            dispatch(setNoticeCreateProminent(e))
                        else
                            dispatch(setEditNoticeProminent(e))
                    }}
                    disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT}
                />
                <div className={"flex flex-row gap-3"}>
                    <ButtonComponent
                        label={noticeState.saveOrEditNotice === 'edit' && noticeState.editNoticeRequest.status === NoticeStatus.DRAFT ? "Modifica bozza" : "Salva Bozza"}
                        size={"md"}
                        iconPosition={"off"}
                        variant={"outline"}
                        color={"blue"}
                        fullWidth
                        onClick={() => {
                            setDraft(true)
                            if (noticeState.saveOrEditNotice === 'save') {
                                dispatch(createNotice({
                                    type: noticeState.createNotice.type,
                                    status: NoticeStatus.DRAFT,
                                    userIds: noticeState.createNotice.userIds,
                                    subjectLine: noticeState.createNotice.subjectLine,
                                    message: noticeState.createNotice.message,
                                    prominent: noticeState.createNotice.prominent
                                }))
                            } else {
                                dispatch(editNotice(
                                    {
                                        id: noticeState.findByIdNoticeResponse?.id.toString() ?? '',
                                        request: {
                                            type: noticeState.editNoticeRequest.type,
                                            status: NoticeStatus.DRAFT,
                                            subjectLine: noticeState.editNoticeRequest.subjectLine,
                                            message: noticeState.editNoticeRequest.message,
                                            prominent: noticeState.editNoticeRequest.prominent,
                                            userIds: noticeState.editNoticeRequest.userIds
                                        }
                                    }
                                ))
                            }
                        }}
                        disabled={
                            noticeState.editNoticeRequest.status === NoticeStatus.SENT ||
                            (noticeState.saveOrEditNotice === 'save' &&
                                (noticeState.createNotice.userIds.length === 0 ||
                                    noticeState.createNotice.subjectLine === '' ||
                                    noticeState.createNotice.message === '' ||
                                    noticeState.createNotice.type === '')
                            ) || (noticeState.saveOrEditNotice === 'edit' &&
                                (noticeState.editNoticeRequest.userIds?.length === 0 ||
                                    noticeState.editNoticeRequest.subjectLine === '' ||
                                    noticeState.editNoticeRequest.message === '' ||
                                    noticeState.editNoticeRequest.type === '')
                            )
                        }
                    />
                    <ButtonComponent
                        label="Invia avviso"
                        size={"md"}
                        iconPosition={"off"}
                        variant={"solid"}
                        color={"blue"}
                        fullWidth
                        onClick={() => {
                            setDraft(false)
                            if (noticeState.saveOrEditNotice === 'save') {
                                dispatch(createNotice({
                                    type: noticeState.createNotice.type,
                                    status: NoticeStatus.SENT,
                                    userIds: noticeState.createNotice.userIds,
                                    subjectLine: noticeState.createNotice.subjectLine,
                                    message: noticeState.createNotice.message,
                                    prominent: noticeState.createNotice.prominent
                                }))
                            } else {
                                dispatch(editNotice(
                                    {
                                        id: noticeState.findByIdNoticeResponse?.id.toString() ?? '',
                                        request: {
                                            type: noticeState.editNoticeRequest.type,
                                            status: NoticeStatus.SENT,
                                            subjectLine: noticeState.editNoticeRequest.subjectLine,
                                            message: noticeState.editNoticeRequest.message,
                                            prominent: noticeState.editNoticeRequest.prominent,
                                            userIds: noticeState.editNoticeRequest.userIds
                                        }
                                    }
                                ))
                            }
                        }}
                        disabled={
                            noticeState.editNoticeRequest.status === NoticeStatus.SENT ||
                            (noticeState.saveOrEditNotice === 'save' &&
                                (noticeState.createNotice.userIds.length === 0 ||
                                    noticeState.createNotice.subjectLine === '' ||
                                    noticeState.createNotice.message === '' ||
                                    noticeState.createNotice.type === '')
                            ) || (noticeState.saveOrEditNotice === 'edit' &&
                                (noticeState.editNoticeRequest.userIds?.length === 0 ||
                                    noticeState.editNoticeRequest.subjectLine === '' ||
                                    noticeState.editNoticeRequest.message === '' ||
                                    noticeState.editNoticeRequest.type === '')
                            )
                        }
                    />
                </div>
            </div>
        </div>
    )
}