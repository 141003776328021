import React from "react"

interface Props {
    color?: string
    size: string
}

export function FinancialAdvisorIcon(props: Props) {
    const { color } = props
    const { size } = props

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 18.9583H6.66667C2.81667 18.9583 2.1 17.1666 1.91667 15.425L1.29167 8.74997C1.2 7.87497 1.175 6.5833 2.04167 5.61663C2.79167 4.7833 4.03333 4.3833 5.83333 4.3833H14.1667C15.975 4.3833 17.2167 4.79163 17.9583 5.61663C18.825 6.5833 18.8 7.87497 18.7083 8.7583L18.0833 15.4166C17.9 17.1666 17.1833 18.9583 13.3333 18.9583ZM5.83333 5.62497C4.425 5.62497 3.45833 5.89997 2.96667 6.44997C2.55833 6.89997 2.425 7.59163 2.53333 8.62497L3.15833 15.3C3.3 16.6166 3.65833 17.7083 6.66667 17.7083H13.3333C16.3333 17.7083 16.7 16.6166 16.8417 15.2916L17.4667 8.6333C17.575 7.59163 17.4417 6.89997 17.0333 6.44997C16.5417 5.89997 15.575 5.62497 14.1667 5.62497H5.83333Z" fill={color} />
            <path d="M13.3333 5.62496C12.9917 5.62496 12.7083 5.34163 12.7083 4.99996V4.33329C12.7083 2.84996 12.7083 2.29163 10.6667 2.29163H9.33333C7.29166 2.29163 7.29166 2.84996 7.29166 4.33329V4.99996C7.29166 5.34163 7.00833 5.62496 6.66666 5.62496C6.325 5.62496 6.04166 5.34163 6.04166 4.99996V4.33329C6.04166 2.86663 6.04166 1.04163 9.33333 1.04163H10.6667C13.9583 1.04163 13.9583 2.86663 13.9583 4.33329V4.99996C13.9583 5.34163 13.675 5.62496 13.3333 5.62496Z" fill={color} />
            <path d="M10 13.9583C7.70834 13.9583 7.70834 12.5417 7.70834 11.6917V10.8333C7.70834 9.65833 7.99167 9.375 9.16667 9.375H10.8333C12.0083 9.375 12.2917 9.65833 12.2917 10.8333V11.6667C12.2917 12.5333 12.2917 13.9583 10 13.9583ZM8.95834 10.625C8.95834 10.6917 8.95834 10.7667 8.95834 10.8333V11.6917C8.95834 12.55 8.95834 12.7083 10 12.7083C11.0417 12.7083 11.0417 12.575 11.0417 11.6833V10.8333C11.0417 10.7667 11.0417 10.6917 11.0417 10.625C10.975 10.625 10.9 10.625 10.8333 10.625H9.16667C9.1 10.625 9.025 10.625 8.95834 10.625Z" fill={color} />
            <path d="M11.6667 12.3084C11.3583 12.3084 11.0833 12.075 11.05 11.7584C11.0083 11.4167 11.25 11.1 11.5917 11.0584C13.7917 10.7834 15.9 9.95004 17.675 8.65837C17.95 8.45004 18.3417 8.5167 18.55 8.80004C18.75 9.07504 18.6917 9.4667 18.4083 9.67504C16.4583 11.0917 14.1583 12 11.7417 12.3084C11.7167 12.3084 11.6917 12.3084 11.6667 12.3084Z" fill={color} />
            <path d="M8.33333 12.3167C8.30833 12.3167 8.28333 12.3167 8.25833 12.3167C5.975 12.0583 3.75 11.225 1.825 9.90833C1.54167 9.71667 1.46667 9.325 1.65833 9.04167C1.85 8.75833 2.24167 8.68333 2.525 8.875C4.28333 10.075 6.30833 10.8333 8.39167 11.075C8.73333 11.1167 8.98333 11.425 8.94167 11.7667C8.91667 12.0833 8.65 12.3167 8.33333 12.3167Z" fill={color} />
        </svg>
    )
}