import { CompanyDTO } from "../company/dto.ts";
import { ReferrersDTO } from "../company/referrers/dto.ts";
import { CollaboratorDTO } from "../financialAdvisor/collaborator/dto.ts";
import { FinancialAdvisorDTO } from "../financialAdvisor/dto.ts";
import { OperatorDTO } from "../operator/dto.ts";
import { AuthServiceImpl } from "./serviceImpl.ts";

export interface AuthService {
    findMeFinancialAdvisor(): Promise<FinancialAdvisorDTO>
    findMeCollaborator(): Promise<CollaboratorDTO>
    findMeCompany(): Promise<CompanyDTO>
    findMeOperator(): Promise<OperatorDTO>
    findMeReferrer(): Promise<ReferrersDTO>
}

export function NewAuthService(): AuthService {
    return new AuthServiceImpl();
}
