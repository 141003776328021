export const colors = {
    brandPrimary: {
        900: '#1C1E4F',
        850: '#1E226B',
        800: '#1E248A',
        700: '#161FCA',
        600: '#1A25FF',
        500: '#3F48F3',
        400: '#8A8EE5',
        300: '#BCBFF0',
        200: '#E6E7F9',
        100: '#F1F2FD',
        50: '#FAFAFF',
    },
    brandSecondary: {
        900: '#131345',
        850: '#1F1F5C',
        800: '#2E2E75',
        700: '#42428F',
        600: '#57579F',
        500: '#7171B3',
        400: '#A2A2DB',
        300: '#C3C3EB',
        200: '#DEDEF7',
        100: '#EDEDFC',
        50: '#F4F4FC',
    },
    neutral: {
        900: '#1A1A38',
        850: '#262645',
        800: '#323252',
        700: '#434366',
        600: '#62628A',
        500: '#8383AD',
        400: '#ADADD6',
        300: '#CBCBEB',
        200: '#E4E4F7',
        100: '#F2F2FA',
        50: '#F7F7FC',
    },
    green: {
        800: '#275252',
        700: '#2A7B5C',
        600: '#30A375',
        500: '#41CC94',
        400: '#8AE5C1',
        300: '#B1EED5',
        200: '#D2F5E7',
        100: '#E6FAF2',
    },
    red: {
        800: '#5C3747',
        700: '#994742',
        600: '#CC524A',
        500: '#FD665B',
        400: '#FFA49E',
        300: '#FFC6C2',
        200: '#FFDEDB',
        100: '#FFECEB',
    },
    warning: {
        900: '#7A2E0E',
        800: '#93370D',
        700: '#B54708',
        600: '#DC6803',
        500: '#F79009',
        400: '#FDB022',
        300: '#FEC84B',
        200: '#FEDF89',
        100: '#FEF0C7',
        50: '#FFFAEB'
    },
    white: '#FFFFFF'
}