import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { format, lastDayOfMonth } from "date-fns"
import { PromiseStatuses } from "../../../lib/utilis/index.tsx"
import { EventCreationDTO, EventDTO, EventStatus, EventType, EventUpdateDTO, EventsFilters, FindAllEventsDTO, UpdateEventStatusRequest } from "./dto.ts"
import { NewEventsService } from "./service.ts"

interface EventsState {
    findAllEventsStatus: PromiseStatuses
    findAllEventsResponse?: FindAllEventsDTO
    eventsFilters: EventsFilters
    eventCreationStatus: PromiseStatuses
    eventCreationRequest: EventCreationDTO
    findByIdEventResponse?: EventDTO
    findByIdEventStatus: PromiseStatuses
    editEventRequest: EventUpdateDTO
    editEventStatus: PromiseStatuses
    deleteEventStatus: PromiseStatuses
    openNewEventsModal: boolean
    modalEventType: 'save' | 'edit'
    openConfirmOrDenyModal: boolean
    editEventId: number
    updateEventStatusStatus: PromiseStatuses
    updateEventStatusRequest: UpdateEventStatusRequest
    currentMonth: Date
}

const initialState: EventsState = {
    findAllEventsStatus: 'idle',
    eventsFilters: {
        fromDate: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'),
        toDate: format(lastDayOfMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), 'yyyy-MM-dd'),
        itemsPerPage: 0,
        page: 0,
        companyId: 0,
        order: false,
        sort: ""
    },
    eventCreationStatus: 'idle',
    eventCreationRequest: {
        type: EventType.Other,
        status: EventStatus.NotResolved,
    },
    findByIdEventStatus: 'idle',
    editEventStatus: 'idle',
    editEventRequest: {},
    deleteEventStatus: 'idle',
    openNewEventsModal: false,
    modalEventType: 'save',
    openConfirmOrDenyModal: false,
    editEventId: 0,
    updateEventStatusStatus: 'idle',
    updateEventStatusRequest: { status: EventStatus.NotResolved },
    currentMonth: new Date()
}

export const findAllEvents = createAsyncThunk(
    'events/findAllEvents',
    async (filters: EventsFilters): Promise<FindAllEventsDTO> => {
        const eventsService = NewEventsService()

        return eventsService.findAllEvents(filters)
    },
)

export const createEvent = createAsyncThunk(
    'events/createEvent',
    async (request: EventCreationDTO): Promise<void> => {
        const eventsService = NewEventsService()

        return eventsService.createEvent(request)
    },
)

export const findByIdEvent = createAsyncThunk(
    'events/findByIdEvent',
    async (id: string): Promise<EventDTO> => {
        const eventsService = NewEventsService()

        return eventsService.findByIdEvent(id)
    },
)

export const editEvent = createAsyncThunk(
    'events/editEvent',
    async (request: { id: string, request: EventUpdateDTO }): Promise<void> => {
        const eventsService = NewEventsService()

        return eventsService.editEvent(request.id, request.request)
    },
)

export const deleteEvent = createAsyncThunk(
    'events/deleteEvent',
    async (id: string): Promise<void> => {
        const eventsService = NewEventsService()

        return eventsService.deleteEvent(id)
    },
)

export const updateEventStatus = createAsyncThunk(
    'events/updateEventStatus',
    async (request: { id: string, data: UpdateEventStatusRequest }): Promise<void> => {
        const eventsService = NewEventsService()
        return eventsService.updateStatus(request.id, request.data)
    },
)

const eventsSlice = createSlice({
    name: 'events/slice',
    initialState,
    reducers: {
        setOpenNewEventsModal: (state, action) => {
            state.openNewEventsModal = action.payload
        },
        resetNewEventRequest: (state) => {
            state.eventCreationRequest.name = undefined
            state.eventCreationRequest.date = undefined
            state.eventCreationRequest.note = undefined
        },
        resetEditEventRequest: (state) => {
            state.editEventRequest.name = undefined
            state.editEventRequest.date = undefined
            state.editEventRequest.note = undefined
        },
        setNewEventName: (state, action) => {
            state.eventCreationRequest.name = action.payload
        },
        setNewEventNote: (state, action) => {
            state.eventCreationRequest.note = action.payload
        },
        setNewEventDate: (state, action) => {
            state.eventCreationRequest.date = action.payload
        },
        setNewEventType: (state, action) => {
            state.eventCreationRequest.type = action.payload
        },
        setNewEventCompanyId: (state, action) => {
            state.eventCreationRequest.companyId = action.payload
        },
        setEditEventName: (state, action) => {
            state.editEventRequest.name = action.payload
        },
        setEditEventNote: (state, action) => {
            state.editEventRequest.note = action.payload
        },
        setEditEventDate: (state, action) => {
            state.editEventRequest.date = action.payload
        },
        setEditEventId: (state, action) => {
            state.editEventId = action.payload
        },
        setModalEventType: (state, action) => {
            state.modalEventType = action.payload
        },
        setOpenConfirmOrDenyModal: (state, action) => {
            state.openConfirmOrDenyModal = action.payload
        },
        setCreateEventStatus: (state, action) => {
            state.eventCreationStatus = action.payload
        },
        setEditEventStatus: (state, action) => {
            state.editEventStatus = action.payload
        },
        setDeleteEventStatus: (state, action) => {
            state.deleteEventStatus = action.payload
        },
        setEventFilterCompanyId: (state, action) => {
            state.eventsFilters.companyId = action.payload
        },
        setFromDateFilter: (state, action) => {
            state.eventsFilters.fromDate = action.payload
        },
        setToDateFilter: (state, action) => {
            state.eventsFilters.toDate = action.payload
        },
        setCurrentMonth: (state, action) => {
            state.currentMonth = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllEvents.pending, (state) => {
                state.findAllEventsStatus = 'loading'
            })
            .addCase(findAllEvents.fulfilled, (state, action) => {
                state.findAllEventsStatus = 'successfully'
                state.findAllEventsResponse = action.payload
            })
            .addCase(findAllEvents.rejected, (state) => {
                state.findAllEventsStatus = 'failed'
            })
            .addCase(createEvent.pending, (state) => {
                state.eventCreationStatus = 'loading'
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.eventCreationStatus = 'successfully'
            })
            .addCase(createEvent.rejected, (state) => {
                state.eventCreationStatus = 'failed'
            })
            .addCase(findByIdEvent.pending, (state) => {
                state.findByIdEventStatus = 'loading'
            })
            .addCase(findByIdEvent.fulfilled, (state, action) => {
                state.findByIdEventStatus = 'successfully'
                state.findByIdEventResponse = action.payload
            })
            .addCase(findByIdEvent.rejected, (state) => {
                state.findByIdEventStatus = 'failed'
            })
            .addCase(editEvent.pending, (state) => {
                state.editEventStatus = 'loading'
            })
            .addCase(editEvent.fulfilled, (state, action) => {
                state.editEventStatus = 'successfully'
            })
            .addCase(editEvent.rejected, (state) => {
                state.editEventStatus = 'failed'
            })
            .addCase(deleteEvent.pending, (state) => {
                state.deleteEventStatus = 'loading'
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.deleteEventStatus = 'successfully'
            })
            .addCase(deleteEvent.rejected, (state) => {
                state.deleteEventStatus = 'failed'
            })
            .addCase(updateEventStatus.pending, (state) => {
                state.updateEventStatusStatus = 'loading'
            })
            .addCase(updateEventStatus.fulfilled, (state, action) => {
                state.updateEventStatusStatus = 'successfully'
            })
            .addCase(updateEventStatus.rejected, (state) => {
                state.updateEventStatusStatus = 'failed'
            })
    },
})

export const {
    setEditEventDate,
    setEditEventName,
    setEditEventNote,
    setNewEventDate,
    setNewEventName,
    setNewEventNote,
    setNewEventType,
    setOpenNewEventsModal,
    resetEditEventRequest,
    resetNewEventRequest,
    setModalEventType,
    setNewEventCompanyId,
    setOpenConfirmOrDenyModal,
    setCreateEventStatus,
    setDeleteEventStatus,
    setEditEventStatus,
    setEditEventId,
    setEventFilterCompanyId,
    setFromDateFilter,
    setToDateFilter,
    setCurrentMonth
} = eventsSlice.actions

export default eventsSlice.reducer