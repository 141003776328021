export interface EventCreationDTO {
    name?: string
    note?: string
    date?: string
    type: EventType
    companyId?: string
    status: EventStatus
    userId?: string
    authenticatedUserRoles?: string[]
}

export interface FindAllEventsDTO {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: EventDTO[]
}

export interface EventsFilters {
    fromDate: string
    toDate: string
    itemsPerPage: number
    page: number
    companyId: number
    order: boolean
    sort: string
}

export interface EventDTO {
    id: string
    name: string
    note: string
    userId: string
    date: Date
    type: EventType
    companyId: string
    status: EventStatus
    documentId: string
}

export interface EventStatusUpdateDTO {
    userId: string
    authenticatedUserRoles: string
    status: EventStatus
}

export interface EventUpdateDTO {
    name?: string
    note?: string
    date?: string
    userId?: string
    authenticatedUserRoles?: string[]
}

export interface UpdateEventStatusRequest {
    status: EventStatus
}

export enum EventType {
    Other = 'Other',
    F24 = 'F24'
}

export enum EventStatus {
    NotResolved = 'NotResolved',
    Resolved = 'Resolved'
}