import React, { useEffect } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { CompanyIcon } from "../../ui/icons/companyIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { Pagination } from "../../ui/organisms/pagination/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { CompanyFilters } from "./filters.tsx";
import { CompanyList } from "./list.tsx";
import { NewCompanyModal } from "./newCompanyModal.tsx";
import { findAllCompany, setCompanyFiltersPage, setCreateCompanyCompanyName, setCreateCompanyEmail, setCreateCompanyName, setCreateCompanyStatus, setCreateCompanySurname, setDeleteCompanyStatus, setEditCompanyStatus, setOpenNewCompanyModal } from "./slice.ts";

export function Company() {
    const dispatch = useAppDispatch()
    const companyState = useAppSelector(state => state.company)

    useEffect(() => {
        dispatch(findAllCompany(companyState.companyFilters))
    }, [companyState.companyFilters.page])

    return (
        <LayoutComponent
            menuItem={MenuItems.COMPANY}
            breadcrumbItems={['Aziende']}
            headingLabel={"Aziende"}
            headingButtons={[
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: 16 }}>
                    {
                        !keycloak.hasRealmRole("financial_advisor_collaborator") &&
                        <ButtonComponent
                            label="Nuova azienda"
                            size={"md"}
                            iconPosition={"off"}
                            variant={"solid"}
                            color={"blue"}
                            onClick={() => {
                                dispatch(setCreateCompanyCompanyName(""))
                                dispatch(setCreateCompanyName(""))
                                dispatch(setCreateCompanySurname(""))
                                dispatch(setCreateCompanyEmail(""))
                                dispatch(setOpenNewCompanyModal(true))
                            }}
                        />
                    }
                </div>
            ]}
            breadcrumbIcon={<CompanyIcon color={colors.brandPrimary[500]} />}
        >
            <ErrorPopup
                active={companyState.createCompanyStatus === 'failed'}
                close={() => dispatch(setCreateCompanyStatus('idle'))}
                message="Si è verificato un errore durante la creazione dell'azienda"
            />
            <SuccessPopup
                active={companyState.createCompanyStatus === 'successfully'}
                close={() => dispatch(setCreateCompanyStatus('idle'))}
                message="Azienda creata"
            />
            <ErrorPopup
                active={companyState.deleteCompanyStatus === 'failed'}
                close={() => dispatch(setDeleteCompanyStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione dell'azienda"
            />
            <SuccessPopup
                active={companyState.deleteCompanyStatus === 'successfully'}
                close={() => dispatch(setDeleteCompanyStatus('idle'))}
                message="Azienda eliminata"
            />
            <ErrorPopup
                active={companyState.editCompanyStatus === 'failed'}
                close={() => dispatch(setEditCompanyStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'azienda"
            />
            <SuccessPopup
                active={companyState.editCompanyStatus === 'successfully'}
                close={() => dispatch(setEditCompanyStatus('idle'))}
                message="Azienda modificata"
            />
            <CompanyFilters />
            <CompanyList />
            {
                companyState.findAllCompanyResponse !== undefined &&
                companyState.findAllCompanyResponse.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={companyState.findAllCompanyResponse?.totalPage || 1}
                        currentPage={companyState.companyFilters.page}
                        setPage={(page) => dispatch(setCompanyFiltersPage(page))}
                    />
                </div>
            }
            <NewCompanyModal />
        </LayoutComponent>
    )
}