import React from "react";
import { DropdownContainerProps } from "./dto.ts";
import { DropdownItem } from "./dropDownItem.tsx";
import './dropDown.css'

export function DropDownContainer(props: DropdownContainerProps) {
    return (
        <div
            className={"absolute right-[40px] top-[15px] z-50"}>
            <div className="dropdown-container">
                {props.items.map((item, index) =>
                    <DropdownItem
                        key={'menu-item-' + index} {...item}
                        onClick={() => { item.onClick && item.onClick(); props.onClose() }}
                    />
                )}
            </div>
        </div>
    )
}