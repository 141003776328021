import React from "react";

interface Props {
    color?: string
    size: string
}

export function ArrowLeftIcon(props: Props) {
    const { color } = props
    const { size } = props

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}