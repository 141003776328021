import React from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../../ui/colors.ts";
import { SettingIcon } from "../../../ui/icons/settingIcon.tsx";
import { LayoutComponent } from "../../../ui/layout/index.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx";
import { HorizontalTab } from "../../../ui/organisms/horizontalTab/index.tsx";
import { MenuItems } from "../../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../../ui/organisms/successPopup/index.tsx";
import { Tab } from "../../../ui/organisms/tab/index.tsx";
import { RegistryCollaborator } from "./registry.tsx";
import { editCollaborator, setEditCollaboratorStatus, setSelectedTab } from "./slice.ts";

export function OperationCollaborator() {
    const dispatch = useAppDispatch()

    const authState = useAppSelector(state => state.auth)
    const collaboratorState = useAppSelector(state => state.collaborator)

    let formData = new FormData();

    return (
        <LayoutComponent
            menuItem={MenuItems.SETTING}
            headingLabel={authState.findMeCollaboratorResponse?.name + ' ' + authState.findMeCollaboratorResponse?.surname}
            headingButtons={[
                <ButtonComponent
                    label={"Aggiorna"}
                    onClick={() => {
                        if (collaboratorState.editCollaboratorRequest.avatar !== null) {
                            formData.append('avatar', collaboratorState.editCollaboratorRequest.avatar)
                        }
                        formData.append('userDto', JSON.stringify(collaboratorState.editCollaboratorRequest))
                        dispatch(editCollaborator({ id: authState.findMeCollaboratorResponse?.id !== undefined ? authState.findMeCollaboratorResponse?.id : "", request: formData }))
                    }}
                    color={"blue"}
                    variant={"solid"}
                    size={"md"}
                    iconPosition="off"
                />
            ]}
            breadcrumbItems={["Impostazioni", "Anagrafica"]}
            breadcrumbIcon={<SettingIcon color={colors.brandSecondary[500]} size={"20"} />}
        >
            <SuccessPopup
                active={collaboratorState.editCollaboratorStatus === "successfully"}
                close={() => dispatch(setEditCollaboratorStatus("idle"))}
                message="Profilo modificato"
            />
            <ErrorPopup
                active={collaboratorState.editCollaboratorStatus === "failed"}
                close={() => dispatch(setEditCollaboratorStatus("idle"))}
                message="Errore durante la modifica del profilo."
            />
            <HorizontalTab>
                <Tab
                    label={"Anagrafica"}
                    selectedPrimary={collaboratorState.selectedTab === 0}
                    onClick={() => dispatch(setSelectedTab(0))}
                    type="primary"
                />
            </HorizontalTab>
            <RegistryCollaborator />
        </LayoutComponent >
    )
}