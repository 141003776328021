import React, { useEffect } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx";
import { HeadingSize } from "../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../ui/organisms/heading/index.tsx";
import { Pagination } from "../../../ui/organisms/pagination/index.tsx";
import { SuccessPopup } from "../../../ui/organisms/successPopup/index.tsx";
import { ReferrersFilters } from "./filters.tsx";
import { ReferrersList } from "./list.tsx";
import { NewOrEditReferrersModal } from "./newOrEditReferrersModal.tsx";
import { findAllReferrers, setCreateReferrersStatus, setDeleteReferrersStatus, setEditReferrersStatus, setNewOrEditReferrersModal, setReferrersFilterPage, setSaveOrEditModal } from "./slice.ts";

export function Referrers() {
    const dispatch = useAppDispatch()
    const referrersState = useAppSelector(state => state.referrers)

    useEffect(() => {
        dispatch(findAllReferrers(referrersState.referrersFilter))
    }, [referrersState.referrersFilter.page])

    return (
        <div className={"flex flex-col gap-3 flex-grow"}>
            <HeadingComponent
                label={"Referenti"}
                buttons={[
                    <>
                        {
                            !keycloak.hasRealmRole("financial_advisor") && !keycloak.hasRealmRole("financial_advisor_collaborator") && !keycloak.hasRealmRole("administrative_company") &&
                            <ButtonComponent
                                label={"Aggiungi referente"}
                                onClick={() => {
                                    dispatch(setSaveOrEditModal('save'))
                                    dispatch(setNewOrEditReferrersModal(true))
                                }}
                                color={"blue"}
                                variant={"outline"}
                                size={"md"}
                                iconPosition="off"
                            />
                        }
                    </>
                ]}
                size={HeadingSize.LG}
            />
            <SuccessPopup
                active={referrersState.createReferrersStatus === "successfully"}
                close={() => dispatch(setCreateReferrersStatus("idle"))}
                message="Referente aggiunto"
            />
            <ErrorPopup
                active={referrersState.createReferrersStatus === "failed"}
                close={() => dispatch(setCreateReferrersStatus("idle"))}
                message="Errore durante la creazione del referente."
            />
            <SuccessPopup
                active={referrersState.editReferrersStatus === "successfully"}
                close={() => dispatch(setEditReferrersStatus("idle"))}
                message="Referente modificato"
            />
            <ErrorPopup
                active={referrersState.editReferrersStatus === "failed"}
                close={() => dispatch(setEditReferrersStatus("idle"))}
                message="Errore durante la modifica del referente."
            />
            <SuccessPopup
                active={referrersState.deleteReferrerStatus === "successfully"}
                close={() => dispatch(setDeleteReferrersStatus("idle"))}
                message="Referente eliminato"
            />
            <ErrorPopup
                active={referrersState.deleteReferrerStatus === "failed"}
                close={() => dispatch(setDeleteReferrersStatus("idle"))}
                message="Errore durante l'eliminazione del referente."
            />
            <ReferrersFilters />
            <ReferrersList />
            {
                referrersState.findAllReferrersResponse !== undefined &&
                referrersState.findAllReferrersResponse?.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={referrersState.findAllReferrersResponse?.totalPage || 1}
                        currentPage={referrersState.referrersFilter.page}
                        setPage={(page) => dispatch(setReferrersFilterPage(page))}
                    />
                </div>
            }
            <NewOrEditReferrersModal />
        </div>
    )
}