import React, { CSSProperties, ReactElement, useState } from "react";

interface Props {
    label: string,
    children: ReactElement
    position: 'bottom' | 'left' | 'bottom-left'
}

export function InfoTextPopup(props: Props) {
    const { label } = props
    const { children } = props
    const { position } = props

    const [hover, setHover] = useState<boolean>(false)

    const positionMap = new Map<'bottom' | 'left' | 'bottom-left', CSSProperties>([
        ['bottom', {
            position: "absolute",
            top: "115%",
            width: "auto",
            right: '0px',
            left: '0%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }],
        ['left', {
            position: "absolute",
            top: '50%',
            bottom: '50%',
            left: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto'
        }],
        ['bottom-left', {
            position: "absolute",
            top: "115%",
            width: "auto",
            right: '0%',
            left: '0%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        }]
    ])

    return (
        <div className={"relative w-full"}>
            {
                hover &&
                <div
                    style={positionMap.get(position)}
                >
                    <div
                        className="text-text-xs text-neutral-500 font-bold text-center bg-neutral-50 rounded-lg z-[5]"
                        style={{
                            padding: '4px 6px',
                            boxShadow: "0px 1px 2px rgba(34, 36, 47, 0.16)"
                        }}>
                        {label}
                    </div>
                </div>
            }
            <div
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                className={"flex items-center"}
            >
                {children}
            </div>
        </div >
    )
}