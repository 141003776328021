import clsx from "clsx";
import React from "react";

interface Props {
    size?: number
    color?: 'neutral' | 'primary' | 'secondary' | 'orange' | 'red' | 'green' | 'info'
}

export function SpinnerComponent(props: Props) {
    const { size } = props
    const { color } = props

    return (
        <div
            style={{ width: size ?? 40 + 'px', height: size ?? 40 + 'px', borderWidth: '2px' }}
            className={clsx(`rounded-full animate-spin`, {
                "border-t-brandPrimary-500 border-brandPrimary-200": color === 'primary' || color === undefined,
                "border-t-brandSecondary-500 border-brandSecondary-200": color === 'secondary',
                "border-t-info-500 border-info-200": color === 'info',
                "border-t-neutral-500 border-neutral-200": color === 'neutral',
                "border-t-red-500 border-red-200": color === 'red',
                "border-t-orange-500 border-orange-200": color === 'orange',
                "border-t-green-500 border-green-200": color === 'green',
            })}
        />

    )
}