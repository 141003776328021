import { DocumentDTO, DocumentFilters, FindAllDocumentsResponseDTO } from "./dto.ts";
import { DocumentServiceImpl } from "./serviceImpl.ts";

export interface DocumentService {
    findAllDocuments(filters: DocumentFilters): Promise<FindAllDocumentsResponseDTO>
    findByIdDocument(id: string): Promise<DocumentDTO>
    saveDocument(form: FormData): Promise<void>
    deleteDocument(id: string): Promise<void>
}

export function NewDocumentService(): DocumentService {
    return new DocumentServiceImpl();
}