import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { AddDocumentsIcon } from "../../ui/icons/addDocumentsIcon.tsx";
import { ChatIcon } from "../../ui/icons/chatIcon.tsx";
import { EditIcon } from "../../ui/icons/editIcon.tsx";
import { TrashIcon } from "../../ui/icons/trashIcon.tsx";
import { Pills } from "../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../ui/organisms/dropDown/index.tsx";
import { InfoTextPopup } from "../../ui/organisms/infoTextPopup/index.tsx";
import { FinancialAdvisorDTO } from "../financialAdvisor/dto.ts";
import { findAllFinancialAdvisor } from "../financialAdvisor/slice.ts";
import { findAllReferrers } from "./referrers/slice.ts";
import { deleteCompany, findAllCompany, resetCreateCompanyRequest, setCompanyId, setOpenConfirmModal, setSelectedOperationTab, setSelectedSettingTab } from "./slice.ts";

export function CompanyList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const companyState = useAppSelector(state => state.company)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const referrersState = useAppSelector(state => state.referrers)
    const authState = useAppSelector(state => state.auth)

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllCompany(companyState.companyFilters))
        if (!keycloak.hasRealmRole("financial_advisor"))
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        dispatch(findAllReferrers(referrersState.referrersFilter))
    }, [])

    useEffect(() => {
        if (companyState.createCompanyStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
            dispatch(resetCreateCompanyRequest())
        }
    }, [companyState.createCompanyStatus])

    useEffect(() => {
        if (companyState.createCompanyStatus === "failed") {
            dispatch(resetCreateCompanyRequest())
        }
    }, [companyState.createCompanyStatus])

    useEffect(() => {
        if (companyState.editCompanyStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
        }
    }, [companyState.editCompanyStatus])

    useEffect(() => {
        if (companyState.deleteCompanyStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
        }
    }, [companyState.deleteCompanyStatus])

    if (companyState.findAllCompanyStatus === "loading" || companyState.createCompanyStatus === "loading" || companyState.editCompanyStatus === "loading" || companyState.deleteCompanyStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    let financialAdvisor: FinancialAdvisorDTO | undefined
    let companyRows: ReactNode[] = []

    if (companyState.findAllCompanyStatus === "successfully" ||
        (!keycloak.hasRealmRole("financial_advisor") && financialAdvisorState.findAllFinancialAdvisorStatus === "successfully")
    ) {
        companyState.findAllCompanyResponse?.data.forEach((item) => {
            if (!keycloak.hasRealmRole("financial_advisor")) {
                financialAdvisor = financialAdvisorState.findAllFinancialAdvisorResponse?.data.find(financialAdvisor => item.financialAdvisor === financialAdvisor.id)
            } else {
                financialAdvisor = authState.findMeFinancialAdvisorResponse
            }

            let referrers: string[] = []
            item.administrativeCompanies.forEach(referrer => {
                const selectedReferrer = referrersState.findAllReferrersResponse?.data.find(ref => ref.id === referrer)
                if (selectedReferrer !== undefined) {
                    referrers.push(selectedReferrer.name + ' ' + selectedReferrer.surname)
                }
            })

            let referrerLabel: string = '-'
            if (referrers.length === 1) {
                referrerLabel = referrers[0]
            } else if (referrers.length > 1) {
                referrerLabel = referrers[0] + ', +' + (referrers.length - 1)
            }

            companyRows.push(
                <tr
                    className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                    key={item.id}
                >
                    <td
                        className="py-4 px-2 text-text-md font-semibold text-neutral-600 hover:text-neutral-900 hover:font-bold"
                        onClick={() => {
                            dispatch(setCompanyId(item.id))
                            dispatch(setSelectedOperationTab(0))
                            navigate('/operation-company/' + item.id)
                        }}
                    >
                        {item.companyName}
                    </td>
                    <td className="py-4 px-2 text-text-md font-semibold text-neutral-600">{referrerLabel}</td>
                    <td className="py-4 px-2 text-text-md font-semibold text-neutral-600">{financialAdvisor?.commercialName}</td>
                    <td className="py-4 px-2 text-text-md font-semibold text-neutral-600"><Pills size={"xs"} label={"Attiva"} outline={false} emphasis={false} color={"green"} /></td>
                    <td className="w-[40px]">
                        <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                            <InfoTextPopup
                                label={"Documenti"}
                                children={
                                    <AddDocumentsIcon size={"24"} color={colors.brandSecondary[500]} onClick={() => {
                                        dispatch(setCompanyId(item.id))
                                        dispatch(setSelectedOperationTab(1))
                                        navigate('/operation-company/' + item.id)
                                    }} />
                                }
                                position={"bottom"}
                            />
                        </div>
                    </td>
                    <td className="w-[40px]">
                        <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                            <InfoTextPopup
                                label={"Messaggi"}
                                children={
                                    <ChatIcon size={"24"} color={colors.brandSecondary[500]} />
                                }
                                position={"bottom"}
                            />
                        </div>
                    </td>
                    <td className="w-[40px] cursor-pointer">
                        <Dropdown items={[
                            {
                                label: 'Visualizza',
                                icon: <EditIcon color="" size={""} />,
                                onClick: () => {
                                    dispatch(setCompanyId(item.id))
                                    dispatch(setSelectedSettingTab(0))
                                    navigate('/settings-company/' + item.id)
                                }
                            },
                            {
                                label: 'Cancella',
                                icon: <TrashIcon color="" size={""} />,
                                onClick: () => {
                                    setIsDelete(item.id)
                                    dispatch(setOpenConfirmModal(true))
                                }
                            }
                        ]} />
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
            <table className="w-full mb-3">
                <thead className="h-[56px] bg-neutral-100 font-semibold text-neutral-500 text-text-sm">
                    <th className="text-left">
                        <div className="flex py-4 px-2 w-[488px] gap-1">
                            RAGIONE SOCIALE
                        </div>
                    </th>
                    {
                        companyState.findAllCompanyResponse?.total !== 0 &&
                        <>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[280px] gap-1">
                                    REFERENTE
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[280px] gap-1">
                                    COMMERCIALISTA
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[80px] gap-1">
                                    STATO
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[40px] gap-1" />
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[40px] gap-1" />
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[40px] gap-1" />
                            </th>
                        </>
                    }
                </thead>
                <tbody>
                    {
                        companyState.findAllCompanyResponse?.total === 0 ?
                            <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                <td className="py-4 px-2 text-text-sm font-semibold text-neutral-800">
                                    Nessun risultato trovato!
                                </td>
                            </tr>
                            :
                            companyRows
                    }
                </tbody>
            </table>
            <ConfirmModal
                open={companyState.openConfirmModal}
                handleClose={() => dispatch(setOpenConfirmModal(false))}
                title={"Elimina azienda"}
                description={"Sei sicuro di voler eliminare questa azienda?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteCompany(isDelete))
                    dispatch(setOpenConfirmModal(false))
                }}
            />
        </div>
    )
}