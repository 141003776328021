import { FindAllNoticeResponseDTO, NoticeCreationDTO, NoticeDTO, NoticeFiltersDTO, NoticeUpsertDTO } from "./dto.ts";
import { NoticeServiceImpl } from "./serviceImpl.ts";

export interface NoticeService {
    findAllNotice(filters: NoticeFiltersDTO): Promise<FindAllNoticeResponseDTO>
    createNotice(request: NoticeCreationDTO): Promise<void>
    findById(id: string): Promise<NoticeDTO>
    editNotice(id: string, request: NoticeUpsertDTO): Promise<void>
    deleteNotice(id: string): Promise<void>
}

export function NewNoticeService(): NoticeService {
    return new NoticeServiceImpl();
}