import { EventCreationDTO, EventDTO, EventUpdateDTO, EventsFilters, FindAllEventsDTO, UpdateEventStatusRequest } from "./dto.ts";
import { EventsServiceImpl } from "./serviceImpl.ts";

export interface EventsService {
    findAllEvents(filters: EventsFilters): Promise<FindAllEventsDTO>
    createEvent(request: EventCreationDTO): Promise<void>
    findByIdEvent(id: string): Promise<EventDTO>
    editEvent(id: string, request: EventUpdateDTO): Promise<void>
    deleteEvent(id: string): Promise<void>
    updateStatus(id: string, data: UpdateEventStatusRequest): Promise<void>
}

export function NewEventsService(): EventsService {
    return new EventsServiceImpl();
}