import React from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { OperatorIcon } from "../../ui/icons/operatorIcon.tsx";
import { SettingIcon } from "../../ui/icons/settingIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { HorizontalTab } from "../../ui/organisms/horizontalTab/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { Tab } from "../../ui/organisms/tab/index.tsx";
import { EditOperator } from "./editOperator.tsx";
import { editOperator, setEditOperatorStatus, setFindByIdOperatorStatus, setSelectedTab } from "./slice.ts";

export function OperationOperatorView() {
    const navigation = useNavigate()
    const dispatch = useAppDispatch()

    const operatorState = useAppSelector(state => state.operator)
    const authState = useAppSelector(state => state.auth)

    let formData = new FormData();
    let id = keycloak.hasRealmRole("operator") ? authState.findMeOperatorResponse?.id : operatorState.findByIdOperatorResponse?.id

    return (
        <LayoutComponent
            menuItem={!keycloak.hasRealmRole("operator") ? MenuItems.OPERATOR : MenuItems.SETTING}
            headingLabel={!keycloak.hasRealmRole("operator") ? operatorState.findByIdOperatorResponse?.name + ' ' + operatorState.findByIdOperatorResponse?.surname : authState.findMeOperatorResponse?.name + ' ' + authState.findMeOperatorResponse?.surname}
            headingButtons={[
                <div className={"flex gap-2"}>
                    {
                        !keycloak.hasRealmRole("operator") &&
                        <ButtonComponent
                            label={"Annulla"}
                            onClick={() => {
                                navigation('/operator')
                            }}
                            color={"gray"}
                            variant={"outline"}
                            size={"md"}
                            iconPosition="off"
                        />
                    }
                    <ButtonComponent
                        label={"Aggiorna"}
                        onClick={() => {
                            if (operatorState.editOperatorRequest.avatar !== null)
                                formData.append('avatar', operatorState.editOperatorRequest.avatar)
                            formData.append('user', JSON.stringify(operatorState.editOperatorRequest))
                            dispatch(editOperator({ id: id !== undefined ? id : '', request: formData }))
                            if (!keycloak.hasRealmRole("operator")) {
                                dispatch(setFindByIdOperatorStatus('idle'))
                                navigation('/operator')
                            }
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"md"}
                        iconPosition="off"
                        disabled={
                            operatorState.editOperatorRequest.name === "" || operatorState.editOperatorRequest.surname === ""
                        }
                    />
                </div>
            ]}
            breadcrumbItems={!keycloak.hasRealmRole("operator") ? ["Operatori", "Modifica operatore"] : ["Impostazioni", "Anagrafica"]}
            breadcrumbIcon={!keycloak.hasRealmRole("operator") ? <OperatorIcon size={"20"} color={colors.brandSecondary[500]} /> : <SettingIcon color={colors.brandSecondary[500]} size={"20"} />}
        >
            <ErrorPopup
                active={operatorState.editOperatorStatus === 'failed'}
                close={() => dispatch(setEditOperatorStatus('idle'))}
                message="Si è verificato un errore durante la modifica del profilo"
            />
            <SuccessPopup
                active={operatorState.editOperatorStatus === 'successfully'}
                close={() => dispatch(setEditOperatorStatus('idle'))}
                message="Profilo modificato"
            />
            <HorizontalTab>
                <Tab
                    label={"Anagrafica"}
                    selectedPrimary={operatorState.selectedTab === 0}
                    onClick={() => dispatch(setSelectedTab(0))}
                    type="primary"
                />
            </HorizontalTab>
            <EditOperator />
        </LayoutComponent >
    )
}