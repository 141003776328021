import React from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { SearchIcon } from "../../ui/icons/searchIcon.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { findAllOperator, setOperatorFilterName } from "./slice.ts";

export function OperatorFilters() {
    const dispatch = useAppDispatch()
    const operatorState = useAppSelector(state => state.operator)

    return (
        <div className={"flex flex-row gap-4 w-full"}>
            <div className={"w-full"}>
                <InputComponent
                    type={"text"}
                    placeholder="Cerca un operatore"
                    startIcon={<SearchIcon />}
                    onChangeText={(value) => dispatch(setOperatorFilterName(value))}
                    value={operatorState.operatorFilters.name}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            dispatch(findAllOperator(operatorState.operatorFilters))
                        }
                    }}
                />
            </div>
            <div className={"gap-4 flex flex-row"}>
                <ButtonComponent
                    label={"Pulisci"}
                    onClick={() => {
                        dispatch(setOperatorFilterName(""))
                        dispatch(findAllOperator({
                            page: operatorState.operatorFilters.page,
                            itemsPerPage: operatorState.operatorFilters.itemsPerPage,
                            name: "",
                            sort: operatorState.operatorFilters.sort,
                            order: operatorState.operatorFilters.order
                        }))
                    }}
                    color={"gray"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition={"off"}
                />
                <ButtonComponent
                    label={"Applica"}
                    onClick={() => {
                        dispatch(findAllOperator({
                            page: operatorState.operatorFilters.page,
                            itemsPerPage: operatorState.operatorFilters.itemsPerPage,
                            name: operatorState.operatorFilters.name,
                            sort: operatorState.operatorFilters.sort,
                            order: operatorState.operatorFilters.order
                        }))
                    }}
                    color={"blue"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition={"off"}
                />
            </div>
        </div>
    )
}