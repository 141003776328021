import axios from "axios";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { CollaboratorDTO, CollaboratorFiltersDTO, FindAllCollaboratorResponseDTO } from "./dto.ts";
import { CollaboratorService } from "./service.ts";

export class CollaboratServiceImpl implements CollaboratorService {

    public findAllCollaborator(filters: CollaboratorFiltersDTO): Promise<FindAllCollaboratorResponseDTO> {
        let url: string = "/api/collaborators/?" +
            "itemsPerPage=" + filters.itemsPerPage +
            "&order=" + filters.order +
            "&page=" + filters.page +
            "&sort=" + filters.sort +
            (filters.name !== '' ? ('&name=' + filters.name) : '') +
            (filters.financialAdvisorId !== 0 ? ("&financialAdvisorId=" + filters.financialAdvisorId) : '')
        return axios({
            url: url,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByIdCollaborator(id: string): Promise<CollaboratorDTO> {
        let url: string = "/api/collaborators/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createCollaborator(request: FormData): Promise<void> {
        let url: string = "/api/collaborators"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editCollaborator(id: string, request: FormData): Promise<void> {
        let url: string = "/api/collaborators/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteCollaborator(id: string): Promise<void> {
        let url: string = "/api/collaborators/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }
}
