import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/utilis/index.ts";
import { CreateOperatorRequestDTO, EditOperatorRequestDTO, FindAllOperatorResponseDTO, OperatorDTO, OperatorFiltersDTO } from "./dto.ts";
import { NewOperatorService } from "./service.ts";

interface OperatorState {
    findAllOperatorStatus: PromiseStatuses
    findAllOperatorResponse?: FindAllOperatorResponseDTO
    operatorFilters: OperatorFiltersDTO
    deleteOperatorStatus: PromiseStatuses
    createOperatorRequest: CreateOperatorRequestDTO
    createOperatorStatus: PromiseStatuses
    findByIdOperatorStatus: PromiseStatuses
    findByIdOperatorResponse?: OperatorDTO
    editOperatorStatus: PromiseStatuses
    editOperatorRequest: EditOperatorRequestDTO
    openNewOperatorModal: boolean
    selectedTab: number
    openConfirmModal: boolean
    operatorId: string
}

const initialState: OperatorState = {
    findAllOperatorStatus: 'idle',
    operatorFilters: { page: 0, itemsPerPage: 25, name: '', sort: '', order: false },
    deleteOperatorStatus: 'idle',
    createOperatorRequest: {
        name: "",
        surname: "",
        email: "",
        userId: "",
        avatarObjectId: 0,
        note: "",
        avatar: null
    },
    createOperatorStatus: 'idle',
    findByIdOperatorStatus: 'idle',
    editOperatorRequest: {
        name: "",
        surname: "",
        avatarObjectId: 0,
        note: "",
        avatar: null
    },
    editOperatorStatus: 'idle',
    openNewOperatorModal: false,
    selectedTab: 0,
    openConfirmModal: false,
    operatorId: ""
}

export const findAllOperator = createAsyncThunk(
    'operator/findAllOperator',
    async (filters: OperatorFiltersDTO, thunkApi): Promise<FindAllOperatorResponseDTO> => {
        const operatorService = NewOperatorService()

        return operatorService.findAllOperator(filters)
    }
)

export const createOperator = createAsyncThunk(
    'operator/createOperator',
    async (request: FormData, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.createOperator(request)
    }
)

export const findByIdOperator = createAsyncThunk(
    'operator/findByIdOperator',
    async (id: string, thunkApi): Promise<OperatorDTO> => {
        const operatorService = NewOperatorService()

        return operatorService.findByIdOperator(id)
    }
)

export const editOperator = createAsyncThunk(
    'operator/editOperator',
    async (request: { id: string, request: FormData }, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.editOperator(request.id, request.request)
    }
)

export const deleteOperator = createAsyncThunk(
    'operator/deleteOperator',
    async (id: string, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.deleteOperator(id)
    }
)

const OperatorSlice = createSlice({
    name: 'operator/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllOperator.pending, (state) => {
                state.findAllOperatorStatus = 'loading'
            })
            .addCase(findAllOperator.fulfilled, (state, action) => {
                state.findAllOperatorStatus = 'successfully'
                state.findAllOperatorResponse = action.payload
            })
            .addCase(findAllOperator.rejected, (state) => {
                state.findAllOperatorStatus = 'failed'
            })
            .addCase(createOperator.pending, (state) => {
                state.createOperatorStatus = 'loading'
            })
            .addCase(createOperator.fulfilled, (state, action) => {
                state.createOperatorStatus = 'successfully'
            })
            .addCase(createOperator.rejected, (state) => {
                state.createOperatorStatus = 'failed'
            })
            .addCase(findByIdOperator.pending, (state) => {
                state.findByIdOperatorStatus = 'loading'
            })
            .addCase(findByIdOperator.fulfilled, (state, action) => {
                state.findByIdOperatorStatus = 'successfully'
                state.findByIdOperatorResponse = action.payload
            })
            .addCase(findByIdOperator.rejected, (state) => {
                state.findByIdOperatorStatus = 'failed'
            })
            .addCase(editOperator.pending, (state) => {
                state.editOperatorStatus = 'loading'
            })
            .addCase(editOperator.fulfilled, (state, action) => {
                state.editOperatorStatus = 'successfully'
            })
            .addCase(editOperator.rejected, (state) => {
                state.editOperatorStatus = 'failed'
            })
            .addCase(deleteOperator.pending, (state) => {
                state.deleteOperatorStatus = 'loading'
            })
            .addCase(deleteOperator.fulfilled, (state, action) => {
                state.deleteOperatorStatus = 'successfully'
            })
            .addCase(deleteOperator.rejected, (state) => {
                state.deleteOperatorStatus = 'failed'
            })
    },
    reducers: {
        setFindAllOperatorStatus: (state, action) => {
            state.findAllOperatorStatus = action.payload
        },
        setFindByIdOperatorStatus: (state, action) => {
            state.findByIdOperatorStatus = action.payload
        },
        resetCreateOperatorRequest: (state) => {
            state.createOperatorRequest = {
                name: "",
                surname: "",
                email: "",
                userId: "",
                avatarObjectId: 0,
                note: "",
                avatar: null
            }
        },
        setCreateOperatorRequestNote: (state, action) => {
            state.createOperatorRequest.note = action.payload
        },
        setCreateOperatorStatus: (state, action) => {
            state.createOperatorStatus = action.payload
        },
        setCreateOperatorRequestName: (state, action) => {
            state.createOperatorRequest.name = action.payload
        },
        setCreateOperatorRequestSurname: (state, action) => {
            state.createOperatorRequest.surname = action.payload
        },
        setCreateOperatorRequestEmail: (state, action) => {
            state.createOperatorRequest.email = action.payload
        },
        setEditOperatorStatus: (state, action) => {
            state.editOperatorStatus = action.payload
        },
        setEditOperatorRequestName: (state, action) => {
            state.editOperatorRequest.name = action.payload
        },
        setEditOperatorRequestSurname: (state, action) => {
            state.editOperatorRequest.surname = action.payload
        },
        setEditOperatorRequestNote: (state, action) => {
            state.editOperatorRequest.note = action.payload
        },
        setDeleteOperatorStatus: (state, action) => {
            state.deleteOperatorStatus = action.payload
        },
        setOpenNewOperatorModal: (state, action) => {
            state.openNewOperatorModal = action.payload
        },
        setOperatorFilterName: (state, action) => {
            state.operatorFilters.name = action.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setOpenConfirmModal: (state, action) => {
            state.openConfirmModal = action.payload
        },
        setOperatorFilterPage: (state, action) => {
            state.operatorFilters.page = action.payload
        },
        setOperatorId: (state, action) => {
            state.operatorId = action.payload
        },
        setEditOperatorAvatar: (state, action) => {
            state.editOperatorRequest.avatar = action.payload
        }
    },
})

export const {
    setCreateOperatorRequestEmail,
    setCreateOperatorRequestName,
    setCreateOperatorRequestSurname,
    setCreateOperatorStatus,
    setDeleteOperatorStatus,
    setEditOperatorRequestName,
    setEditOperatorRequestSurname,
    setEditOperatorStatus,
    setFindAllOperatorStatus,
    setFindByIdOperatorStatus,
    setOpenNewOperatorModal,
    setOperatorFilterName,
    setSelectedTab,
    setOpenConfirmModal,
    setOperatorFilterPage,
    setOperatorId,
    resetCreateOperatorRequest,
    setCreateOperatorRequestNote,
    setEditOperatorRequestNote,
    setEditOperatorAvatar
} = OperatorSlice.actions

export default OperatorSlice.reducer

