import React, { ReactNode } from "react";

interface Props {
    children: ReactNode
}

export function HorizontalTab(props: Props) {
    const { children } = props;

    return (
        <div>
            <div className={"flex flex-row"}>
                {children}
            </div>
            <div className="w-full h-[2px] bg-neutral-200 mt-[-2px]" />
        </div>
    )
}