import clsx from "clsx";
import React from "react";

interface Props {
    selected: boolean
    label: String
    onClick: () => void
}

export function SegmentControl(props: Props) {
    const { selected } = props;
    const { label } = props;
    const { onClick } = props

    return (
        <div className="w-full rounded-[50px] p-[2px] gap-2" onClick={onClick}>
            <div className={
                clsx("h-[32px] top-4 left-4 rounded-[40px] px-4 py-1 text-label-xs font-semibold flex items-center justify-center cursor-pointer", {
                    "bg-brandPrimary-500 text-brandSecondary-50 hover:bg-brandPrimary-600": selected,
                    "bg-brandPrimary-100 text-brandSecondary-600 hover:bg-brandPrimary-200": !selected
                })}
            >
                {label}
            </div>
        </div>
    )
}