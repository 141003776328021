import { CSSProperties } from "react";

export type AvatarSize = '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type AvatarSingleShape = 'square' | 'circle'
export type AvatarInfoSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type AvatarType = 'single' | 'profile' | 'info' | 'group'
export type AvatarProfileShape = 'square' | 'rectangular'
export type AvatarProfileIcon = 'email' | 'source'
export type AvatarGroupSize = 'xs' | 'sm' | 'md'
export type AvatarGroupLength = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export type AvatarProps =
    | { type: 'single'; size: AvatarSize, shape: AvatarSingleShape, imageUrl: string, altTextInitials: string }
    | { type: 'profile'; shape: AvatarProfileShape, imageUrl: string, profileIcon?: AvatarProfileIcon, profileInfo?: string, placeholder: string, altTextInitials: string }
    | { type: 'info'; size: AvatarInfoSize, imageUrl: string, firstRow: string, secondRow?: string, thirdRow?: string, altTextInitials: string }
    | { type: 'group'; size: AvatarGroupSize, imageUrls: { url: string, altTextInitials: string }[], length: AvatarGroupLength, };

export type AvatarProfileProps = {
    sourceUrl: string;
};

export type AvatarInfoProps = {
    name: string;
    description: string;
};

export type AvatarGroupProps = {
    members: string[];
};

export const singleSizeMap = new Map<AvatarSize, CSSProperties>([
    ['3xs', { height: '16px', width: '16px' }],
    ['2xs', { height: '20px', width: '20px' }],
    ['xs', { height: '24px', width: '24px' }],
    ['sm', { height: '32px', width: '32px' }],
    ['md', { height: '40px', width: '40px' }],
    ['lg', { height: '48px', width: '48px' }],
    ['xl', { height: '56px', width: '56px' }],
    ['2xl', { height: '64px', width: '64px' }],
])

export const profileShapeMap = new Map<AvatarProfileShape, CSSProperties>([
    ['rectangular', { height: '200px', width: '160px', borderRadius: '0px', objectFit: 'cover' }],
    ['square', { height: '160px', width: '160px', borderRadius: '24px', objectFit: 'cover' }]
])

export const singleSquareRadiusMap = new Map<AvatarSize, CSSProperties>([
    ['3xs', { borderRadius: '4px' }],
    ['2xs', { borderRadius: '4px' }],
    ['xs', { borderRadius: '4px' }],
    ['sm', { borderRadius: '6px' }],
    ['md', { borderRadius: '6px' }],
    ['lg', { borderRadius: '6px' }],
    ['xl', { borderRadius: '8px' }],
    ['2xl', { borderRadius: '8px' }],
])
