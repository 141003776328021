import React from "react"
import { colors } from "../colors.ts"


export function CompanyIcon({ color = colors.brandSecondary[500], size = "20" }) {

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3333 18.9584H1.66666C1.325 18.9584 1.04166 18.675 1.04166 18.3334C1.04166 17.9917 1.325 17.7084 1.66666 17.7084H18.3333C18.675 17.7084 18.9583 17.9917 18.9583 18.3334C18.9583 18.675 18.675 18.9584 18.3333 18.9584Z" fill={color} />
            <path d="M17.5 18.9583H2.5C2.15833 18.9583 1.875 18.675 1.875 18.3333V4.99996C1.875 2.48329 3.31667 1.04163 5.83333 1.04163H14.1667C16.6833 1.04163 18.125 2.48329 18.125 4.99996V18.3333C18.125 18.675 17.8417 18.9583 17.5 18.9583ZM3.125 17.7083H16.875V4.99996C16.875 3.14996 16.0167 2.29163 14.1667 2.29163H5.83333C3.98333 2.29163 3.125 3.14996 3.125 4.99996V17.7083Z" fill={color} />
            <path d="M8.33334 14.375H5.83334C5.49167 14.375 5.20834 14.0917 5.20834 13.75C5.20834 13.4083 5.49167 13.125 5.83334 13.125H8.33334C8.675 13.125 8.95834 13.4083 8.95834 13.75C8.95834 14.0917 8.675 14.375 8.33334 14.375Z" fill={color} />
            <path d="M14.1667 14.375H11.6667C11.325 14.375 11.0417 14.0917 11.0417 13.75C11.0417 13.4083 11.325 13.125 11.6667 13.125H14.1667C14.5083 13.125 14.7917 13.4083 14.7917 13.75C14.7917 14.0917 14.5083 14.375 14.1667 14.375Z" fill={color} />
            <path d="M8.33334 10.625H5.83334C5.49167 10.625 5.20834 10.3417 5.20834 10C5.20834 9.65833 5.49167 9.375 5.83334 9.375H8.33334C8.675 9.375 8.95834 9.65833 8.95834 10C8.95834 10.3417 8.675 10.625 8.33334 10.625Z" fill={color} />
            <path d="M14.1667 10.625H11.6667C11.325 10.625 11.0417 10.3417 11.0417 10C11.0417 9.65833 11.325 9.375 11.6667 9.375H14.1667C14.5083 9.375 14.7917 9.65833 14.7917 10C14.7917 10.3417 14.5083 10.625 14.1667 10.625Z" fill={color} />
            <path d="M8.33334 6.875H5.83334C5.49167 6.875 5.20834 6.59167 5.20834 6.25C5.20834 5.90833 5.49167 5.625 5.83334 5.625H8.33334C8.675 5.625 8.95834 5.90833 8.95834 6.25C8.95834 6.59167 8.675 6.875 8.33334 6.875Z" fill={color} />
            <path d="M14.1667 6.875H11.6667C11.325 6.875 11.0417 6.59167 11.0417 6.25C11.0417 5.90833 11.325 5.625 11.6667 5.625H14.1667C14.5083 5.625 14.7917 5.90833 14.7917 6.25C14.7917 6.59167 14.5083 6.875 14.1667 6.875Z" fill={color} />
        </svg>
    )
}