import React, { ReactElement } from "react";

interface Props {
    label: string
    subLabel?: string
    children: ReactElement[]
}

export function FormRow(props: Props) {
    const { label } = props;
    const { subLabel } = props;
    const { children } = props;

    return (
        <div className={"flex flex-row gap-6"}>
            <div className={"flex flex-col w-[240px]"}>
                <span className={"text-text-md font-semibold text-neutral-600"}>{label}</span>
                <span className={"text-text-md font-regular text-neutral-400"}>{subLabel}</span>
            </div>
            <div className={"flex gap-4 w-full"}>
                {children}
            </div>
        </div>
    )
}