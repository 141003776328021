import React, { useEffect, useRef, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../colors.ts";
import { LogoutIcon } from "../../icons/logoutIcon.tsx";
import { NotificationIcon } from "../../icons/notificationIcon.tsx";
import { SupportIcon } from "../../icons/supportIcon.tsx";
import Avatar from "../../molecules/avatar/index.tsx";
import { SpinnerComponent } from "../../molecules/spinner/index.tsx";


export function HeaderComponent() {
    const [showMenu, setShowMenu] = useState(false)
    const menuRef = useRef<HTMLTableCellElement>(null);

    const authState = useAppSelector(state => state.auth)
    const objectState = useAppSelector(state => state.object)
    const dispatch = useAppDispatch()

    const [altTextInitials, setAltTextInitials] = useState<string>("")
    const [name, setName] = useState<string>("")
    const [avatarToBase64, setAvatarToBase64] = useState("")

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            closeMenu();
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        switch (true) {
            case keycloak.hasRealmRole("financial_advisor"):
                dispatch(findFileById(authState.findMeFinancialAdvisorResponse?.avatarObjectId!))
                setName(authState.findMeFinancialAdvisorResponse?.name + " " + authState.findMeFinancialAdvisorResponse?.surname)
                setAltTextInitials((authState.findMeFinancialAdvisorResponse?.name[0] || '') + (authState.findMeFinancialAdvisorResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole("operator"):
                dispatch(findFileById(authState.findMeOperatorResponse?.avatarObjectId!))
                setName(authState.findMeOperatorResponse?.name + " " + authState.findMeOperatorResponse?.surname)
                setAltTextInitials((authState.findMeOperatorResponse?.name[0] || '') + (authState.findMeOperatorResponse?.surname[0] || ''))

                break;
            case keycloak.hasRealmRole("financial_advisor_collaborator"):
                dispatch(findFileById(authState.findMeCollaboratorResponse?.avatarObjectId!))
                setName(authState.findMeCollaboratorResponse?.name + " " + authState.findMeCollaboratorResponse?.surname)
                setAltTextInitials((authState.findMeCollaboratorResponse?.name[0] || '') + (authState.findMeCollaboratorResponse?.surname[0] || ''))

                break;
            case keycloak.hasRealmRole("administrative_company"):
                dispatch(findFileById(authState.findMeReferrersResponse?.avatarObjectId!))
                setName(authState.findMeReferrersResponse?.name + " " + authState.findMeReferrersResponse?.surname)
                setAltTextInitials((authState.findMeReferrersResponse?.name[0] || '') + (authState.findMeReferrersResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole("company"):
                dispatch(findFileById(authState.findMeCompanyResponse?.avatarObjectId!))
                setName(authState.findMeCompanyResponse?.name + " " + authState.findMeCompanyResponse?.surname)
                setAltTextInitials((authState.findMeCompanyResponse?.name[0] || '') + (authState.findMeCompanyResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole('admin'):
                setAltTextInitials('AD')
                break;
            default:
                break;
        }
    }, [keycloak.hasRealmRole]);

    useEffect(() => {
        switch (true) {
            case keycloak.hasRealmRole("financial_advisor"):
                setAvatarToBase64(objectState.document!)
                break;
            case keycloak.hasRealmRole("operator"):
                setAvatarToBase64(objectState.document!)

                break;
            case keycloak.hasRealmRole("financial_advisor_collaborator"):
                setAvatarToBase64(objectState.document!)

                break;
            case keycloak.hasRealmRole("administrative_company"):
                setAvatarToBase64(objectState.document!)
                break;
            case keycloak.hasRealmRole("company"):
                setAvatarToBase64(objectState.document!)
                break;
            case keycloak.hasRealmRole('admin'):
                setAvatarToBase64("")
                break;
            default:
                break;
        }
        dispatch(setFindDocumentStatus('idle'))
    }, [keycloak.hasRealmRole, objectState.findDocumentStatus]);

    if (objectState.findDocumentStatus === "loading") {
        return (
            <div className={"flex items-center"}>
                <SpinnerComponent size={30} />
            </div>
        )
    }

    return (
        <div className="flex flex-row gap-[16px] items-center">
            <div className="flex flex-row gap-[16px]">
                <SupportIcon />
                <NotificationIcon />
            </div>
            <span className="text-text-sm font-semibold text-neutral-800">
                {keycloak.hasRealmRole('admin') ? 'Amministratore' : name}
            </span>
            <div
                className="cursor-pointer relative"
                onClick={e => { toggleMenu() }}
                ref={menuRef}
            >
                <Avatar type="single" size="md" shape="circle" imageUrl={avatarToBase64} altTextInitials={altTextInitials} />
                {
                    showMenu &&
                    <div
                        onClick={e => { e.preventDefault(); e.stopPropagation(); keycloak.logout({ redirectUri: window.location.origin }) }}
                        className={"absolute bg-white right-2 shadow-sm rounded-xl p-3 gap-2 flex items-center w-[200px] hover:bg-brandPrimary-100"}
                    >
                        <LogoutIcon color={colors.neutral[500]} />
                        <span className={"text-text-md text-neutral-600 font-semibold  hover:text-brandPrimary-500"}>Esci</span>
                    </div>
                }
            </div>
        </div>
    )
}