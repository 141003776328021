import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React from "react";
import { colors } from "../../colors.ts";
import { CheckedIcon } from "../../icons/checkedIcon.tsx";
import { ChevronDownIcon } from '../../icons/chevronDownIcon.tsx';
import './style.css';

interface Props {
    onChange: (e: MultiSelectChangeEvent) => void
    value: { name: string; code: string; }[]
    option: { name: string, code: string }[]
    placeholder?: string
    disabled?: boolean
}

export default function MultiSelectCheckbox(props: Props) {
    const { onChange } = props;
    const { value } = props;
    const { option } = props;
    const { placeholder } = props;
    const { disabled } = props;

    return (
        <div>
            <MultiSelect
                dropdownIcon={<ChevronDownIcon size={"20"} color={colors.neutral[300]} />}
                value={value}
                onChange={onChange}
                options={option}
                optionLabel="name"
                placeholder={placeholder}
                maxSelectedLabels={5}
                className="w-full"
                checkboxIcon={<CheckedIcon size={"20"} color={colors.white} />}
                itemCheckboxIcon={<CheckedIcon size={"20"} color={colors.white} />}
                selectAllLabel={"Seleziona tutti"}
                emptyMessage={"Non è disponibile nessuna opzione"}
                selectedItemsLabel="{0} selezionati"
                disabled={disabled}
            />
        </div>
    );
}