import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/utilis/index.ts";
import { CollaboratorDTO, CollaboratorFiltersDTO, CreateCollaboratorRequestDTO, EditCollaboratorRequestDTO, FindAllCollaboratorResponseDTO } from "./dto.ts";
import { NewCollaboratorService } from "./service.ts";

interface CollaboratorState {
    findAllCollaboratorStatus: PromiseStatuses
    findAllCollaboratorResponse?: FindAllCollaboratorResponseDTO
    collaboratorFilters: CollaboratorFiltersDTO
    createCollaboratorRequest: CreateCollaboratorRequestDTO
    createCollaboratorStatus: PromiseStatuses
    openNewCollaboratorModal: boolean
    findAllCollaboratorsForFinancialAdvisorResponse?: CollaboratorDTO[]
    findAllCollaboratorsForFinancialAdvisorStatus: PromiseStatuses
    deleteCollaboratorStatus: PromiseStatuses
    openConfirmModal: boolean
    saveOrEditModal: 'save' | 'edit'
    findByIdCollaboratoreStatus: PromiseStatuses
    findByIdCollaboratoreResponse?: CollaboratorDTO
    editCollaboratorStatus: PromiseStatuses
    editCollaboratorRequest: EditCollaboratorRequestDTO
    selectedTab: number
}

const initialState: CollaboratorState = {
    findAllCollaboratorStatus: 'idle',
    collaboratorFilters: { page: 0, itemsPerPage: 15, name: '', sort: '', order: false, financialAdvisorId: 0 },
    createCollaboratorStatus: 'idle',
    createCollaboratorRequest: {
        name: '',
        surname: '',
        email: '',
        financialAdvisorId: 0,
        phoneNumber: '',
        avatarObjectId: 0,
        companyIds: [],
        note: "",
        avatar: null
    },
    openNewCollaboratorModal: false,
    findAllCollaboratorsForFinancialAdvisorStatus: 'idle',
    deleteCollaboratorStatus: 'idle',
    openConfirmModal: false,
    saveOrEditModal: 'save',
    findByIdCollaboratoreStatus: 'idle',
    editCollaboratorStatus: 'idle',
    editCollaboratorRequest: {
        name: "",
        surname: "",
        email: "",
        phoneNumber: "",
        avatarObjectId: 0,
        note: "",
        avatar: null
    },
    selectedTab: 0
}

export const findAllCollaborator = createAsyncThunk(
    'collaborator/findAllCollaborator',
    async (filters: CollaboratorFiltersDTO, thunkApi): Promise<FindAllCollaboratorResponseDTO> => {
        const collaboratorService = NewCollaboratorService()

        return collaboratorService.findAllCollaborator(filters)
    }
)

export const findByIdCollaborator = createAsyncThunk(
    'collaborator/findByIdCollaborator',
    async (id: string, thunkApi): Promise<CollaboratorDTO> => {
        const collaboratorService = NewCollaboratorService()

        return collaboratorService.findByIdCollaborator(id)
    }
)

export const createCollaborator = createAsyncThunk(
    'collaborator/createCollaborator',
    async (request: FormData, thunkApi): Promise<void> => {
        const collaboratorService = NewCollaboratorService()

        return collaboratorService.createCollaborator(request)
    }
)
export const editCollaborator = createAsyncThunk(
    'collaborator/editCollaborator',
    async (request: { id: string, request: FormData }, thunkApi): Promise<void> => {
        const collaboratorService = NewCollaboratorService()

        return collaboratorService.editCollaborator(request.id, request.request)
    }
)

export const deleteCollaborator = createAsyncThunk(
    'delete/deleteCollaborator',
    async (id: string, thunkApi): Promise<void> => {
        const collaboratorService = NewCollaboratorService()

        return collaboratorService.deleteCollaborator(id)
    }
)

const collaboratorSlice = createSlice({
    name: 'collaborator/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllCollaborator.pending, (state) => {
                state.findAllCollaboratorStatus = 'loading'
            })
            .addCase(findAllCollaborator.fulfilled, (state, action) => {
                state.findAllCollaboratorStatus = 'successfully'
                state.findAllCollaboratorResponse = action.payload
            })
            .addCase(findAllCollaborator.rejected, (state) => {
                state.findAllCollaboratorStatus = 'failed'
            })
            .addCase(findByIdCollaborator.pending, (state) => {
                state.findByIdCollaboratoreStatus = 'loading'
            })
            .addCase(findByIdCollaborator.fulfilled, (state, action) => {
                state.findByIdCollaboratoreStatus = 'successfully'
                state.findByIdCollaboratoreResponse = action.payload
            })
            .addCase(findByIdCollaborator.rejected, (state) => {
                state.findByIdCollaboratoreStatus = 'failed'
            })
            .addCase(createCollaborator.pending, (state) => {
                state.createCollaboratorStatus = 'loading'
            })
            .addCase(createCollaborator.fulfilled, (state, action) => {
                state.createCollaboratorStatus = 'successfully'
            })
            .addCase(createCollaborator.rejected, (state) => {
                state.createCollaboratorStatus = 'failed'
            })
            .addCase(editCollaborator.pending, (state) => {
                state.editCollaboratorStatus = 'loading'
            })
            .addCase(editCollaborator.fulfilled, (state, action) => {
                state.editCollaboratorStatus = 'successfully'
            })
            .addCase(editCollaborator.rejected, (state) => {
                state.editCollaboratorStatus = 'failed'
            })
            .addCase(deleteCollaborator.pending, (state) => {
                state.deleteCollaboratorStatus = 'loading'
            })
            .addCase(deleteCollaborator.fulfilled, (state, action) => {
                state.deleteCollaboratorStatus = 'successfully'
            })
            .addCase(deleteCollaborator.rejected, (state) => {
                state.deleteCollaboratorStatus = 'failed'
            })
    },
    reducers: {
        resetCreateCollaboratorsRequest: (state) => {
            state.createCollaboratorRequest = {
                name: '',
                surname: '',
                email: '',
                financialAdvisorId: 0,
                phoneNumber: '',
                avatarObjectId: 0,
                companyIds: [],
                note: "",
                avatar: null
            }
        },
        setCreateCollaboratorsNote: (state, action) => {
            state.createCollaboratorRequest.note = action.payload
        },
        setCreateCollaboratorsName: (state, action) => {
            state.createCollaboratorRequest.name = action.payload
        },
        setCreateCollaboratorsSurname: (state, action) => {
            state.createCollaboratorRequest.surname = action.payload
        },
        setCreateCollaboratorsEmail: (state, action) => {
            state.createCollaboratorRequest.email = action.payload
        },
        setCreateCollaboratorsPhoneNumber: (state, action) => {
            state.createCollaboratorRequest.phoneNumber = action.payload
        },
        setCreateCollaboratorsStatus: (state, action) => {
            state.createCollaboratorStatus = action.payload
        },
        setCreateCollaboratorsCompanyId: (state, action) => {
            state.createCollaboratorRequest.companyIds = action.payload
        },
        setOpenNewCollaboratorModal: (state, action) => {
            state.openNewCollaboratorModal = action.payload
        },
        setFindAllCollaboratorStatus: (state, action) => {
            state.findAllCollaboratorStatus = action.payload
        },
        setCreateFinancialAdvisordId: (state, action) => {
            state.createCollaboratorRequest.financialAdvisorId = action.payload
        },
        setOpenConfirmModal: (state, action) => {
            state.openConfirmModal = action.payload
        },
        setSaveOrEdit: (state, action) => {
            state.saveOrEditModal = action.payload
        },
        setEditCollaboratorName: (state, action) => {
            state.editCollaboratorRequest.name = action.payload
        },
        setEditCollaboratorSurname: (state, action) => {
            state.editCollaboratorRequest.surname = action.payload
        },
        setEditCollaboratorEmail: (state, action) => {
            state.editCollaboratorRequest.email = action.payload
        },
        setEditCollaboratorNote: (state, action) => {
            state.editCollaboratorRequest.note = action.payload
        },
        setEditCollaboratorPhoneNumber: (state, action) => {
            state.editCollaboratorRequest.phoneNumber = action.payload
        },
        setFindByIdCollaboratorStatus: (state, action) => {
            state.findByIdCollaboratoreStatus = action.payload
        },
        setEditCollaboratorStatus: (state, action) => {
            state.editCollaboratorStatus = action.payload
        },
        setDeleteCollaboratorStatus: (state, action) => {
            state.deleteCollaboratorStatus = action.payload
        },
        setCollaboratorFiltersFinancialAdvisorId: (state, action) => {
            state.collaboratorFilters.financialAdvisorId = action.payload
        },
        setCollaboratorFiltersName: (state, action) => {
            state.collaboratorFilters.name = action.payload
        },
        setCollaboratorFilterPage: (state, action) => {
            state.collaboratorFilters.page = action.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setEditCollaboratorFile: (state, action) => {
            state.editCollaboratorRequest.avatar = action.payload
        }
    },
})

export const {
    setCreateCollaboratorsEmail,
    setCreateCollaboratorsName,
    setCreateCollaboratorsPhoneNumber,
    setCreateCollaboratorsSurname,
    setCreateCollaboratorsStatus,
    setFindAllCollaboratorStatus,
    setOpenNewCollaboratorModal,
    setCreateFinancialAdvisordId,
    setOpenConfirmModal,
    setSaveOrEdit,
    setEditCollaboratorName,
    setEditCollaboratorPhoneNumber,
    setEditCollaboratorSurname,
    setEditCollaboratorEmail,
    setFindByIdCollaboratorStatus,
    setEditCollaboratorStatus,
    setDeleteCollaboratorStatus,
    setCollaboratorFiltersFinancialAdvisorId,
    setCollaboratorFiltersName,
    setCreateCollaboratorsCompanyId,
    setCreateCollaboratorsNote,
    setEditCollaboratorNote,
    resetCreateCollaboratorsRequest,
    setSelectedTab,
    setCollaboratorFilterPage,
    setEditCollaboratorFile
} = collaboratorSlice.actions

export default collaboratorSlice.reducer

