import axios from "axios";
import { keycloak } from "../../lib/keycloak/index.ts";
import { FinancialAdvisorDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO } from "./dto.ts";
import { FinancialAdvisorService } from "./service.ts";

export class FinancialAdvisorServiceImpl implements FinancialAdvisorService {

    public findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<findAllFinancialAdvisorResponseDTO> {
        let url: string = "/api/financialAdvisors?" +
            "itemsPerPage=" + filters.itemsPerPage +
            "&order=" + filters.order +
            "&page=" + filters.page +
            "&sort=" + filters.sort +
            (filters.name !== '' ? ('&name=' + filters.name) : '')
        return axios({
            url: url,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createFinancialAdvisor(request: FormData): Promise<void> {
        let url: string = "/api/financialAdvisors"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteFinancialAdvisor(id: string): Promise<void> {
        let url: string = "/api/financialAdvisors/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public findByIdFinancialAdvisor(id: string): Promise<FinancialAdvisorDTO> {
        let idByPath = window.location.pathname.split("/")[2]
        let _id = id;

        if (idByPath !== undefined && window.location.pathname === ("/operation/" + idByPath)) {
            _id = idByPath
        }

        let url: string = "/api/financialAdvisors/" + _id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editFinancialAdvisor(id: string, request: FormData): Promise<void> {
        let url: string = "/api/financialAdvisors/" + id
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
