import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/utilis/index.ts";
import { CreateReferrersRequestDTO, EditReferrersRequestDTO, FindAllReferrersResponseDTO, ReferrersDTO, ReferrersFilterDTO } from "./dto.ts";
import { NewReferrersService } from "./service.ts";

interface ReferrersState {
    findAllReferrersResponse?: FindAllReferrersResponseDTO
    findAllReferrersStatus: PromiseStatuses
    createReferrersRequest: CreateReferrersRequestDTO
    createReferrersStatus: PromiseStatuses
    referrersFilter: ReferrersFilterDTO
    openNewOrEditReferrersModal: boolean
    saveOrEditModal: 'save' | 'edit'
    editReferrersStatus: PromiseStatuses
    editReferrersRequest: EditReferrersRequestDTO
    findByIdReferrerStatus: PromiseStatuses
    findByIdReferrerResponse?: ReferrersDTO
    openConfirmModal: boolean
    deleteReferrerStatus: PromiseStatuses
}

const initialState: ReferrersState = {
    findAllReferrersStatus: 'idle',
    createReferrersRequest: {
        name: "",
        surname: "",
        email: "",
        companyId: 0,
        note: "",
        avatar: null
    },
    createReferrersStatus: 'idle',
    referrersFilter: {
        itemsPerPage: 15,
        order: false,
        page: 0,
        sort: "",
        name: "",
        companyId: 0,
    },
    openNewOrEditReferrersModal: false,
    saveOrEditModal: 'save',
    editReferrersRequest: {
        name: "",
        surname: "",
        email: "",
        note: "",
        avatar: null
    },
    editReferrersStatus: 'idle',
    findByIdReferrerStatus: 'idle',
    openConfirmModal: false,
    deleteReferrerStatus: 'idle'
}

export const findAllReferrers = createAsyncThunk(
    'referrers/findAllReferrers',
    async (filters: ReferrersFilterDTO, thunkApi): Promise<FindAllReferrersResponseDTO> => {
        const referrersService = NewReferrersService()

        return referrersService.findAllReferrers(filters)
    }
)

export const findByIdReferrer = createAsyncThunk(
    'referrers/findByIdReferrer',
    async (id: string, thunkApi): Promise<ReferrersDTO> => {
        const referrersService = NewReferrersService()

        return referrersService.findByIdReferrer(id)
    }
)

export const createReferrer = createAsyncThunk(
    'referrers/createReferrer',
    async (request: FormData, thunkApi): Promise<void> => {
        const referrersService = NewReferrersService()

        return referrersService.createReferrer(request)
    }
)

export const editReferrer = createAsyncThunk(
    'referrers/editReferrer',
    async (request: { id: string, request: FormData }, thunkApi): Promise<void> => {
        const referrersService = NewReferrersService()

        return referrersService.editReferrer(request.id, request.request)
    }
)

export const deleteReferrer = createAsyncThunk(
    'delete/deleteReferrer',
    async (id: string, thunkApi): Promise<void> => {
        const referrersService = NewReferrersService()

        return referrersService.deleteReferrer(id)
    }
)

const referrersSlice = createSlice({
    name: 'referrers/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllReferrers.pending, (state) => {
                state.findAllReferrersStatus = 'loading'
            })
            .addCase(findAllReferrers.fulfilled, (state, action) => {
                state.findAllReferrersStatus = 'successfully'
                state.findAllReferrersResponse = action.payload
            })
            .addCase(findAllReferrers.rejected, (state) => {
                state.findAllReferrersStatus = 'failed'
            })
            .addCase(findByIdReferrer.pending, (state) => {
                state.findByIdReferrerStatus = 'loading'
            })
            .addCase(findByIdReferrer.fulfilled, (state, action) => {
                state.findByIdReferrerStatus = 'successfully'
                state.findByIdReferrerResponse = action.payload
            })
            .addCase(findByIdReferrer.rejected, (state) => {
                state.findByIdReferrerStatus = 'failed'
            })
            .addCase(createReferrer.pending, (state) => {
                state.createReferrersStatus = 'loading'
            })
            .addCase(createReferrer.fulfilled, (state, action) => {
                state.createReferrersStatus = 'successfully'
            })
            .addCase(createReferrer.rejected, (state) => {
                state.createReferrersStatus = 'failed'
            })
            .addCase(editReferrer.pending, (state) => {
                state.editReferrersStatus = 'loading'
            })
            .addCase(editReferrer.fulfilled, (state, action) => {
                state.editReferrersStatus = 'successfully'
            })
            .addCase(editReferrer.rejected, (state) => {
                state.editReferrersStatus = 'failed'
            })
            .addCase(deleteReferrer.pending, (state) => {
                state.deleteReferrerStatus = 'loading'
            })
            .addCase(deleteReferrer.fulfilled, (state, action) => {
                state.deleteReferrerStatus = 'successfully'
            })
            .addCase(deleteReferrer.rejected, (state) => {
                state.deleteReferrerStatus = 'failed'
            })
    },
    reducers: {
        setNewOrEditReferrersModal: (state, action) => {
            state.openNewOrEditReferrersModal = action.payload
        },
        setSaveOrEditModal: (state, action) => {
            state.saveOrEditModal = action.payload
        },
        setReferrersFiltersCompanyId: (state, action) => {
            state.referrersFilter.companyId = action.payload
        },
        setReferrersFiltersName: (state, action) => {
            state.referrersFilter.name = action.payload
        },
        setReferrersFilterPage: (state, action) => {
            state.referrersFilter.page = action.payload
        },
        setCreateReferrersName: (state, action) => {
            state.createReferrersRequest.name = action.payload
        },
        setCreateReferrersSurname: (state, action) => {
            state.createReferrersRequest.surname = action.payload
        },
        setCreateReferrersEmail: (state, action) => {
            state.createReferrersRequest.email = action.payload
        },
        setCreateReferrersNote: (state, action) => {
            state.createReferrersRequest.note = action.payload
        },
        setCreateReferrersClientId: (state, action) => {
            state.createReferrersRequest.companyId = action.payload
        },
        resetCreateReferrersRequest: (state) => {
            state.createReferrersRequest = {
                name: "",
                surname: "",
                email: "",
                companyId: 0,
                note: "",
                avatar: null
            }
        },
        setEditReferrersName: (state, action) => {
            state.editReferrersRequest.name = action.payload
        },
        setEditReferrersSurname: (state, action) => {
            state.editReferrersRequest.surname = action.payload
        },
        setEditReferrersEmail: (state, action) => {
            state.editReferrersRequest.email = action.payload
        },
        setEditReferrersNote: (state, action) => {
            state.editReferrersRequest.note = action.payload
        },
        setFindByIdReferrerStatus: (state, action) => {
            state.findByIdReferrerStatus = action.payload
        },
        setOpenConfirmModal: (state, action) => {
            state.openConfirmModal = action.payload
        },
        setCreateReferrersStatus: (state, action) => {
            state.createReferrersStatus = action.payload
        },
        setEditReferrersStatus: (state, action) => {
            state.editReferrersStatus = action.payload
        },
        setDeleteReferrersStatus: (state, action) => {
            state.deleteReferrerStatus = action.payload
        },
        setEditRefferrersAvatar: (state, action) => {
            state.editReferrersRequest.avatar = action.payload
        }
    },
})

export const {
    setNewOrEditReferrersModal,
    setCreateReferrersClientId,
    setCreateReferrersEmail,
    setCreateReferrersName,
    setCreateReferrersSurname,
    setSaveOrEditModal,
    setEditReferrersEmail,
    setEditReferrersName,
    setEditReferrersSurname,
    setFindByIdReferrerStatus,
    setOpenConfirmModal,
    setCreateReferrersStatus,
    setDeleteReferrersStatus,
    setEditReferrersStatus,
    setReferrersFiltersCompanyId,
    setReferrersFiltersName,
    setCreateReferrersNote,
    setEditReferrersNote,
    resetCreateReferrersRequest,
    setReferrersFilterPage,
    setEditRefferrersAvatar
} = referrersSlice.actions

export default referrersSlice.reducer

