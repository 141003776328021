import React from "react";
import { IconsProps } from "./dto.ts";

export function UploadIcon(props: IconsProps) {

    return (
        <svg width={props.size} height={props.size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill={props.backgroundColor} />
            <path d="M23.3333 23.3334L20 20M20 20L16.6667 23.3334M20 20V27.5M26.9917 25.325C27.8044 24.8819 28.4465 24.1808 28.8166 23.3322C29.1866 22.4837 29.2635 21.5361 29.0352 20.6389C28.8068 19.7418 28.2862 18.9463 27.5556 18.3779C26.8249 17.8095 25.9257 17.5006 25 17.5H23.95C23.6978 16.5244 23.2276 15.6186 22.575 14.8509C21.9223 14.0831 21.104 13.4732 20.1817 13.0672C19.2595 12.6612 18.2571 12.4695 17.2501 12.5066C16.2431 12.5437 15.2576 12.8086 14.3676 13.2814C13.4777 13.7542 12.7066 14.4226 12.1122 15.2363C11.5178 16.0501 11.1156 16.988 10.9358 17.9795C10.7561 18.9711 10.8034 19.9905 11.0743 20.9611C11.3453 21.9317 11.8327 22.8282 12.5 23.5834" stroke={props.color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}