export enum HeadingSize {
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
}


export const headingSizeMap = new Map<HeadingSize, string>([
    [HeadingSize.SM, "sm"],
    [HeadingSize.MD, "sm"],
    [HeadingSize.LG, "md"]
])

export const headingTitleMap = new Map<HeadingSize, string>([
    [HeadingSize.LG, 'text-heading-sm font-bold text-neutral-800'],
    [HeadingSize.MD, 'text-heading-xs font-bold text-neutral-800'],
    [HeadingSize.SM, 'text-text-lg font-semibold text-neutral-800'],
])