import { format } from "date-fns";
import React, { useEffect } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { ChatIcon } from "../../ui/icons/chatIcon.tsx";
import { CloseIcon } from "../../ui/icons/closeIcon.tsx";
import { DownloadIcon } from "../../ui/icons/downloadIcon.tsx";
import { F24Icon } from "../../ui/icons/f24Icon.tsx";
import { OtherIcon } from "../../ui/icons/otherIcon.tsx";
import { RadioIcon } from "../../ui/icons/radioIcon.tsx";
import { ReceivedFileIcon } from "../../ui/icons/receivedFileIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { GroupedRow } from "../../ui/organisms/groupedRow/index.tsx";
import { SegmentControl } from "../../ui/organisms/segmentControl/index.tsx";
import { FinancialAdvisorDTO } from "../financialAdvisor/dto.ts";
import { findAllFinancialAdvisor, findByIdFinancialAdvisor } from "../financialAdvisor/slice.ts";
import { Events } from "./events/index.tsx";
import "./style.css";

export function Overview() {
    const companyState = useAppSelector(state => state.company)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully") {
            let financialAdvisorId = Number(companyState.findByIdCompanyResponse?.financialAdvisor)
            dispatch(findByIdFinancialAdvisor(financialAdvisorId.toString()))
        }
    }, [companyState.findByIdCompanyStatus])

    useEffect(() => {
        if (keycloak.hasRealmRole("financial_advisor_collaborator")) {
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        }
    }, [keycloak.hasRealmRole])

    let financialAdvisor: FinancialAdvisorDTO | undefined

    if (financialAdvisorState.findAllFinancialAdvisorStatus === "successfully") {
        financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((item) => {
            financialAdvisor = item
        })
    }

    if (companyState.findByIdCompanyStatus === "loading" || financialAdvisorState.findByIdFinancialAdvisorStatus === "loading" || financialAdvisorState.findAllFinancialAdvisorStatus === "loading") {
        return (
            <div className={"flex flex-grow justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-4"}>
            <div className="h-[64px] rounded-xl border-[0.5px] flex flex-row header-widget">
                <div
                    style={{
                        backgroundColor: "rgba(194, 223, 255, 0.2)",
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 16,
                        padding: "12px 32px 12px 16px"
                    }}
                >
                    <div className="flex gap-3 flex-row items-center">
                        <Avatar
                            type="single"
                            size="lg"
                            shape="circle"
                            imageUrl=""
                            altTextInitials={(financialAdvisorState.findByIdFinancialAdvisorResponse?.name[0] || '') + (financialAdvisorState.findByIdFinancialAdvisorResponse?.surname[0] || '')}
                        />
                        <div className="flex gap-1 flex-col">
                            <span className="text-label-xs font-medium text-neutral-500">
                                Il tuo Commercialista
                            </span>
                            <span className={"text-text-md text-neutral-850 font-bold"}>
                                {
                                    keycloak.hasRealmRole("financial_advisor_collaborator") ?
                                        financialAdvisor?.name + ' ' + financialAdvisor?.surname :
                                        financialAdvisorState.findByIdFinancialAdvisorResponse?.name + ' ' + financialAdvisorState.findByIdFinancialAdvisorResponse?.surname
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", padding: "12px 24px 12px 24px", gap: 24 }}>
                    <div className="gap-[6px] flex flex-row items-center">
                        <ChatIcon size={"20"} color={colors.brandSecondary[400]} />
                        <span className="text-label-sm font-bold text-neutral-850">
                            Ultimo messaggio
                        </span>
                    </div>
                    <div className="gap-1 flex flex-row items-center">
                        <span className="text-text-sm font-normal text-neutral-850">
                            Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della...
                        </span>
                        <span className="text-text-sm font-semibold text-brandPrimary-500 cursor-pointer" onClick={() => undefined}>
                            leggi tutto →
                        </span>
                    </div>
                </div>
            </div>
            <div
                className="h-[48px] p-3 rounded-xl gap-6 flex flex-row"
                style={{ backgroundColor: "rgba(194, 223, 255, 0.2)", borderWidth: 0.5, borderColor: "rgba(0, 107, 224, 0.24)" }}
            >
                <div className="flex flex-row gap-2 items-center">
                    <RadioIcon size={"24"} color={colors.brandPrimary[500]} />
                    <span className="text-label-md text-neutral-850 font-semibold">Avviso</span>
                </div>
                <div className="gap-1 flex flex-row items-center justify-between w-full">
                    <div>
                        <span className="text-text-sm font-normal text-neutral-850">
                            Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della...
                        </span>
                        <span className="text-text-sm font-semibold text-brandPrimary-500 cursor-pointer" onClick={() => undefined}>
                            leggi tutto →
                        </span>
                    </div>
                    <div className={"rounded-[20.57px] border-[0.86px] gap-[6.86px] bg-brandPrimary-50 border-brandPrimary-200"}>
                        <CloseIcon size={"24"} color={colors.brandSecondary[400]} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-6">
                <div className="p-6 rounded-xl flex flex-col gap-6 w-full" style={{ boxShadow: "0px 4px 24px -8px rgba(26, 37, 255, 0.08)" }}>
                    <div className="flex flex-row gap-2 items-center">
                        <OtherIcon size={"24"} color={colors.brandPrimary[400]} />
                        <span className="text-label-md font-bold text-neutral-800">Documenti</span>
                    </div>
                    <div className="flex flex-row justify-start gap-3 w-1/2">
                        <SegmentControl
                            selected={false}
                            label={"770"}
                            onClick={() => undefined}
                        />
                        <SegmentControl
                            selected={false}
                            label={"IVA"}
                            onClick={() => undefined}
                        />
                        <SegmentControl
                            selected={false}
                            label={"Bilanci"}
                            onClick={() => undefined}
                        />
                        <SegmentControl
                            selected={false}
                            label={"Unico"}
                            onClick={() => undefined}
                        />
                        <SegmentControl
                            selected={false}
                            label={"Redditi"}
                            onClick={() => undefined}
                        />
                        <SegmentControl
                            selected={false}
                            label={"Altro"}
                            onClick={() => undefined}
                        />
                    </div>
                    <div className="flex flex-col rounded-2xl gap-3">
                        <div className="flex flex-row gap-2 px-2 items-center">
                            <ReceivedFileIcon size={"20"} color={colors.brandPrimary[400]} />
                            <span className="text-label-md font-bold text-neutral-800">Ultimi ricevuti</span>
                        </div>
                        <div className="w-full flex flex-col gap-2 max-h-[354px] overflow-y-auto">
                            <GroupedRow
                                toRead={false}
                                icon={<F24Icon size={"32"} color={colors.green[500]} />}
                                label={"Documento F24"}
                                subLabel={format(new Date(), "dd-MM-yyyy")}
                                secondIcon={<DownloadIcon size={"24"} color={colors.brandPrimary[500]} />}
                            />
                            <GroupedRow
                                toRead={false}
                                icon={<F24Icon size={"32"} color={colors.green[500]} />}
                                label={"Documento F24"}
                                subLabel={format(new Date(), "dd-MM-yyyy")}
                                secondIcon={<DownloadIcon size={"24"} color={colors.brandPrimary[500]} />}
                            />
                            <GroupedRow
                                toRead={false}
                                icon={<F24Icon size={"32"} color={colors.green[500]} />}
                                label={"Documento F24"}
                                subLabel={format(new Date(), "dd-MM-yyyy")}
                                secondIcon={<DownloadIcon size={"24"} color={colors.brandPrimary[500]} />}
                            />
                            <GroupedRow
                                toRead={false}
                                icon={<F24Icon size={"32"} color={colors.green[500]} />}
                                label={"Documento F24"}
                                subLabel={format(new Date(), "dd-MM-yyyy")}
                                secondIcon={<DownloadIcon size={"24"} color={colors.brandPrimary[500]} />}
                            />
                            <GroupedRow
                                toRead={false}
                                icon={<F24Icon size={"32"} color={colors.green[500]} />}
                                label={"Documento F24"}
                                subLabel={format(new Date(), "dd-MM-yyyy")}
                                secondIcon={<DownloadIcon size={"24"} color={colors.brandPrimary[500]} />}
                            />
                            <GroupedRow
                                toRead={false}
                                icon={<F24Icon size={"32"} color={colors.green[500]} />}
                                label={"Documento F24"}
                                subLabel={format(new Date(), "dd-MM-yyyy")}
                                secondIcon={<DownloadIcon size={"24"} color={colors.brandPrimary[500]} />}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-6 rounded-xl bg-white flex flex-col gap-6 w-full" style={{ boxShadow: "0px 4px 24px -8px rgba(26, 37, 255, 0.08)" }}>
                    <Events />
                </div>
            </div>
        </div>
    )
}