import React, { cloneElement, useState } from "react";
import { colors } from '../../colors.ts';
import { DropdownItemProps } from "./dto.ts";

export function DropdownItem(props: DropdownItemProps) {
    const [isHover, setIsHover] = useState(false)

    const active = props.value !== undefined && props.control !== undefined && props.value === props.control

    return (
        <>
            {!props.visible &&
                <div
                    onClick={() => props.onClick && props.onClick()}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                        padding: '0px 12px 0px 12px',
                        borderRadius: '8px',
                        height: '36px',
                        width: '216px',
                        justifyContent: 'space-between',
                        backgroundColor: isHover ? colors.brandPrimary[100] : active ? colors.brandPrimary[50] : colors.white,
                        boxShadow: isHover ? '0px 2px 4px 0px rgba(22, 42, 100, 0.12)' : 'none',
                        cursor: 'pointer',
                        border: '1px solid ' + (isHover ? colors.brandPrimary[100] : active ? colors.brandPrimary[400] : colors.white)
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        {cloneElement(props.icon, { color: isHover || active ? colors.brandPrimary[600] : colors.neutral[600], size: 20 })}
                        <span className='dropdown-label' style={{ color: isHover || active ? colors.brandPrimary[600] : colors.neutral[600] }}>
                            {props.label}
                        </span>
                    </div>
                </div>
            }
        </>
    )
}