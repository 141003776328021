import React, { useEffect } from 'react';
import { keycloak } from './app/lib/keycloak/index.ts';
import { setKeycloakInitStatus, setRoles } from './app/lib/keycloak/slice.ts';
import { useAppDispatch, useAppSelector } from './app/lib/redux/hook.ts';
import { KeycloakRouter } from './app/lib/router/index.tsx';
import { findMeCollaborator, findMeCompany, findMeFinancialAdvisor, findMeOperator, findMeReferrer } from './app/pages/auth/slice.ts';
import { setDocumentFilterCompanyId } from './app/pages/company/documents/slice.ts';
import { setEventFilterCompanyId, setNewEventCompanyId } from './app/pages/company/events/slice.ts';
import { setNoticeFilterReceiver } from './app/pages/notice/slice.ts';
import { SpinnerComponent } from './app/ui/molecules/spinner/index.tsx';

function App() {
  const dispatch = useAppDispatch()

  const keycloakState = useAppSelector(state => state.secured)
  const authState = useAppSelector(state => state.auth)

  useEffect(() => {
    if ((authState.findMeCompanyStatus === "successfully" && keycloak.hasRealmRole("company")) ||
      authState.findMeReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company")) {
      dispatch(setDocumentFilterCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setEventFilterCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setNewEventCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setNoticeFilterReceiver('true'))
    }
  }, [authState.findMeCompanyStatus, authState.findMeReferrersStatus, keycloak.hasRealmRole])

  keycloak.onAuthSuccess = () => {
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles))
    dispatch(setKeycloakInitStatus('successfully'))
  }

  useEffect(() => {
    switch (true) {
      case keycloakState.userRoles.includes("financial_advisor"):
        dispatch(findMeFinancialAdvisor());
        break;
      case keycloakState.userRoles.includes("operator"):
        dispatch(findMeOperator());
        break;
      case keycloakState.userRoles.includes("financial_advisor_collaborator"):
        dispatch(findMeCollaborator());
        break;
      case keycloakState.userRoles.includes("administrative_company"):
        dispatch(findMeReferrer());
        break;
      case keycloakState.userRoles.includes("company"):
        dispatch(findMeCompany());
        break;
      default:
        break;
    }
  }, [keycloakState.userRoles]);

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
  }

  if (
    keycloakState.keycloakInitStatus === 'idle' ||
    authState.findMeCollaboratorStatus === "loading" ||
    authState.findMeCompanyStatus === "loading" ||
    authState.findMeFinancialAdvisorStatus === "loading" ||
    authState.findMeOperatorStatus === "loading" ||
    authState.findMeReferrersStatus === "loading"
  ) {
    return (
      <div className={"w-full h-[100vh] flex justify-center items-center"}>
        <SpinnerComponent />
      </div>
    )
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;
