import { CollaboratorDTO, CollaboratorFiltersDTO, EditCollaboratorRequestDTO, FindAllCollaboratorResponseDTO } from "./dto.ts";
import { CollaboratServiceImpl } from "./serviceImpl.ts";

export interface CollaboratorService {
    findAllCollaborator(filters: CollaboratorFiltersDTO): Promise<FindAllCollaboratorResponseDTO>
    findByIdCollaborator(id: string): Promise<CollaboratorDTO>
    createCollaborator(request: FormData): Promise<void>
    editCollaborator(id: string, request: FormData): Promise<void>
    deleteCollaborator(id: string): Promise<void>
}

export function NewCollaboratorService(): CollaboratorService {
    return new CollaboratServiceImpl();
}
