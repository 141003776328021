import Keycloak from "keycloak-js";

const urlMap = new Map<string, string>([
    ['http://localhost:3000', 'http://localhost:8081/auth'],
    ['https://staging-app.fiscalbright.it', 'https://staging-auth.fiscalbright.it/auth']
])

export const keycloak = new Keycloak({
    realm: "fiscalbright",
    url: urlMap.get(window.location.origin),
    clientId: "frontend",
})
