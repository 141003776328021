import React from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { SearchIcon } from "../../../ui/icons/searchIcon.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { InputComponent } from "../../../ui/molecules/input/index.tsx";
import { findAllCollaborator, setCollaboratorFiltersName } from "./slice.ts";

export function CollaboratorFilters() {
    const dispatch = useAppDispatch()
    const collaboratorState = useAppSelector(state => state.collaborator)

    return (
        <div className={"flex flex-row gap-4 w-full"}>
            <InputComponent
                placeholder="Cerca un collaboratore"
                startIcon={<SearchIcon />}
                type={"text"}
                onChangeText={(value) => dispatch(setCollaboratorFiltersName(value))}
                value={collaboratorState.collaboratorFilters.name}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
                    }
                }}
            />
            <div className={"gap-4 flex flex-row"}>
                <ButtonComponent
                    label={"Pulisci"}
                    onClick={() => {
                        dispatch(setCollaboratorFiltersName(""))
                        dispatch(findAllCollaborator({
                            page: collaboratorState.collaboratorFilters.page,
                            itemsPerPage: collaboratorState.collaboratorFilters.itemsPerPage,
                            name: "",
                            sort: collaboratorState.collaboratorFilters.sort,
                            order: collaboratorState.collaboratorFilters.order,
                            financialAdvisorId: collaboratorState.collaboratorFilters.financialAdvisorId
                        }))
                    }}
                    color={"gray"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition="off"
                />
                <ButtonComponent
                    label={"Applica"}
                    onClick={() => {
                        dispatch(findAllCollaborator({
                            page: collaboratorState.collaboratorFilters.page,
                            itemsPerPage: collaboratorState.collaboratorFilters.itemsPerPage,
                            name: collaboratorState.collaboratorFilters.name,
                            sort: collaboratorState.collaboratorFilters.sort,
                            order: collaboratorState.collaboratorFilters.order,
                            financialAdvisorId: collaboratorState.collaboratorFilters.financialAdvisorId
                        }))
                    }}
                    color={"blue"}
                    variant={"outline"}
                    size={"md"}
                    iconPosition="off"
                />
            </div>
        </div>
    )
}