import React, { ReactElement } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { AccountantIcon } from "../../../ui/icons/accountantIcon.tsx";
import { BalanceIcon } from "../../../ui/icons/balanceIcon.tsx";
import { EmployeeIcon } from "../../../ui/icons/employeeIcon.tsx";
import { F24Icon } from "../../../ui/icons/f24Icon.tsx";
import { OtherIcon } from "../../../ui/icons/otherIcon.tsx";
import { ReceivedIcon } from "../../../ui/icons/receivedIcon.tsx";
import { SendingIcon } from "../../../ui/icons/sendingIcon.tsx";
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx";
import { HorizontalSecondaryTab } from "../../../ui/organisms/horizontalSecondaryTab/index.tsx";
import { SuccessPopup } from "../../../ui/organisms/successPopup/index.tsx";
import { Tab } from "../../../ui/organisms/tab/index.tsx";
import { setSelectedDocumentTab } from "../slice.ts";
import { Accountant } from "./accountant/accountant.tsx";
import { Balance } from "./balance/balace.tsx";
import { DocumentType } from "./dto.ts";
import { Employee } from "./employee/employee.tsx";
import { F24 } from "./f24/f24.tsx";
import { Other } from "./other/other.tsx";
import { setDeleteDocumentStatus, setDocumentFilterFileName, setDocumentFilterMonth, setDocumentFilterPage, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterStatus, setDocumentFilterType, setDocumentFilterTypology, setDocumentFilterYear, setFindAllDocumentsStatus, setSaveDocumentStatus, setSelectedSentOrReceivedTab } from "./slice.ts";

export function Documents() {
    const companyState = useAppSelector(state => state.company)
    const documentState = useAppSelector(state => state.document)
    const dispatch = useAppDispatch()

    const viewDocumentMap = new Map<number, ReactElement>([
        [0, <Balance />],
        [1, <F24 />],
        [2, <Employee />],
        [3, <Accountant />],
        [4, <Other />],
    ])

    return (
        <>
            <ErrorPopup
                active={documentState.saveDocumentStatus === "failed"}
                close={() => dispatch(setSaveDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvaggio del documento."
            />
            <SuccessPopup
                active={documentState.saveDocumentStatus === "successfully"}
                close={() => dispatch(setSaveDocumentStatus('idle'))}
                message="Documento salvato correttamente."
            />
            <ErrorPopup
                active={documentState.deleteDocumentStatus === "failed"}
                close={() => dispatch(setDeleteDocumentStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del documento."
            />
            <SuccessPopup
                active={documentState.deleteDocumentStatus === "successfully"}
                close={() => dispatch(setDeleteDocumentStatus('idle'))}
                message="Documento eliminato correttamente."
            />
            <HorizontalSecondaryTab>
                <Tab
                    icon={<BalanceIcon size={"20"} color={""} />}
                    label={"Bilanci e Dichiarativi"}
                    selectedSecondary={companyState.selectedDocumentTab === 0}
                    onClick={() => {
                        dispatch(setFindAllDocumentsStatus("idle"))
                        dispatch(setDocumentFilterPage(0))
                        dispatch(setDocumentFilterFileName(""))
                        dispatch(setDocumentFilterMonth(0))
                        dispatch(setDocumentFilterTypology(""))
                        dispatch(setDocumentFilterYear(0))
                        dispatch(setDocumentFilterType(DocumentType.balance))
                        dispatch(setDocumentFilterReceivedGroup(""))
                        dispatch(setDocumentFilterSenderGroup(""))
                        dispatch(setDocumentFilterStatus(""))
                        dispatch(setSelectedDocumentTab(0))
                    }}
                    type="secondary"
                />
                <Tab
                    icon={<F24Icon size={"20"} color={""} />}
                    label={"F24"}
                    selectedSecondary={companyState.selectedDocumentTab === 1}
                    onClick={() => {
                        dispatch(setFindAllDocumentsStatus("idle"))
                        dispatch(setDocumentFilterPage(0))
                        dispatch(setDocumentFilterFileName(""))
                        dispatch(setDocumentFilterMonth(0))
                        dispatch(setDocumentFilterTypology(""))
                        dispatch(setDocumentFilterYear(0))
                        dispatch(setDocumentFilterType(DocumentType.f24))
                        dispatch(setDocumentFilterReceivedGroup(""))
                        dispatch(setDocumentFilterSenderGroup(""))
                        dispatch(setDocumentFilterStatus(""))
                        dispatch(setSelectedDocumentTab(1));
                    }}
                    type="secondary"
                />
                <Tab
                    icon={<EmployeeIcon size={"20"} color={""} />}
                    label={"Dipendenti"}
                    selectedSecondary={companyState.selectedDocumentTab === 2}
                    onClick={() => {
                        dispatch(setFindAllDocumentsStatus("idle"))
                        dispatch(setDocumentFilterPage(0))
                        dispatch(setDocumentFilterFileName(""))
                        dispatch(setDocumentFilterMonth(0))
                        dispatch(setDocumentFilterTypology(""))
                        dispatch(setDocumentFilterYear(0))
                        dispatch(setDocumentFilterType(DocumentType.employees))
                        dispatch(setDocumentFilterReceivedGroup(""))
                        dispatch(setDocumentFilterSenderGroup(""))
                        dispatch(setDocumentFilterStatus(""))
                        dispatch(setSelectedDocumentTab(2))
                    }}
                    type="secondary"
                />
                <Tab
                    icon={<OtherIcon size={"20"} color={""} />}
                    label={"Altro"}
                    selectedSecondary={companyState.selectedDocumentTab === 4}
                    onClick={() => {
                        dispatch(setFindAllDocumentsStatus("idle"))
                        dispatch(setDocumentFilterPage(0))
                        dispatch(setDocumentFilterFileName(""))
                        dispatch(setDocumentFilterMonth(0))
                        dispatch(setDocumentFilterTypology(""))
                        dispatch(setDocumentFilterYear(0))
                        dispatch(setDocumentFilterType(DocumentType.other))
                        dispatch(setDocumentFilterReceivedGroup(""))
                        dispatch(setDocumentFilterSenderGroup(""))
                        dispatch(setDocumentFilterStatus(""))
                        dispatch(setSelectedDocumentTab(4))
                    }}
                    type="secondary"
                />
                <div className="border border-neutral-200 mr-[6px]" />
                <Tab
                    icon={<AccountantIcon size={"20"} color={""} />}
                    label={"Contabili"}
                    selectedSecondary={companyState.selectedDocumentTab === 3}
                    onClick={() => {
                        dispatch(setFindAllDocumentsStatus("idle"))
                        dispatch(setDocumentFilterPage(0))
                        dispatch(setDocumentFilterFileName(""))
                        dispatch(setDocumentFilterMonth(0))
                        dispatch(setDocumentFilterTypology(""))
                        dispatch(setDocumentFilterYear(0))
                        dispatch(setDocumentFilterType(DocumentType.accounting))
                        dispatch(setDocumentFilterReceivedGroup(""))
                        dispatch(setDocumentFilterStatus(""))
                        if (keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company")) {
                            dispatch(setDocumentFilterSenderGroup("companies"))
                        } else if (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) {
                            dispatch(setDocumentFilterSenderGroup("operators"))
                        } else {
                            dispatch(setDocumentFilterSenderGroup("admin"))
                        }
                        dispatch(setSelectedDocumentTab(3))
                    }}
                    type="secondary"
                />
                {
                    companyState.selectedDocumentTab === 3 &&
                    <>

                        <Tab icon={<SendingIcon size={"20"} color={""} />}
                            label={"Doc inviati"}
                            selectedSecondary={documentState.selectedSentOrReceivedDocumentTab === 0}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                if (keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company")) {
                                    dispatch(setDocumentFilterSenderGroup("companies"))
                                } else if (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) {
                                    dispatch(setDocumentFilterSenderGroup("operators"))
                                } else {
                                    dispatch(setDocumentFilterSenderGroup("admin"))
                                }
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.accounting))
                                dispatch(setDocumentFilterReceivedGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                dispatch(setSelectedSentOrReceivedTab(0))
                            }}
                            type="secondary"
                        />
                        <Tab icon={<ReceivedIcon size={"20"} color={""} />}
                            label={"Doc ricevuti"}
                            selectedSecondary={documentState.selectedSentOrReceivedDocumentTab === 1}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.accounting))
                                dispatch(setDocumentFilterSenderGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                if (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) {
                                    dispatch(setDocumentFilterReceivedGroup("operators"))
                                } else if (keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company")) {
                                    dispatch(setDocumentFilterReceivedGroup("companies"))
                                }
                                dispatch(setSelectedSentOrReceivedTab(1))
                            }}
                            type="secondary"
                        />
                    </>
                }
            </HorizontalSecondaryTab>
            {viewDocumentMap.get(companyState.selectedDocumentTab)}
        </>
    )
}