import { createSlice } from "@reduxjs/toolkit"
import { MenuStatus } from "./dto.ts"
import { MenuItems } from "../organisms/sidebar/dto.ts"

interface LayoutState {
    menuItems: MenuItems[]
    menuStatus: MenuStatus
}

const initialState: LayoutState = {
    menuItems: [],
    menuStatus: MenuStatus.OPEN
}

const layoutSlice = createSlice({
    name: 'layout/slice',
    initialState,
    reducers: {
        setMenuItems: (state, action) => {
            state.menuItems = action.payload
        },
        setMenuStatus: (state, action) => {
            state.menuStatus = action.payload
        },
    },
})

export const {
    setMenuItems,
    setMenuStatus
} = layoutSlice.actions

export default layoutSlice.reducer
