import React, { ReactElement } from "react";

interface Props {
    icon: ReactElement
    label: string
    menuIcon: ReactElement
    subLabel: string
    cssIcon: string
}

export function Card(props: Props) {
    const { icon } = props
    const { label } = props
    const { menuIcon } = props
    const { subLabel } = props
    const { cssIcon } = props

    return (
        <div className={"flex flex-col gap-4 border rounded-[20px] border-brandPrimary-200 flex-grow"} style={{ padding: "24px 16px 24px 24px" }}>
            <div className={"flex flex-row justify-between"}>
                <div className={"flex gap-3 justify-center items-center"}>
                    <div className={cssIcon}>
                        {icon}
                    </div>
                    <span className={"text-heading-xs text-brandPrimary-900 font-semibold"}>{label}</span>
                </div>
                {menuIcon}
            </div>
            <span className={"text-heading-lg text-neutral-500"}>{subLabel}</span>
        </div>
    )
}