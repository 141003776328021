import { FindAllOperatorResponseDTO, OperatorDTO, OperatorFiltersDTO } from "./dto.ts";
import { OperatorServiceImpl } from "./serviceImpl.ts";


export interface OperatorService {
    findAllOperator(filters: OperatorFiltersDTO): Promise<FindAllOperatorResponseDTO>
    createOperator(request: FormData): Promise<void>
    deleteOperator(id: string): Promise<void>
    findByIdOperator(id: string): Promise<OperatorDTO>
    editOperator(id: string, request: FormData): Promise<void>
}

export function NewOperatorService(): OperatorService {
    return new OperatorServiceImpl();
}
