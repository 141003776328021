import { CreateFinancialAdvisorDTO, EditFinancialAdvisorDTO, FinancialAdvisorDTO, FindAllFinancialAdvisorFiltersDTO, findAllFinancialAdvisorResponseDTO } from "./dto.ts";
import { FinancialAdvisorServiceImpl } from "./serviceImpl.ts";


export interface FinancialAdvisorService {
    findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<findAllFinancialAdvisorResponseDTO>
    createFinancialAdvisor(request: FormData): Promise<void>
    deleteFinancialAdvisor(id: string): Promise<void>
    findByIdFinancialAdvisor(id: string): Promise<FinancialAdvisorDTO>
    editFinancialAdvisor(id: string, request: FormData): Promise<void>
}

export function NewFinancialAdvisorService(): FinancialAdvisorService {
    return new FinancialAdvisorServiceImpl();
}
