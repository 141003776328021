import { PillsColor } from "../../ui/molecules/pills/dto.ts"

export interface FindAllNoticeResponseDTO {
    total: number
    page: number
    itemsPerPage: number,
    prev: boolean
    next: boolean
    totalPage: number
    data: NoticeDTO[]
}

export interface NoticeFiltersDTO {
    itemsPerPage: number
    order: boolean
    page: number
    sort: string
    status: NoticeStatus | string
    type: NoticeType | string
    receiver: 'true' | 'false' | ''
}

export interface NoticeDTO {
    id: number
    userIds: string[]
    type: NoticeType
    status: NoticeStatus
    date: Date
    companyReceivers: string
    administrativeCompanyReceivers: string
    financialAdvisorReceivers: string
    collaboratorReceivers: string
    subjectLine: string
    message: string
    prominent: boolean
    objectId: string
    userId: string
}

export interface NoticeCreationDTO {
    type: NoticeType | string
    status: NoticeStatus
    userIds: string[]
    subjectLine: string
    message: string
    prominent: boolean
}

export interface NoticeUpsertDTO {
    type: NoticeType | string
    status: NoticeStatus | string
    subjectLine: string
    message: string
    prominent: boolean
    userIds: string[]
}

export enum NoticeType {
    NEWS = 'NEWS',
    DEADLINE = 'DEADLINE',
    UPDATE = 'UPDATE',
    REQUEST = 'REQUEST',
    EVENT = 'EVENT'
}

export enum NoticeStatus {
    DRAFT = 'DRAFT',
    SENT = 'SENT'
}

export const NoticeStatusMap = new Map<NoticeStatus, string>([
    [NoticeStatus.SENT, 'Inviato'],
    [NoticeStatus.DRAFT, 'Bozza'],
])

export const NoticeTypeMap = new Map<NoticeType, string>([
    [NoticeType.NEWS, 'News'],
    [NoticeType.DEADLINE, 'Scadenze'],
    [NoticeType.UPDATE, 'Aggiornamenti'],
    [NoticeType.REQUEST, 'Richieste'],
    [NoticeType.EVENT, 'Eventi'],
])

export const NoticeTypeColorMap = new Map<NoticeType, PillsColor>([
    [NoticeType.NEWS, 'blue'],
    [NoticeType.DEADLINE, 'red'],
    [NoticeType.UPDATE, 'orange'],
    [NoticeType.REQUEST, 'orange'],
    [NoticeType.EVENT, 'blue'],
])