import React, { ReactElement, cloneElement } from "react";
import { PillsColor, PillsSize, colorKey, pillLabelSizeMap, pillSizeMap, pillVariantKey } from "./dto.ts";

interface Props {
    size: PillsSize;
    label: string | string[]
    startIcon?: ReactElement
    endIcon?: ReactElement
    outline: boolean
    emphasis: boolean
    color: PillsColor
    onClick?: () => void
}

export function Pills(props: Props) {
    return (
        <div
            style={{
                ...pillSizeMap.get(props.size),
                ...colorKey(props.color).pillContainerVariant.get(pillVariantKey(props.emphasis, props.outline)),
                ...{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: 4, borderWidth: '1px', borderStyle: 'solid', width: 'max-content' },
            }}
        >
            {
                props.startIcon && cloneElement(props.startIcon, { color: colorKey(props.color).contentColor.get(pillVariantKey(props.emphasis, props.outline)) })
            }
            <span
                style={{
                    ...pillLabelSizeMap.get(props.size),
                    ...{ color: colorKey(props.color).contentColor.get(pillVariantKey(props.emphasis, props.outline)) }
                }}
            >
                {props.label}
            </span>
            {
                props.endIcon && cloneElement(props.endIcon, { color: colorKey(props.color).contentColor.get(pillVariantKey(props.emphasis, props.outline)) })
            }
        </div>

    )
}